import { ScoreTypes } from "./actionTypes";

export const INIT_STATE: any = {
  scores: [],
  singleScore: {},
  error: {},
  loading: false,
};

const score = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ScoreTypes.GET_SCORES_BY_COMPANYCODE:
      return {
        ...state,
        loading: true,
      };

    case ScoreTypes.GET_SCORES_BY_COMPANYCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        scores: action.payload,
      };

    case ScoreTypes.GET_SCORES_BY_COMPANYCODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default score;
