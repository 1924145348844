import { useState } from 'react';
import styled from 'styled-components';
import MetaTags from "react-meta-tags";
import {Alert} from 'reactstrap'
import { IService } from './types';
import { Button } from './components/general';
import useDirectories from 'src/hooks/useDirectories';
import { ISite } from 'src/interfaces/general';
import ServiceItem from './components/ServiceItem';
import SiteItem from './components/SiteSelector';
import { useTranslation } from 'react-i18next';

const initial_services:Array<IService> = [
    { title: "Buy", key: "buy", selected: false},
    { title: "Sell My Car", key: "sell_my_car", selected: false},
    { title: "Insurance", key: "insurance", selected: false},
    { title: "Rental", key: "rental", selected: false},
    { title: "Tyres", key: "tyres", selected: false},
    { title: "Parts", key: "parts", selected: false},
    { title: "Parking", key: "parking", selected: false},
    { title: "Fix My Car", key: "fix_my_car", selected: false},
    { title: "Charge Points", key: "charge_points", selected: false},
    { title: "My Carbon", key: "my_carbon", selected: false},
    { title: "Games", key: "games", selected: false},
    { title: "Finance", key: "finance", selected: false},
    { title: "Car Warranty", key: "car_warranty", selected: false},
    { title: "Gap Insurance", key: "gap_insurance", selected: false},
    { title: "Service Plan", key: "service_plan", selected: false},
    { title: "Protect", key: "protect", selected: false},
];

const Directory = ()=>{

    const {t} = useTranslation();
    const [siteCode, setSiteCode] = useState<string>("");    
    const [services, setServices] = useState(initial_services);
    const {directories, updateDirectories } = useDirectories(siteCode);
    const [showResult, setShowResult] = useState(0);

    const onToggleService = (service: IService, index: number)=>{
        services[index] = {...service, selected: !service.selected};
        setServices([...services]);
    }

    const onRemoveItem = (service: IService, item: string)=>{
        const index = directories[service.key].findIndex(site=>site.code === item);
        const updated = JSON.parse(JSON.stringify(directories));
        updated[service.key].splice(index, 1);
        
        updateDirectories(updated);
    }

    const onAddItem = (service: IService, site: ISite)=>{
        if ( (directories[service.key]||[]).findIndex(item=>item.code == site.code)>=0) return;
        
        const updated = JSON.parse(JSON.stringify(directories));
        if (updated[service.key]){
            updated[service.key].push(site);
        }else{
            updated[service.key] = [site];
        }
        
        updateDirectories(updated);
    }

    const onUpdate = async ()=>{
        const result = await updateDirectories(directories, true);
        setShowResult(result);
        
        setTimeout(()=>setShowResult(0), 3000);
        
    }
    
    const onChangeSiteCode = ( siteCode: string ) => {
        setSiteCode(siteCode);    
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title> Directories </title>
            </MetaTags>

            <SiteItem onChangeSiteCode = {onChangeSiteCode}/>

            {services.map((service, index)=>(
                <ServiceItem
                    key={index}
                    service={service}
                    onToggle={()=>onToggleService(service, index)}
                    onAddItem={(site: ISite)=>onAddItem(service, site)}
                    directories={directories[service.key]||[]}
                    onRemoveItem={item=>onRemoveItem(service, item)}
                />
            ))}

            <Footer>
                {showResult!=0&&<Alert color={showResult==1?"success":"error"}>{t("directory.alert.success")}</Alert>}
                <Button
                    onPress={onUpdate}
                    title={t("directory.save")}
                    theme="info"
                    style={{width:100}}
                />
            </Footer>
            
        </div>
    )
}

export default Directory;


const Footer = styled.div`
    margin: 20px;
    display: flex;
    justify-content: space-evenly;
`

