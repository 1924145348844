import { useSelector } from "react-redux";







const usePermission = (table: string) => {

    const { currentUser } = useSelector((state: any) => state.login);

    const permissions = currentUser?.permissions || {};

    const read = permissions[table]; // TODO: need to add read permission to user
    const write = permissions[table]; // TODO: need to add write permission to user

    return {
        read,
        write,
    }
}

export default usePermission;