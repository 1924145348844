import { WarrantyTypes } from "./actionTypes";

export const INIT_STATE: any = {
  warrantyList: [],
  singleWarranty: {},
  error: {},
  success: false,
  loading: true,
  confirm_del: false,
};

const warranty = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case WarrantyTypes.GET_WARRANTY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        warrantyList: action.payload,
      };

    case WarrantyTypes.GET_WARRANTY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case WarrantyTypes.ADD_WARRANTY:
      return {
        ...state,
        loading: true,
      };
    case WarrantyTypes.ADD_WARRANTY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        warrantyList: [...state.warrantyList, action.payload],
      };

    case WarrantyTypes.ADD_WARRANTY_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case WarrantyTypes.GET_WARRANTY_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case WarrantyTypes.GET_WARRANTY_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleWarranty: action.payload,
      };

    case WarrantyTypes.GET_WARRANTY_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    
    case WarrantyTypes.UPDATE_WARRANTY_BY_DOCID:
    return {
      ...state,
      loading: true,
    };

    case WarrantyTypes.UPDATE_WARRANTY_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case WarrantyTypes.UPDATE_WARRANTY_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleWarranty: action.payload,
      };
    case WarrantyTypes.DELETE_WARRANTY_BYID:
      return {
        ...state,
        loading: true,
        success: false
      };

    case WarrantyTypes.DELETE_WARRANTY_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        warrantyList: state.warrantyList.filter((item: any) => item.id !== action.payload),
      };

    case WarrantyTypes.DELETE_WARRANTY_BYID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      }    
    default:
      return state;
  }
};

export default warranty;
