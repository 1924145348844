import React from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";

const Keylocker = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>Keyloker</title>
                </MetaTags>
                <Container fluid></Container>

            </div>
        </React.Fragment>
    );
};
 
export default Keylocker;