import { useEffect, useRef } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { IProduct } from "src/hooks/useProducts";




const ProductDetailCard = ({
    wasInfo,
    hendyInfo
}: IProduct) => {

    const videoRef = useRef<any>();

    useEffect(() => {
        videoRef.current?.load();
    }, [videoRef.current])

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <h5 className="font-size-16 mb-1">Product detail</h5>
                        <Col md="6" className="pt-3">
                            <img
                                width="100%"
                                className="rounded"
                                src={hendyInfo.image}
                            />
                        </Col>
                        <Col md="6" className="pt-3">
                            <video
                                ref={videoRef}
                                width="100%"
                                height="100%"
                                controls
                            >
                                <source src={hendyInfo.video} />
                            </video>
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col md="6" className="pt-3">
                            <div className="d-flex justify-content-between">
                                <h6 className="font-size-14 mb-1">
                                    {wasInfo.ProductName}
                                </h6>
                                <h6 className="font-size-14 mb-1">
                                    £ {wasInfo.DealerCost}
                                </h6>
                            </div>
                            <div className="pt-4">
                                <Input
                                    type="textarea"
                                    name="text"
                                    disabled
                                    id="exampleText"
                                    defaultValue={hendyInfo.description}
                                />
                            </div>
                        </Col>
                        <Col md="6" className="pt-3">
                            <h6 className="font-size-14 mb-1">Terms PDF</h6>
                            <div className="d-flex justify-content-center pt-4">
                                <a
                                    target="_blank"
                                    href={hendyInfo.terms}
                                    className="border p-3 rounded"
                                >
                                    <img
                                        src="/pdf.jpg"
                                        width={"50"}
                                        style={{ cursor: "pointer" }}
                                    />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default ProductDetailCard;