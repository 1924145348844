export enum ProductTypes {
  GET_PRODUCTS_BY_SITECODE = "GET_PRODUCTS_BY_SITECODE",
  GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS",
  GET_PRODUCT_BY_DOCID = "GET_PRODUCT_BY_DOCID",
  GET_PRODUCT_BY_DOCID_SUCCESS = "GET_PRODUCT_BY_DOCID_SUCCESS",
  GET_PRODUCT_BY_DOCID_FAIL = "GET_PRODUCT_BY_DOCID_FAIL",

  GET_PRODUCTS_BY_SITECODE_SUCCESS = 'GET_PRODUCTS_BY_SITECODE_SUCCESS',
  GET_PRODUCTS_BY_SITECODE_FAIL = 'GET_PRODUCTS_BY_SITECODE_FAIL',

  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL',

  UPDATE_PRODUCT_BY_DOCID = "UPDATE_PRODUCT_BY_DOCID",
  UPDATE_PRODUCT_BY_DOCID_SUCCESS = "UPDATE_PRODUCT_BY_DOCID_SUCCESS",
  UPDATE_PRODUCT_BY_DOCID_FAIL = "UPDATE_PRODUCT_BY_DOCID_FAIL",


  DELETE_PRODUCT_BYID = 'DELETE_PRODUCT_BYID',
  DELETE_PRODUCT_BYID_SUCCESS = 'DELETE_PRODUCT_BYID_SUCCESS',
  DELETE_PRODUCT_BYID_FAIL = 'DELETE_PRODUCT_BYID_FAIL',
  
  GET_ALL_WAS_PRODUCTS = "GET_ALL_WAS_PRODUCTS",
  SET_CURRENT_WAS_PRODUCT_INFO = "SET_CURRENT_WAS_PRODUCT_INFO"
}
