import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { ScoreTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getScoresByCompanyCodeSuccess, getScoresByCompanyCodeFail} from "./actions";

import {
  getScoresByCompanyCodeAsynce
} from "../../helpers/backend_helper";

function* getScoresByCompanyCode({ payload: companyCode}: any) {
  try {
    const response: IResponse = yield call(getScoresByCompanyCodeAsynce, companyCode);
    if(response.success) {
      yield put(getScoresByCompanyCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getScoresByCompanyCodeFail(error));
  }
}


function* scoreSaga() {
  yield takeEvery(ScoreTypes.GET_SCORES_BY_COMPANYCODE, getScoresByCompanyCode)
}

export default scoreSaga;


