export enum SiteTypes {
  GET_SITES = "GET_SITES",

  ADD_SITE = 'ADD_SITE',
  ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS',
  ADD_SITE_FAIL = 'ADD_SITE_FAIL',
  GET_SITES_BY_COMPANY_CODE = 'GET_SITES_BY_COMPANY_CODE',
  GET_SITES_BY_COMPANY_CODE_SUCCESS = 'GET_SITES_BY_COMPANY_CODE_SUCCESS',
  GET_SITES_BY_COMPANY_CODE_FAIL = 'GET_SITES_BY_COMPANY_CODE_FAIL',
  GET_SITE_BY_DOCID = 'GET_SITE_BY_DOCID',
  GET_SITE_BY_DOCID_SUCCESS = 'GET_SITE_BY_DOCID_SUCCESS',
  GET_SITE_BY_DOCID_FAIL = 'GET_SITE_BY_DOCID_FAIL',
  UPDATE_SITE_BY_DOCID = 'UPDATE_SITE_BY_DOCID',
  UPDATE_SITE_BY_DOCID_SUCCESS = 'UPDATE_SITE_BY_DOCID_SUCCESS',
  UPDATE_SITE_BY_DOCID_FAIL = 'UPDATE_SITE_BY_DOCID_FAIL',
  GET_SITE_BY_SITECODE = 'GET_SITE_BY_SITECODE',
  GET_SITE_BY_SITECODE_SUCCESS = 'GET_SITE_BY_SITECODE_SUCCESS',
  GET_SITE_BY_SITECODE_FAIL = 'GET_SITE_BY_SITECODE_FAIL',

  DELETE_SITE_BYSITECODE = 'DELETE_SITE_BYSITECODE',
  DELETE_SITE_BYSITECODE_SUCCESS = 'DELETE_SITE_BYSITECODE_SUCCESS'

  
}
