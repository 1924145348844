import { useEffect, useState } from "react";
import {
    getCustomersByCompanyCodeAsync, getGraphDataOfCustomer,
    deleteCustomerByDocIdAsync, addCustomerAsync,
    getCustomersByFilter
} from "src/helpers/backend_helper";
import { ICustomer, TGraphData } from "src/interfaces/general";

import moment from 'moment'

const useCustomers = (companyCode?: string) => {

    const [customers, setCustomers] = useState<Array<ICustomer>>([]);
    const [graphData, setGraphData] = useState<TGraphData>([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const fetchCustomers = () => {
        setLoading(true);
        if (companyCode) {
            getCustomersByCompanyCodeAsync(companyCode)
                .then(res => {
                    if (res.success) {
                        setCustomers(res.result || []);
                    }
                }).catch(console.log).finally(() => setLoading(false));
        }
    }

    const fetchFilteredCustomers = (filterParam: any) => {
        setLoading(true);
        getCustomersByFilter(filterParam)
            .then(res => {
                if (res.success) {
                    let new_result: any[] = [];
                    let filter_result: any[] = [];
                    res.result.filter((item:any, index: number) => {
                        if (new_result.includes(item.id)) {
                            return false;
                        }else{
                            new_result.push(item.id);
                            filter_result.push(item);
                            return true;
                        }
                    });
                    setCustomers(filter_result || []);
                }
            }).catch(console.log).finally(() => setLoading(false));
    }

    const fetchGraphData = (siteCode: string, start: number, days: number) => {
        setLoading(true);
        getGraphDataOfCustomer(
            siteCode,
            moment(start).format("YYYY-MM-DD"),
            days
        ).then(res => {
            if (res.success) {
                setGraphData(res.result);
            }
        })
        setLoading(false);
    }

    const deleteCustomer = async (id: string) => {
        const index = customers.findIndex(customer => customer.id === id);
        try {
            setLoading(true);
            const result = await deleteCustomerByDocIdAsync(id);
            if (result.success) {
                customers.splice(index, 1);
                setCustomers([...customers]);
                setLoading(false);
                return true;
            }
        } catch (error) {
            console.log("customer delete error", error);
        }
        setLoading(false);
        return false;
    }

    const addCustomer = async (customer: ICustomer) => {

        try {
            const result = await addCustomerAsync(customer);
            if (result.success) {
                customers.push(customer);
                setCustomers([...customers]);
                setLoading(true);
                setSuccess(true);
                return true;
            }
        } catch (error) {
            console.log("customer add error", error);
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    useEffect(() => {
        fetchCustomers();
    }, [companyCode])

    return {
        customers,
        loading,
        fetchFilteredCustomers,
        deleteCustomer,
        addCustomer,
        fetchGraphData,
        graphData,
        success,
    };
}

export default useCustomers;