import { call, put, take, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { WarrantyTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {
  getWarrantySuccess,
  getWarrantyFail,
  addWarrantySuccess,
  addWarrantyFail,
  getWarrantyByDocIdSuccess,
  getWarrantyByDocIdFail,
  updateWarrantyByDocIdSuccess,
  updateWarrantyByDocIdFail,
  deleteWarrantyByDocIdSuccess,
  deleteWarrantyByDocIdFail
} from "./actions";

import {
  getAllWarrantyListAsync,
  getWarrantByDocIdAsync,
  addWarrantyAsync,
  deleteWarrantyByIdAsync,
  updateWarrantyByDocIdAsync,
} from "../../helpers/backend_helper";

function* getAllWarrantyList() {
  try {
    const response: IResponse = yield call(getAllWarrantyListAsync);
    if (response.success) {
      yield put(getWarrantySuccess(response.result));
    }
  } catch (error) {
    yield put(getWarrantyFail(error));
  }
}

function* addWarranty({ payload: warranty }: any) {
  try {
    const response: IResponse = yield call(addWarrantyAsync, warranty);
    if (response.success) {
      yield put(addWarrantySuccess(response.result));
    }
  } catch (error) {
    yield put(addWarrantyFail(error));
  }
}

function* getWarrantByDocId({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(getWarrantByDocIdAsync, docid);
    if (response.success) {
      yield put(getWarrantyByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getWarrantyByDocIdFail(error));
  }
}

function* deleteWarrantyById({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(deleteWarrantyByIdAsync, docid);
    if (response.success) {
      yield put(deleteWarrantyByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(deleteWarrantyByDocIdFail(error));
  }
}


function* updateWarrantyByDocId({ payload: { docid, warranty, history } }: any) {
  try {
    const response: IResponse = yield call(
      updateWarrantyByDocIdAsync,
      docid,
      warranty
    );
    if (response.success) {
      yield put(updateWarrantyByDocIdSuccess(response.result));
      history.push("/warranty");
    }
  } catch (error) {
    yield put(updateWarrantyByDocIdFail(error));
  }
}


function* warrantySaga() {
  yield takeEvery(WarrantyTypes.GET_ALL_WARRANTY_LISTS, getAllWarrantyList);
  yield takeEvery(WarrantyTypes.ADD_WARRANTY, addWarranty);
  yield takeEvery(WarrantyTypes.GET_WARRANTY_BY_DOCID, getWarrantByDocId);
  yield takeEvery(WarrantyTypes.UPDATE_WARRANTY_BY_DOCID, updateWarrantyByDocId);
  yield takeEvery(WarrantyTypes.DELETE_WARRANTY_BYID, deleteWarrantyById);
}

export default warrantySaga;
