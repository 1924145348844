import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import CSVReader from 'react-csv-reader'

const ImportCSV = (props: any) => {
    const dataRef = useRef<any>([]);

    const handleOnSubmit = (e: any) => {
        e.preventDefault();
        props.onAddImportData(dataRef.current);
    };

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header: any) =>
            header
                .toLowerCase()
                .replace(/\W/g, '_')
    }

    const onFileLoaded = (data: any, fileInfo: any) => {
        dataRef.current = data;

    }

    return (
        <Modal
            isOpen={props.open}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {"ImportCSV"}
                </h5>
                <button
                    type="button"
                    onClick={props.onClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="d-flex flex-column align-items-center p-3 ">
                    <CSVReader
                        cssClass="csv-reader-input"
                        label="Select a CSV file"
                        onFileLoaded={onFileLoaded}
                        onError={console.log}
                        parserOptions={papaparseOptions}
                        inputId="ObiWan"
                        inputName="ObiWan"
                        inputStyle={{ color: 'red' }}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={props.onClose}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                >
                    {"Close"}
                </button>
                <button
                    type="button"
                    className="btn btn-primary "
                    onClick={(e) => {
                        handleOnSubmit(e);
                    }}
                >
                    {props.loading ? (
                        <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                    ) : (
                        <>{"OK"}</>
                    )}
                </button>
            </div>
        </Modal>
    )
}

export default ImportCSV;
