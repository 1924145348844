import { call, put, takeEvery } from "redux-saga/effects";
import { BookingToolTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  saveBookingToolSettingSuccess,
  getBookingToolDataBySiteCodeSuccess,
  getBookingToolDataBySiteCodeFail
} from "./actions";

import {
  saveBookingToolBySitecodeAsync,
  getBookingToolDataBySiteCodeAsync
} from "../../helpers/backend_helper";


function* getBookingToolDataBySiteCode({ payload: siteCode}: any) {
  try {
    const response: IResponse = yield call(getBookingToolDataBySiteCodeAsync, siteCode);
    if(response.success) {
      yield put(getBookingToolDataBySiteCodeSuccess(response.result[0]));
    } else {
      yield put(getBookingToolDataBySiteCodeFail());
    }
  } catch (error) {
    console.log(error)
  }
}

function* saveBookingToolBySiteCode({ payload: bookingToolData}: any) {
  try {
    const response: IResponse = yield call(saveBookingToolBySitecodeAsync, bookingToolData);
    if(response.success) {
      yield put(saveBookingToolSettingSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}


function* bookingToolSaga() {
  yield takeEvery(BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE, getBookingToolDataBySiteCode)
  yield takeEvery(BookingToolTypes.SAVE_BOOKING_TOOL_DATA, saveBookingToolBySiteCode)
}

export default bookingToolSaga;


