import { call, put, takeEvery } from "redux-saga/effects";
import { CommsTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  getCommsBySiteCodeSuccess,
  saveCommBySitecodeSuccess,
  getCommsBySiteCodeFail
} from "./actions";

import {
  saveCommBySitecodeAsync,
  getCommsBySiteCodeAsync
} from "../../helpers/backend_helper";


function* getCommsBySiteCode({ payload: siteCode}: any) {
  try {
    const response: IResponse = yield call(getCommsBySiteCodeAsync, siteCode);
    if(response.success) {
      yield put(getCommsBySiteCodeSuccess(response.result[0]));
    } else {
      yield put(getCommsBySiteCodeFail());
    }
  } catch (error) {
    console.log(error)
  }
}

function* saveCommBySitecode({ payload: commsData}: any) {
  try {
    const response: IResponse = yield call(saveCommBySitecodeAsync, commsData);
    if(response.success) {
      yield put(saveCommBySitecodeSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}


function* commsSaga() {
  yield takeEvery(CommsTypes.GET_COMMS_BY_SITECODE, getCommsBySiteCode)
  yield takeEvery(CommsTypes.SAVE_COMMS, saveCommBySitecode)
}

export default commsSaga;


