import Select from "react-select";

interface ISelectedOption {
    value: string;
    label: string;
}

interface IProps {
  siteCode: string;
  siteName: string;
  productList: Array<{
    value: string;
    label: string;
  }>;
  handleChange: (selectedOption: ISelectedOption) => void;
}

const SiteSelect = (props: IProps) => {
  const handleChange = (selectedOption: any) => {
    props.handleChange(selectedOption);
  };
  return (
    <Select
      value={
        props.siteCode
          ? {
              value: props.siteCode,
              label: props.siteName,
            }
          : null
      }
      onChange={(selectedOption: ISelectedOption) => {
        handleChange(selectedOption);
      }}
      options={
        props.productList.length > 0
          ? props.productList
          : [{ value: null, label: "Loading" }]
      }
      isSearchable={true}
    />
  );
};

export default SiteSelect;
