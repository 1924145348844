import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import {
  Col,
  Row,
  Label,
  Input,
  Card,
  CardTitle,
  Spinner,
  FormGroup,
} from "reactstrap";
import {
  getAllSitesByCompanyCode,
  getSiteBySiteCode,
} from "../../store/actions";
import SiteSelect from "src/components/Common/SiteSelect";
import { addGeoFence } from "../../store/actions";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { storage } from "../../helpers/firebase_config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const GeoFenceSchema = Yup.object().shape({
  title: Yup.string().required("The title is required."),
  radius: Yup.string().required("The radius is required."),
  siteCode: Yup.string().required("The site code is required."),
  latitude: Yup.number().required("The latitude is required."),
  longitude: Yup.number().required("The longitude is required."),
  description: Yup.string().required("The decription is required."),
  image: Yup.string().required("The image is required."),
});
const AddGeoFence = (props: any) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: any) => state.login);
  const [siteList, setSitesList] = useState<any>([]);
  const [siteName, setSiteName] = useState<string>("");
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  // const { sites, loading } = useSites(currentUser?.code);
  const { sites, loading, authSite, success } = useSelector(
    (state: any) => state.site
  );

  const { loading_product } = useSelector((state: any) => state.product);

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      dispatch(getSiteBySiteCode(currentUser.siteCode));
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      siteCodeLists(Array(authSite));
    } else {
      siteCodeLists(sites);
    }
  }, [sites, authSite]);

  const siteCodeLists = (lists: any) => {
    let arr: {value: string; label: string }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        arr.push({value: item.code, label: item.name });
      });
    }
    setSitesList(arr);
  };
  

  const handleChange = (selectedOption: any, setFieldValue: any) => {
    setFieldValue("siteCode", selectedOption.value);
    setSiteName(selectedOption.label)
  };

  const handleAcceptedImageFiles = async (setFieldValue: any, files: any) => {
    setImageUploading(true);
    const path = `/geo-fence/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setImageUploading(false);
    setFieldValue("image", url);
  };

  return (
    <React.Fragment>
      <div className="add-product">
        <MetaTags>
          <title>GeoFence List</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-product-form" style={{ padding: 20 }}>
          <Formik
            initialValues={{
              title: "",
              radius: "",
              siteCode: "",
              latitude: "",
              longitude: "",
              description: "",
              image: "",
            }}
            validationSchema={GeoFenceSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              dispatch(addGeoFence(values));
              actions.resetForm({
                values: {
                  title: "",
                  radius: "",
                  siteCode: "",
                  latitude: "",
                  longitude: "",
                  description: "",
                  image: ""
                },
              });
              props.onDrawerClose(false);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="siteCode">Site name</Label>
                        <Field
                          name="siteCode"
                          render={({ field }: any) => (
                            <SiteSelect
                              siteCode={values.siteCode}
                              siteName={siteName}
                              productList={siteList}
                              handleChange={(selectedOption)=>handleChange(selectedOption, setFieldValue)}
                            />
                          )}
                        />
                        {errors.siteCode && touched.siteCode ? (
                          <div className="error-message">{errors.siteCode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="title">Title</Label>
                        <Field
                          name="title"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.title && touched.title ? (
                          <div className="error-message">{errors.title}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="radius">Radius (km)</Label>
                        <Field
                          name="radius"
                          render={({ field }: any) => (
                            <Input {...field} type="number" />
                          )}
                        />
                        {errors.radius && touched.radius ? (
                          <div className="error-message">{errors.radius}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="latitude">Latitude</Label>
                        <Field
                          name="latitude"
                          render={({ field }: any) => (
                            <Input {...field} type="number" />
                          )}
                        />
                        {errors.latitude && touched.latitude ? (
                          <div className="error-message">{errors.latitude}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="longitude">Longitude</Label>
                        <Field
                          name="longitude"
                          render={({ field }: any) => (
                            <Input {...field} type="number" />
                          )}
                        />
                        {errors.longitude && touched.longitude ? (
                          <div className="error-message">{errors.longitude}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="description">Description</Label>
                        <Field
                          name="description"
                          render={({ field }: any) => (
                            <textarea
                              {...field}
                              className="form-control"
                              rows={2}
                            />
                          )}
                        />
                        {errors.description && touched.description ? (
                          <div className="error-message">
                            {errors.description}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CardTitle>Image</CardTitle>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedImageFiles(setFieldValue, acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drop files here or click to upload.</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews" id="file-previews">
                      {imageUploading && (
                        <div className="d-flex justify-content-center">
                          <i
                            className="bx bx-loader bx-spin align-middle me-2"
                            style={{ fontSize: 46 }}
                          ></i>
                        </div>
                      )}
                      <div className="pt-3 pb-3">
                        <FormGroup>
                          <Label for="image">Or Please enter image URL.</Label>
                          <Field
                            name="image"
                            render={({ field }: any) => (
                              <Input {...field} type="text" />
                            )}
                          />
                          {errors.image && touched.image ? (
                            <div className="error-message">{errors.image}</div>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={loading_product}
                      >
                        {loading_product && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddGeoFence);
