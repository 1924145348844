import axios from "axios"
const token = sessionStorage.getItem('accessToken')
import config from "../config";

const was_test_url = "https://apiwasuat.warrantylogix.com";
const was_prod_url = "https://apiwas.warrantylogix.com";

export const axiosApi = axios.create({
  baseURL: config.apiUrl,
})

axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url : string, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url : string, data : any, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url : string, data : any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url : string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}


// // Was API.

// export const axiosWASApi = axios.create({
//   baseURL: was_test_url,
//   auth: {
//     username: "hendy-api", 
//     password: "bG$0O3M3%^5e"
//   },
//   headers: {
//     "Content-Type": "application/json"
//   }
// })


// export async function getWas(url : string, config = {}) {
//   return await axiosWASApi.get(url).then(response => response.data)
// }

// export async function postWas(url : string, data : any, config = {}) {
//   return await axiosWASApi
//     .post(url, { ...data }, { ...config })
//     .then(response => response.data)
// }
