export enum Permissions {
    COMPANIES = "companies",
    SITES = "sites",
    ADMINS = "admins",
    CUSTOMERS = "customers",
    BOOKINGS = "bookings",
    CHECKINS = "checkins",
    NOTIFICATIONS = "notifications",
    KEYLOCKER = "keylocker",
    COMMS = "comms",
    CHECKIN_SETTINGS = "checkinSettings",
    BOOKING_TOOL = "bookingTool",
    PRODUCTS = "products",
    GEOFENCE = "geofence",
    WARRANTY = "warranty",
    VEHICLES = "vehicles",
    QUOTES = "quotes",
    INVOICES = "invoices",
    PAYMENT_SETTING = "paymentSetting",
    PAYMENT_HISTORY = "paymentHistory",
    SALE_OF_PRODUCTS = "saleOfProducts",
}