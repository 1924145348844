
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import styled from "styled-components";




interface IProps {
    children?: any;
    loading?: boolean;
    metaTitle: string;
    footer?: any;
    modal?: any;
}


const BaseTableCard = (props: IProps) => {

    return (
        <div className="page-content">
            <MetaTags>
                <title>{props.metaTitle}</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                {props.children}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {props.loading ? (
                    <LoadingContainer>
                        <i className="bx bx-loader bx-spin align-middle" style={{ fontSize: 46 }}></i>
                    </LoadingContainer>
                ) : null}
                {props.footer}
                {props.modal}
            </Container>
        </div>
    )
}



export default BaseTableCard;


const LoadingContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`