import { useState, useEffect } from "react";
import { IGroup } from "src/interfaces/general";
import { getGroupsByCompanyCodeAsync, getAllGroupsAsync, getGroupByIdAsync, addGroupAsync, deleteGroupByIdAsync, updateGroupByIdAsync } from "src/helpers/backend_helper";

const useGroups = (companyCode?: string) => {
    const [ groups, setGroups ] = useState<Array<IGroup>>([]);
    const [ singleGroup, setSingleGroup ] = useState<IGroup>();
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const fetchGroups = () => {
        setLoading(true);
        if ( companyCode ){
            getGroupsByCompanyCodeAsync(companyCode)
            .then(res=>{
                if(res.result){
                    setGroups(res.result||[]);
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    }

    const fetchGroupById = (id:string) => {
        setLoading(true);
        if (id){
            getGroupByIdAsync(id)
            .then(res=>{
                if (res.success) {
                    setSingleGroup(res.result||{})
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    }

    const addGroup = async (group: IGroup) => {
        try{
            setLoading(true);
            const result = await addGroupAsync(group);
            if (result.success){
                groups.push(group);
                setGroups([...groups]);
                setLoading(false);
                setSuccess(true);
                return true;
            }
        }catch(error){
            console.log("group add error", error);
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    const deleteGroup = async (id: string) => {
        const index = groups.findIndex(group=>group.id == id);
        try{
            setLoading(true);
            const result = await deleteGroupByIdAsync(id);
            if (result.success) {
                groups.splice(index, 1);
                setGroups([...groups]);
                setLoading(false);
                return true;
            }
        }catch(error){
            console.log("group delete error", error);
        }
        setLoading(false);
        return false;
    }

    const updateGroup = async (id: string, group: IGroup) => {
        try{
            setLoading(true);
            const result = await updateGroupByIdAsync(id, group);
            if ( result.success){
                groups.push(group);
                setGroups([...groups]);
                setLoading(false);
                setSuccess(true);
                return true;
            }
        }catch(error){
            console.log("group update error", error);
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    const changeGroup = (group:IGroup)=>{
        setSingleGroup(group);
    }

    useEffect(() => {
        fetchGroups()
    }, [companyCode]);

    return {
        groups,
        singleGroup,
        fetchGroupById,        
        addGroup,
        deleteGroup,
        updateGroup,
        changeGroup,
        loading,
        success,
    }
}

export default useGroups;