import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    getNotificationTemplatesByCompanyCodeAsync,
    getNotificationTemplateByIdAsnc,
    addNotificationTemplateAsync,
    deleteNotificationTemplateByIdAsync,
    updateNotificationTemplateByIdAsync,
    createNotificationsAsync
} from "src/helpers/backend_helper";
import { ITemplate } from "src/interfaces/general";


const useNotificationTemplates = () => {

    const [templates, setTemplates] = useState<Array<ITemplate>>([]);
    const [singleTemplate, setSingleTemplate] = useState<ITemplate>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const user = useSelector((state: any) => state.login.currentUser);
    const companyCode = user?.code || user?.companyCode;

    const fetchTemplatesByCompanyCode = () => {
        if (companyCode) {
            setLoading(true);
            getNotificationTemplatesByCompanyCodeAsync(companyCode)
                .then(res => {
                    if (res.success) {
                        setTemplates(res.result || [])
                    }
                }).catch(console.log).finally(() => setLoading(false));
        }
    }

    const fetchTemplateById = (id: string) => {
        setLoading(true);
        if (id) {
            getNotificationTemplateByIdAsnc(id)
                .then(res => {
                    if (res.success) {
                        setSingleTemplate(res.result || {})
                    }
                }).catch(console.log).finally(() => setLoading(false));
        }
    }

    const addTemplate = async (template: ITemplate) => {
        try {
            setLoading(true);
            const result = await addNotificationTemplateAsync(template);
            if (result.success) {
                templates.push(template);
                setTemplates([...templates]);
                setLoading(false);
                setSuccess(true);
                return true;
            }
        } catch (error) {
            console.log("template add error", error)
        }
        setLoading(false);
        return false;
    }

    const deleteTemplate = async (id: string) => {
        setLoading(true);
        try {
            const result = await deleteNotificationTemplateByIdAsync(id);
            if (result.success) {
                setTemplates(templates.filter(template => template.id !== id));
                setLoading(false);
                return true;
            }
        } catch (error) {
            console.log("template delete error", error)
        }
        setLoading(false);
        return false;
    }

    const updateTemplate = async (id: string, template: ITemplate) => {
        try {
            setLoading(true);
            const result = await updateNotificationTemplateByIdAsync(id, template);
            if (result.success) {
                setTemplates(
                    templates.map(t => {
                        if (t.id === id) {
                            return template;
                        }
                        return t;
                    })
                );
                setLoading(false);
                setSuccess(true);
                return true;
            }
        } catch (error) {
            if (error instanceof Error){
                console.log("template update error", error.message)
            }
        }
        setLoading(false);
        return false;
    }

    const changeTemplate = (template: ITemplate) => {
        setSingleTemplate(template)
    }

    const sendTemplate = async (data: any) => {
        try {
            setLoading(true);
            setSuccess(true);
            const result = await createNotificationsAsync(data);
            if (result.success) {
                setSuccess(true);
                return true;
            }
            setLoading(false);
        } catch (error) {
            console.log("notification send error", error);
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    return {
        fetchTemplatesByCompanyCode,
        templates,
        loading,
        fetchTemplateById,
        deleteTemplate,
        addTemplate,
        updateTemplate,
        changeTemplate,
        sendTemplate,
        success,
        singleTemplate
    }
}

export default useNotificationTemplates;