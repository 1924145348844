import React, { useEffect } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getBookingByDocId, updateSiteByDocId } from "../../store/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";

const EditBooking = (props: any) => {
  const dispatch = useDispatch();
  const { docid } = useParams<any>();
  const history = useHistory();

  const { signleBooking } = useSelector((state: any) => state.booking);

  useEffect(() => {
    dispatch(getBookingByDocId(docid));
  }, []);

  const handleEditSite = (values: any) => {
    // dispatch(updateSiteByDocId(docid, values));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Booking</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/bookings" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Booking Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: BookingId: {signleBooking?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        <AvForm
          onValidSubmit={(e: any, values: any) => {
            handleEditSite(values);
          }}
        >
          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="companyCode"
                  label="Company Code"
                  placeholder="Company Code should 5 digits"
                  type="number"
                  errorMessage="Should be 5 digits"
                  validate={{
                    required: { value: true },
                    minLength: {
                      value: 5,
                      errorMessage: "Min 5 chars.",
                    },
                    maxLength: {
                      value: 5,
                      errorMessage: "Max 5 chars.",
                    },
                  }}
                  value={signleBooking?.companyCode || ""}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="siteCode"
                  label="Site Code"
                  placeholder="site code should 7 digits"
                  type="number"
                  errorMessage="Should be 7 digits"
                  validate={{
                    required: { value: true },
                    minLength: {
                      value: 7,
                      errorMessage: "Min 7 chars.",
                    },
                    maxLength: {
                      value: 7,
                      errorMessage: "Max 7 chars.",
                    },
                  }}
                  value={signleBooking?.siteCode || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="customerId"
                  label="Customer Id"
                  type="text"
                  errorMessage="Invalid customerId"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.customerId || ""}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="regNo"
                  label="Reg No"
                  type="text"
                  errorMessage="Invalid Reg No"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.regNo || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="phone"
                  label="Phone"
                  type="text"
                  errorMessage="Invalid Phone number"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.phone || ""}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="email"
                  label="Email"
                  type="email"
                  errorMessage="Invalid Email"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.email || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="firstName"
                  label="First Name"
                  type="text"
                  errorMessage="Invalid Name"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.firstName || ""}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="lastName"
                  label="Last Name"
                  type="text"
                  errorMessage="Invalid Name"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.lastName || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="postCode"
                  label="Post Code"
                  type="text"
                  errorMessage="Invalid postCode"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.postCode || ""}
                />
              </div>
            </Col>

            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="make"
                  label="Make"
                  type="text"
                  errorMessage="Invalid make"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.make || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="model"
                  label="Model"
                  type="text"
                  errorMessage="Invalid model"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.model || ""}
                />
              </div>
            </Col>

            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="telephone"
                  label="Telephone"
                  type="text"
                  errorMessage="Invalid telephone"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.telephone || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="title"
                  label="Title"
                  type="text"
                  errorMessage="Invalid title"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.title || ""}
                />
              </div>
            </Col>

            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="dueDate"
                  label="Due Date"
                  type="text"
                  errorMessage="Invalid dueDate"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.dueDate || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="status"
                  label="Status"
                  type="text"
                  errorMessage="Invalid status"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.status || ""}
                />
              </div>
            </Col>

            <Col md="6">
              <div className="mb-3">
                <AvField
                  name="source"
                  label="Source"
                  type="text"
                  errorMessage="Invalid source"
                  validate={{
                    required: { value: true },
                  }}
                  value={signleBooking?.source || ""}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Update
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditBooking);
