export enum WarrantyTypes {
  ADD_WARRANTY = 'ADD_WARRANTY',
  GET_ALL_WARRANTY_LISTS = "GET_ALL_WARRANTY_LISTS",
  GET_WARRANTY_LIST_SUCCESS = "GET_WARRANTY_LIST_SUCCESS",
  GET_WARRANTY_LIST_FAIL = "GET_WARRANTY_LIST_FAIL",
  ADD_WARRANTY_SUCCESS = "ADD_WARRANTY_SUCCESS",
  ADD_WARRANTY_FAIL = "ADD_WARRANTY_FAIL",
  GET_WARRANTY_BY_DOCID = "GET_WARRANTY_BY_DOCID",
  GET_WARRANTY_BY_DOCID_SUCCESS = "GET_WARRANTY_BY_DOCID_SUCCESS",
  GET_WARRANTY_BY_DOCID_FAIL = "GET_WARRANTY_BY_DOCID_FAIL",
  UPDATE_WARRANTY_BY_DOCID = "UPDATE_WARRANTY_BY_DOCID",
  UPDATE_WARRANTY_BY_DOCID_SUCCESS = "UPDATE_WARRANTY_BY_DOCID_SUCCESS",
  UPDATE_WARRANTY_BY_DOCID_FAIL = "UPDATE_WARRANTY_BY_DOCID_FAIL",
  DELETE_WARRANTY_BYID = "DELETE_WARRANTY_BYID",
  DELETE_WARRANTY_BYID_SUCCESS = "DELETE_WARRANTY_BYID_SUCCESS",
  DELETE_WARRANTY_BYID_FAIL = "DELETE_WARRANTY_BYID_FAIL",
}
