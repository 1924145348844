import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, Input, Col, Container, Row, Button, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, Spinner, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { SketchPicker } from 'react-color';
import { SpinnerCircular } from "spinners-react";
import Dropzone from "react-dropzone";
import { formatBytes } from "../../helpers/general";
import { storage } from "../../helpers/firebase_config";
import { saveBookingToolSetting, getBookingToolDataBySiteCode } from "../../store/actions";

interface IProps {
  siteCode: string;
  removeSiteCode: () => void;
  LOGIN_METHOD: string
}

const BookingToolComponent = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState({
    clientId: '',
    secretKey: '',
    contractCode: '',
    stagingContractCode: '',
    logo: '',
    themeColor: '',
    workShops: [],
    stagingWorkShops: []
  })
  const [modalBasic, setModalBasic] = useState(false);
  const [tempColor, setTempColor] = useState("#fff")
  const [openPicker, setOpenPicker] = useState(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [uploading, setUploading] = useState<any>(false);
  const [uploadsuccess, setUploadSuccess] = useState<boolean>(false);

  const { loading, signleBookingToolSetting } = useSelector((state: any) => state.bookingTool);
  const { currentUser } = useSelector((state: any) => state.login);

  useEffect(() => {
    dispatch(getBookingToolDataBySiteCode(props.siteCode));
  }, [props.siteCode]);

  useEffect(() => {
    setData({...data, 
    clientId: signleBookingToolSetting?.clientId ? signleBookingToolSetting?.clientId : '',
    secretKey: signleBookingToolSetting?.secretKey ? signleBookingToolSetting?.secretKey : '',
    contractCode: signleBookingToolSetting?.contractCode ? signleBookingToolSetting?.contractCode : '',
    stagingContractCode: signleBookingToolSetting?.stagingContractCode ? signleBookingToolSetting?.stagingContractCode : '',
    logo: signleBookingToolSetting?.logo ? signleBookingToolSetting?.logo : '',
    themeColor: signleBookingToolSetting?.themeColor ? signleBookingToolSetting?.themeColor : '',
    workShops: signleBookingToolSetting?.workShops ? signleBookingToolSetting?.workShops : [],
    stagingWorkShops: signleBookingToolSetting?.stagingWorkShops ? signleBookingToolSetting?.stagingWorkShops : []})

  }, [signleBookingToolSetting]);

  const handleChangeComplete = (color:any) => {
    setTempColor(color.hex)
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      setUploading(true);
      const path = `/avatar/${selectedFiles[0].name}`;
      const ref = storage.ref(path);
      await ref.put(selectedFiles[0]);
      const url = await ref.getDownloadURL();
      if (url) {
        setUploadSuccess(true);
      } else {
        setUploadSuccess(false);
      }
      setUploading(false);
      setselectedFiles([]);
      setData({ ...data, logo: url });
    } else {
      alert("please select the file which you want upload!");
    }
  };

  const addWorkShop = () => {
    const temp: any = data.workShops
    temp.push({name: '', key: ''})
    setData({...data, workShops: temp})
  }

  const removeWorkShip = (index: number) => {
    data.workShops.splice(index, 1)
    setData({...data, workShops: data.workShops})
  }

  const addStagingWorkShop = () => {
    const temp: any = data.stagingWorkShops
    temp.push({name: '', key: ''})
    setData({...data, stagingWorkShops: temp})
  }

  const removeStagingWorkShip = (index: number) => {
    data.stagingWorkShops.splice(index, 1)
    setData({...data, stagingWorkShops: data.stagingWorkShops})
  }

  const onChangeWorkShopValue =(e: any, index: number) => {
    let temp: any = []
    if(e.target.name === 'name') {
      temp = data.workShops
      temp[index].name = e.target.value
    }
    if(e.target.name === 'key') {
      temp = data.workShops
      temp[index].key = e.target.value
    }
    setData({...data, workShops: temp})    
  }

  const onChangeStagingWorkShopValue =(e: any, index: number) => {
    let temp: any = []
    if(e.target.name === 'name') {
      temp = data.stagingWorkShops
      temp[index].name = e.target.value
    }
    if(e.target.name === 'key') {
      temp = data.stagingWorkShops
      temp[index].key = e.target.value
    }
    setData({...data, stagingWorkShops: temp})    
  }

  const onChangeValue = (e: any) => {
    setData({...data, [e.target.name]: e.target.value})
  }

  const onSubmit = () => {
    const requestData: any = data
    requestData.siteCode = props.siteCode
    dispatch(saveBookingToolSetting(requestData))
  }

  return (
        <Container fluid>
          <Row className="mt-4">
            <div className="mb-4">
              {props.LOGIN_METHOD === 'COMPANY_LOGIN' && (
                 <button type="button" className="btn btn-primary waves-effect waves-light" onClick={props.removeSiteCode}>
                 <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>{" "}
                 Back
               </button>
              )}
            </div>
          </Row>
          <Row>
            <Col md = {6}>
                <Row>
                  <Col md = {6}>
                    <FormGroup className="mb-3">
                      <Label>Client ID</Label>
                      <Input className="form-control" type="text"  name="clientId" value = {data.clientId} onChange = {(e: any) => onChangeValue(e)}/>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label> Secret Key </Label>
                      <Input className="form-control" type="text"  name="secretKey" value = {data.secretKey} onChange = {(e: any) => onChangeValue(e)}/>
                    </FormGroup>
                  </Col>
                  <Col md = {6}>
                    <FormGroup className="mb-3">
                      <Label > Contract Code </Label>
                      <Input className="form-control" type="text"  name="contractCode" value = {data.contractCode} onChange = {(e: any) => onChangeValue(e)}/>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label>Staging Contract Code</Label>
                      <Input className="form-control" type="text"  name="stagingContractCode" value = {data.stagingContractCode} onChange = {(e: any) => onChangeValue(e)}/>
                    </FormGroup>
                  </Col>
                </Row>
              <div className="d-flex align-items-end">

                <div className="mb-2">
                  <Label htmlFor="themeColor">Theme Color</Label>
                  <Input className="form-control" type="text"  id="theme-color" name="themeColor" value={data.themeColor} onChange = {() => {}}/>
                </div>
                
                <Button color="primary" className="label-btn m-2" style={{height: 40}} onClick={() => {
                    setModalBasic(!modalBasic)
                    setOpenPicker(!openPicker)
                  }}
                  >
                  Color Picker
                </Button>
              </div>
            </Col>

              <Col md = {6}>
                <Row className="">
                  <Col md="12">
                      <CardTitle>Logo</CardTitle>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h6>Drop files here or click to upload.</h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {uploading && (
                          <div className="d-flex justify-content-center">
                            <Spinner
                              type="grow"
                              className="ms-2"
                              color="primary"
                            />
                          </div>
                        )}
                        {selectedFiles.map((file: any, i: number) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={file.name}
                                      src={file.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {file.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{file.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      <div className="text-center mt-4">
                        {uploadsuccess && (
                          <Alert color="success">Upload Logo Success!</Alert>
                        )}
                        <button type="button" className="btn btn-primary " onClick={handleFileUpload}>Upload</button>
                      </div>
                  </Col>
                </Row>
              </Col>
          </Row>

          <Row className="mt-4">
            <Col md = {6}>
              <div className="d-flex justify-content-between align-items-center">
                <h4>WorkShop</h4>
                <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light" onClick={addWorkShop}>Add</button>
              </div>
              {data?.workShops?.map((item: any, index: number) => {
                return (
                  <Row className="align-items-end" key={index}>
                      <Col md = {5}>
                        <div>
                          <Label className="form-Label" >Name</Label>
                          <Input className="form-control" type="text" name="name" value = {item.name} onChange = {(e) => onChangeWorkShopValue(e, index)}/>
                        </div>
                      </Col>
                      <Col md = {5}>
                        <div>
                          <Label className="form-Label">Key</Label>
                          <Input className="form-control" type="text" name = "key" value = {item.key} onChange = {(e) => onChangeWorkShopValue(e, index)}/>
                        </div>
                      </Col>
                      <Col md = {2}>
                        <button type="button" className="btn btn-danger btn-rounded waves-effect waves-light" onClick={() => removeWorkShip(index)}> Remove </button>
                      </Col>
                  </Row>
                )
              })}
            </Col>

            <Col md = {6}>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Staging WorkShop</h4>
                <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light" onClick={addStagingWorkShop}>Add</button>
              </div>
              {data?.stagingWorkShops?.map((item: any, index: number) => {
                return (
                  <Row className="align-items-end" key={index}>
                      <Col md = {5}>
                        <div>
                          <Label className="form-Label">Name</Label>
                          <Input className="form-control" type="text"  name = "name" value = {item.name} onChange = {(e) => onChangeStagingWorkShopValue(e, index)}/>
                        </div>
                      </Col>
                      <Col md = {5}>
                        <div>
                          <Label className="form-Label">Key</Label>
                          <Input className="form-control" type="text"  name = "key" value = {item.key} onChange = {(e) => onChangeStagingWorkShopValue(e, index)}/>
                        </div>
                      </Col>
                      <Col md = {2}>
                        <button type="button" className="btn btn-danger btn-rounded waves-effect waves-light" onClick={() => removeStagingWorkShip(index)}>Remove</button>
                      </Col>
                  </Row>
                )
              })}
            </Col>
           
          </Row>

          <Row className="mt-4">
            <Col md = {6}>
              <button type="submit" className="btn btn-success save-user" disabled={loading} onClick = {onSubmit}>
                {loading ? (
                  <SpinnerCircular
                    size={30}
                    thickness={150}
                    speed={150}
                    color="#36ad47"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                ) : (
                  <>Submit</>
                )}
              </button>
            </Col>
          </Row>

          <Modal
            isOpen={modalBasic}
            toggle={() => setModalBasic(!modalBasic)}
          >
            <ModalHeader>
              Color Picker
            </ModalHeader>
            <ModalBody>
              <SketchPicker
                width='100%'
                color={tempColor}
                onChangeComplete={handleChangeComplete}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  setModalBasic(false)
                  setData({...data, themeColor: tempColor})
                }}
              >
                Apply
              </Button>{' '}
              <Button
                color="secondary"
                onClick={() => setModalBasic(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
  );
};

export default BookingToolComponent;
