import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SpinnerCircular } from "spinners-react";
import { useSelector } from "react-redux";
import { IBooking } from "src/interfaces/general";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const AddBooking = (props: any) => {

  const [date, setDate] = useState(new Date());
  const dueDate = moment(date).format("YYYY-MM-DD");
  const { currentUser } = useSelector((state: any) => state.login);

  const handleAddBooking = (booking: IBooking) => {
    booking.dueDate = dueDate
    props.addBooking(booking)    
};
  useEffect(() => {
    if(props.success) {
      props.onDrawerClose(false)
    }
  }, [props.success])
  
  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Booking</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-company-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddBooking(values);
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="companyCode"
                    label="Company Code"
                    placeholder="Company Code should 5 digits"
                    type="string"
                    errorMessage="Should be 5 digits"
                    validate={{
                      required: { value: true },
                      minLength: {
                        value: 5,
                        errorMessage: "Min 5 chars.",
                      },
                      maxLength: {
                        value: 5,
                        errorMessage: "Max 5 chars.",
                      },
                    }}
                    value={""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="siteCode"
                    label="Site Code"
                    placeholder="site code should 7 digits"
                    type="string"
                    errorMessage="Should be 7 digits"
                    validate={{
                      required: { value: true },
                      minLength: {
                        value: 7,
                        errorMessage: "Min 7 chars.",
                      },
                      maxLength: {
                        value: 7,
                        errorMessage: "Max 7 chars.",
                      },
                    }}
                    value={""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <label> Customer Contact Info </label>                  
                </div>
              </Col>              
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="firstName"
                    label="First Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="lastName"
                    label="Last Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Phone"
                    type="text"
                    errorMessage="Invalid Phone number"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="title"
                      label="Title"
                      type="text"
                      errorMessage="Invalid title"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="customerId"
                    label="Customer Id"
                    type="text"
                    errorMessage="Invalid customerId"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>              
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <label> Vehicle info </label>                  
                </div>
              </Col>              
            </Row>

            <Row>
            <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="regNo"
                    label="Reg No"
                    type="text"
                    errorMessage="Invalid Reg No"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="postCode"
                    label="Post Code"
                    type="text"
                    errorMessage="Invalid postCode"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row>
                       
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="make"
                    label="Make"
                    type="text"
                    errorMessage="Invalid make"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="model"
                    label="Model"
                    type="text"
                    errorMessage="Invalid model"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row>

            <Row>              
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="telephone"
                    label="Telephone"
                    type="text"
                    errorMessage="Invalid telephone"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">                  
                  <label> Due Date </label>
                  <DatePicker selected={date} onChange={(date:Date) => setDate(date)} className="form-control"  />
                </div>
              </Col>
            </Row>
            
            {/* <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="status"
                    label="status"
                    type="text"
                    errorMessage="Invalid status"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="source"
                    label="source"
                    type="text"
                    errorMessage="Invalid source"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            </Row> */}

            <Row>
              <Col>
                <div className="text-end">
                  <button 
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Save</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddBooking);
