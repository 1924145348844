import { Row, Col, Container } from "reactstrap";
import CarouselPage from "../CarouselPage";
import { useTranslation } from "react-i18next";
import loginlogo from "../../../assets/images/loginlogo.png";
import styled from "styled-components";

const AuthUI = (props: any) => {
  const { t } = useTranslation();
  const companyName: string = "Hendy";

  return (
    <div className="auth-page">
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col lg={4} md={5} className="col-xxl-3">
            <div className="auth-full-page-content d-flex percentPadding-10">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <LogoImg className="avatar-edit" src={loginlogo} />
                      <h5 className="mb-0"> {t("LoginWelcome")}</h5>
                      <p className="text-muted mt-2">
                        {t("LoginTitle") + companyName + " Admin."}
                      </p>
                    </div>
                    {props?.showLogin && (
                      <div className="mb-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ height: 50 }}
                          onClick={props.login}
                        >
                          {t("Login")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <CarouselPage />
        </Row>
      </Container>
    </div>
  );
};

export default AuthUI;

const LogoImg = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;
