import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {addVehicle} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";


const AddVehicle = (props: any) => {

  const dispatch = useDispatch();
  const { companyCode } = useParams<any>();

  const handleAddVehicle = (values: any) => {
    dispatch(addVehicle(values));
  };

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Vehicle</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-company-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddVehicle(values);
            }}
          >
            <div className="add-vehicle-form-cotainer">
            
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="regNo"
                      label="Reg No"
                      type="text"
                      errorMessage="Invalid Reg No"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="image"
                      label="Image"
                      type="text"
                      errorMessage="Invalid Image"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="customerId"
                      label="Customer Id"
                      type="text"
                      errorMessage="Invalid customerId"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="companyCode"
                    label="Company Code"
                    type="text"
                    errorMessage="Invalid Company Code"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
                
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="tax"
                      label="Tax"
                      type="text"
                      errorMessage="Invalid Tax"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="mot"
                      label="Mot"
                      type="text"
                      errorMessage="Invalid Mot"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="warranty"
                      label="Warranty"
                      type="text"
                      errorMessage="Invalid Warranty"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col> 
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="reminders"
                      label="Reminders"
                      type="text"
                      errorMessage="Invalid Reminders"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>               
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddVehicle);
