import { AdminTypes } from "./actionTypes";

export const INIT_STATE: any = {
  admins: [],
  singleAdmin: {},
  error: {},
  loading: false,
  success: false,
};

const site = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AdminTypes.GET_ADMINS_BY_COMPANYCODE:
      return {
        ...state,
        loading: true,
      };
    case AdminTypes.GET_ADMINS_BY_COMPANYCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
      };

    case AdminTypes.GET_ADMINS_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case AdminTypes.GET_ADMINS_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload,
      };

    case AdminTypes.GET_ADMINS_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AdminTypes.ADD_ADMIN:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case AdminTypes.ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        admins: [...state.admins, action.payload],
      };

    case AdminTypes.ADD_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case AdminTypes.GET_ADMIN_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case AdminTypes.GET_ADMIN_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAdmin: action.payload,
      };

    case AdminTypes.GET_ADMIN_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AdminTypes.UPDATE_ADMIN_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case AdminTypes.UPDATE_ADMIN_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAdmin: action.payload,
      };

    case AdminTypes.UPDATE_ADMIN_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AdminTypes.DELETE_ADMIN_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case AdminTypes.DELETE_ADMIN_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: state.admins.filter(
          (admin: any) => admin.id !== action.payload
        ),
      };

      case AdminTypes.DELETE_ADMIN_BY_DOCID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  

    // case AdminTypes.UPDATE_COMPANY:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case AdminTypes.UPDATE_COMPANY_SUCCESS:

    //   return {
    //     ...state,
    //     loading: false,
    //     companies: [
    //       ...state.companies.filter((item: any) => item.code === action.payload.code),
    //       ...action.payload
    //     ]
    //   };

    // case AdminTypes.UPDATE_COMPANY_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload
    //   };
    default:
      return state;
  }
};

export default site;
