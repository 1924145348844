import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Icon from "@ailibs/feather-react-ts";

interface IProps {
    title: string;
    description: string;
    onBack?: () => void;
}

const NavHeader = (props: IProps) => {

    const history = useHistory();

    const onBack = () => {
        history.goBack();
    }

    return (
        <Row>
            <Col md="12">
                <Card>
                    <CardBody>
                        <Row>
                            <div className="col-sm order-2 order-sm-1">
                                <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div style={{ marginRight: 10 }}>
                                        <div onClick={onBack} style={{ cursor: 'pointer' }}>
                                            <Icon name="arrow-left" />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <div>
                                            <h5 className="font-size-20 mb-1">
                                                {props.title}
                                            </h5>
                                            <span className="text-muted font-size-13">
                                                {props.description}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default NavHeader;