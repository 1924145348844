export enum DirectoryTypes {
  
  GET_DIRECTORY_BY_SITECODE = "GET_DIRECTORY_BY_SITECODE",
  GET_DIRECTORY_BY_SITECODE_SUCCESS = 'GET_DIRECTORY_BY_SITECODE_SUCCESS',
  GET_DIRECTORY_BY_SITECODE_FAIL = 'GET_DIRECTORY_BY_SITECODE_FAIL',
  GET_SITES_BY_SERVICEID = 'GET_SITES_BY_SERVICEID',
  GET_SITES_BY_SERVICEID_SUCCESS = 'GET_SITES_BY_SERVICEID_SUCCESS',
  GET_SITES_BY_SERVICEID_FAIL = 'GET_SITES_BY_SERVICEID_FAIL',
  UPDATE_DIR_STATUS_BY_SERVICE = 'UPDATE_DIR_STATUS_BY_SERVICE',
  UPDATE_DIR_STATUS_BY_SERVICE_SUCCESS = 'UPDATE_DIR_STATUS_BY_SERVICE_SUCCESS'

}
