import { GeoFenceTypes } from "./actionTypes";


export const getAllGeoFenceList = () => ({
  type: GeoFenceTypes.GET_ALL_GEOFENCE_LISTS,
});

export const getGeoFenceSuccess = (geoFenceList: any) => ({
  type: GeoFenceTypes.GET_GEOFENCE_LIST_SUCCESS,
  payload: geoFenceList,
});

export const getGeoFenceFail = (error: any) => ({
  type: GeoFenceTypes.GET_GEOFENCE_LIST_FAIL,
  payload: error,
});

export const addGeoFence = (geofence: any) => ({
  type: GeoFenceTypes.ADD_GEO_FENCE,
  payload: geofence,
});

export const addGeoFenceSuccess = (geofence: any) => ({
  type: GeoFenceTypes.ADD_GEO_FENCE_SUCCESS,
  payload: geofence,
});

export const addGeoFenceFail = (error: any) => ({
  type: GeoFenceTypes.ADD_GEO_FENCE_FAIL,
  payload: error,
});

export const getGeoFenceByDocId = (docid: string) => ({
  type: GeoFenceTypes.GET_GEOFENCE_BY_DOCID,
  payload: docid
})

export const getGeoFenceByDocIdSuccess = (admin: any) => ({
  type: GeoFenceTypes.GET_GEOFENCE_BY_DOCID_SUCCESS,
  payload: admin,
});

export const getGeoFenceByDocIdFail = (error: any) => ({
  type: GeoFenceTypes.GET_GEOFENCE_BY_DOCID_FAIL,
  payload: error,
});

export const deleteGeoFenceById = (docid: string) => ({
  type: GeoFenceTypes.DELETE_GEOFENCE_BYID,
  payload: docid,
});

export const deleteGeoFenceByDocIdSuccess = (docid: any) => ({
  type: GeoFenceTypes.DELETE_GEOFENCE_BYID_SUCCESS,
  payload: docid,
});

export const deleteGeoFenceByDocIdFail = (error: any) => ({
  type: GeoFenceTypes.DELETE_GEOFENCE_BYID_FAIL,
  payload: error,
});

export const updateGeoFenceByDocId = (docid: string, geofence: any, history: any) => ({
  type: GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID,
  payload: { docid, geofence, history },
});

export const updateGeoFenceByDocIdSuccess = (geofence: any) => ({
  type: GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID_SUCCESS,
  payload: geofence,
});

export const updateGeoFenceByDocIdFail = (error: any) => ({
  type: GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID_FAIL,
  payload: error,
});
