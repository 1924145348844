export enum CompanyTypes {
  GET_COMPANIES = "GET_COMPANIES",
  GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL',

  GET_COMPANY_BY_CODE = 'GET_COMPANY_BY_CODE',
  GET_COMPANY_BYCODE_SUCCESS = 'GET_COMPANY_BYCODE_SUCCESS',
  GET_COMPANY_BYCODE_FAIL = 'GET_COMPANY_BYCODE_FAIL',

  ADD_COMPANY = 'ADD_COMPANY',
  ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS',
  ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL',

  DELETE_COMPANY = 'DELETE_COMPANY',
  DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL',
  CONFIRM_DELETE = 'CONFIRM_DELETE',

  UPDATE_COMPANY = 'UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL'
}
