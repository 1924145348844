import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Label,
  Input,
  Card,
  CardTitle,
  Spinner,
  FormGroup,
  CardBody,
  Container,
} from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";
import { useSelector, useDispatch } from "react-redux";
import { getGeoFenceByDocId, updateGeoFenceByDocId } from "../../store/actions";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { storage } from "../../helpers/firebase_config";
import { Formik, Form, Field } from "formik";
import SiteSelect from "src/components/Common/SiteSelect";
import * as Yup from "yup";

const GeoFenceSchema = Yup.object().shape({
  title: Yup.string().required("The title is required."),
  radius: Yup.string().required("The radius is required."),
  siteCode: Yup.string().required("The site code is required."),
  latitude: Yup.number().required("The latitude is required."),
  longitude: Yup.number().required("The longitude is required."),
  description: Yup.string().required("The decription is required."),
});

const EditGeoFence = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: any) => state.login);
  const history = useHistory();
  const [siteList, setSitesList] = useState<any>([]);
  const [siteName, setSiteName] = useState<string>("");
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const { docid } = useParams<any>();
  const { singleGeoFence, loading } = useSelector(
    (state: any) => state.geofence
  );
  const { sites, authSite, success } = useSelector((state: any) => state.site);
  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      siteCodeLists(Array(authSite));
    } else {
      siteCodeLists(sites);
    }
  }, [sites, authSite, singleGeoFence]);

  const siteCodeLists = (lists: any) => {
    let arr: { value: string; label: string }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        arr.push({value: item.code, label: item.name });
      });
    }
    if (singleGeoFence.siteCode) {
      const result = lists.filter(
        (item: any) => item.code == singleGeoFence.siteCode
      );
      if (result.length > 0) {
        setSiteName(result[0].name);
      }
    }
    setSitesList(arr);
  };
  useEffect(() => {
    dispatch(getGeoFenceByDocId(docid));
  }, []);

  const handleChange = (selectedOption: any, setFieldValue: any) => {
    setFieldValue("siteCode", selectedOption.value);
    setSiteName(selectedOption.label);
  };

  const handleAcceptedImageFiles = async (setFieldValue: any, files: any) => {
    setImageUploading(true);
    const path = `/geo-fence/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setImageUploading(false);
    setFieldValue("image", url);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit GeoFence</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/geo-fence" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: GeoFence Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: GeoFenceID: {singleGeoFence?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Formik
            initialValues={{
              title: singleGeoFence.title || "",
              radius: singleGeoFence.radius || "",
              siteCode: singleGeoFence.siteCode || "",
              latitude: singleGeoFence.latitude || "",
              longitude: singleGeoFence.longitude || "",
              description: singleGeoFence.description || "",
              image: singleGeoFence.image || "",
            }}
            validationSchema={GeoFenceSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              dispatch(updateGeoFenceByDocId(docid, values, history));
              // props.onDrawerClose(false);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="siteCode">Site name</Label>
                        <Field
                          name="siteCode"
                          render={({ field }: any) => (
                            <SiteSelect
                              siteCode={singleGeoFence?.siteCode || ""}
                              siteName={siteName}
                              productList={siteList}
                              handleChange={selectedOption =>
                                handleChange(selectedOption, setFieldValue)
                              }
                            />
                          )}
                        />
                        {errors.siteCode && touched.siteCode ? (
                          <div className="error-message">{errors.siteCode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="title">Title</Label>
                        <Field
                          name="title"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.title && touched.title ? (
                          <div className="error-message">{errors.title}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="radius">Radius (km)</Label>
                        <Field
                          name="radius"
                          render={({ field }: any) => (
                            <Input {...field} type="number" />
                          )}
                        />
                        {errors.radius && touched.radius ? (
                          <div className="error-message">{errors.radius}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="latitude">Latitude</Label>
                        <Field
                          name="latitude"
                          render={({ field }: any) => (
                            <Input {...field} type="number" />
                          )}
                        />
                        {errors.latitude && touched.latitude ? (
                          <div className="error-message">{errors.latitude}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="longitude">Longitude</Label>
                        <Field
                          name="longitude"
                          render={({ field }: any) => (
                            <Input {...field} type="number" />
                          )}
                        />
                        {errors.longitude && touched.longitude ? (
                          <div className="error-message">
                            {errors.longitude}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="description">Description</Label>
                        <Field
                          name="description"
                          render={({ field }: any) => (
                            <textarea
                              {...field}
                              className="form-control"
                              rows={2}
                            />
                          )}
                        />
                        {errors.description && touched.description ? (
                          <div className="error-message">
                            {errors.description}
                          </div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <CardTitle>Image</CardTitle>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedImageFiles(setFieldValue, acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drop files here or click to upload.</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews" id="file-previews">
                      {imageUploading && (
                        <div className="d-flex justify-content-center">
                          <i
                            className="bx bx-loader bx-spin align-middle me-2"
                            style={{ fontSize: 46 }}
                          ></i>
                        </div>
                      )}
                      <div className="py-3">
                        <FormGroup>
                          <Label for="image">Or please enter image URL</Label>
                          <Field
                            name="image"
                            render={({ field }: any) => (
                              <Input {...field} type="text" />
                            )}
                          />
                          {errors.image && touched.image ? (
                            <div className="error-message">{errors.image}</div>
                          ) : null}
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="pb-3">
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={loading}
                      >
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>{t("customers.Update")}</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditGeoFence);
