import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { SiteTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  addSiteSuccess,
  addSiteFail,
  fetchSitesByCompanyCodeSuccess,
  fetchSitesByCompanyCodeFail,
  getSiteByDocIdSuccess,
  getSiteByDocIdFail,
  updateSiteByDocIdSuccess,
  updateSiteByDocIdFail,
  getSiteBySiteCodeSuccess,
  getSiteBySiteCodeFail,
  deleteSiteBySiteCodeSuccess
} from "./actions";

import {
  fetchSitesByCompanyCodeAsync,
  addSiteAsync,
  getSiteByDocIdAsync,
  updateSiteByDocIdAsync,
  getSiteBySiteCodeAsync,
  deleteSiteBySiteCodeAsync
} from "../../helpers/backend_helper";

function* fetchSitesByCompanyCode({ payload: companyCode }: any) {
  try {
    const response: IResponse = yield call(fetchSitesByCompanyCodeAsync, companyCode);
    if (response.success) {
      yield put(fetchSitesByCompanyCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(fetchSitesByCompanyCodeFail(error));
  }
}

function* addSite({ payload: site }: any) {
  try {
    const response: IResponse = yield call(addSiteAsync, site);
    if (response.success) {
      yield put(addSiteSuccess(response.result));
    }
  } catch (error) {
    yield put(addSiteFail(error));
  }
}

function* getSiteByDocId({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(getSiteByDocIdAsync, docid);
    if (response.success) {
      yield put(getSiteByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getSiteByDocIdFail(error));
  }
}

const make7 = (code: string) => {
  while (code.length < 7) code = '0' + code;
  return code;
}

function* getSiteBySiteCode({ payload: siteCode }: any) {
  try {
    const response: IResponse = yield call(getSiteBySiteCodeAsync, make7(siteCode));
    if (response.success) {
      yield put(getSiteBySiteCodeSuccess([response.result]));
    } else {
      yield put(getSiteBySiteCodeFail(response));
    }
  } catch (error) {
    yield put(getSiteBySiteCodeFail(error));
  }
}

function* deleteSiteBySiteCode({ payload: siteCode }: any) {
  try {
    const response: IResponse = yield call(deleteSiteBySiteCodeAsync, siteCode);
    yield put(deleteSiteBySiteCodeSuccess(response.result));
  } catch (error) {
    console.log(error)
  }
}

function* updateSiteByDocId({ payload: { docid, site, history } }: any) {
  try {
    const response: IResponse = yield call(updateSiteByDocIdAsync, docid, site);

    if (response.success) {
      yield put(updateSiteByDocIdSuccess(response.result));
      history.push(`/sites`)
    }
  } catch (error) {
    yield put(updateSiteByDocIdFail(error));
  }
}



function* siteSaga() {
  yield takeEvery(SiteTypes.GET_SITES_BY_COMPANY_CODE, fetchSitesByCompanyCode)
  yield takeEvery(SiteTypes.ADD_SITE, addSite)
  yield takeEvery(SiteTypes.GET_SITE_BY_DOCID, getSiteByDocId)
  yield takeEvery(SiteTypes.GET_SITE_BY_SITECODE, getSiteBySiteCode)
  yield takeEvery(SiteTypes.DELETE_SITE_BYSITECODE, deleteSiteBySiteCode),
    yield takeEvery(SiteTypes.UPDATE_SITE_BY_DOCID, updateSiteByDocId);
}

export default siteSaga;


