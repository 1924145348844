import { useEffect, useState } from "react";
import { withRouter, useHistory} from "react-router-dom";
import {
  getAllSitesByCompanyCode,
  deleteSiteBySiteCode,
  getSiteBySiteCode,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import AddSite from "./add-site";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";

const Site = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { sites, loading, authSite, success } = useSelector(
    (state: any) => state.site
  );  
  const { currentUser } = useSelector((state: any) => state.login);
  const [ showConfirmAlert, setShowConfirmAlert ] = useState(false);
  const [ showSuccessAlert, setShowSuccessAlert ] = useState(false);
  const [siteList, setSitesList] = useState<any>([]);
  const [currentSite, setCurrentSite] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const hasPermission = currentUser?.permissions?.sites;

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];  

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      setSitesList(Array(authSite));
    } else {
      setSitesList(sites);
    }
  }, [sites, authSite]);

  const onEdit = (site: any) => {
    history.push(`/sites/${site.id}`);
  };

  const onDelete = (site: any) => {
    setCurrentSite(site);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteSiteBySiteCode(currentSite.code));
    if (success){
      setShowSuccessAlert(true)
    }
  };

  const onAdd = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };
  
  return (
    <TablePage
      loading={loading}
      metaTitle={t("Admins")}
      data={siteList}
      onAdd={onAdd}
      hasPermission={hasPermission}
      title={t("sites.title")}
      description={`(${sites.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={sites.length}
      defaultSorted={defaultSorted}
      footer = {
        <AddSite open={drawer} onDrawerClose={onDrawerClose} />
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />    
  );
};

export default withRouter(Site);

const header = [
  {
    dataField: "logo",
    text: "Logo",
    formatter: (_: any, site: any) => (
      <img className="rounded-circle avatar-sm" src={site?.logo} alt="" />
    ),
  },
  {
    dataField: "code",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "address",
    text: "Address",
    sort: true,
  },    
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
