export enum VehicleTypes {
  GET_ALL_VEHICLES = "GET_ALL_VEHICLES",
  GET_ALL_VEHICLES_SUCCESS = 'GET_ALL_VEHICLES_SUCCESS',
  GET_ALL_VEHICLES_FAIL = 'GET_ALL_VEHICLES_FAIL',

  ADD_VEHICLE = 'ADD_VEHICLE',
  ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS',
  ADD_VEHICLE_FAIL = 'ADD_VEHICLE_FAIL',

  GET_VEHICLE_BY_DOCID = 'GET_VEHICLE_BY_DOCID',
  GET_VEHICLE_BY_DOCID_SUCCESS = 'GET_VEHICLE_BY_DOCID_SUCCESS',
  GET_VEHICLE_BY_DOCID_FAIL = 'GET_VEHICLE_BY_DOCID_FAIL',

  DELETE_VEHICLE_BYID = 'DELETE_VEHICLE_BYID',
  DELETE_VHEICLE_BYID_SUCCESS = 'DLETLE_VHEICLE_BYID_SUCCESS'
}
