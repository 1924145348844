import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux";
import { getCustomerByDocId, updateCustomerByDocId } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";;
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import AvatarEdit from "src/components/Common/AvatarEdit";
import SiteSelect from "src/components/Common/SiteSelect";

const EditCustomer = (props: any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [avatar, setAvatar] = useState("");
  const { docid } = useParams<any>();
  const { singleCustomer, loading } = useSelector(
    (state: any) => state.customer
  );
  const { sites, authSite } = useSelector((state: any) => state.site);
  const { currentUser } = useSelector((state: any) => state.login);
  const [siteList, setSitesList] = useState<any>([]);
  const [siteName, setSiteName] = useState("");
  const [siteCode, setSiteCode] = useState("");
  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      siteCodeLists(Array(authSite));
    } else {
      siteCodeLists(sites);
    }
  }, [sites, authSite, singleCustomer]);

  const siteCodeLists = (lists: any) => {
    let arr: { value: any; label: any }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        if (singleCustomer?.siteCode) {
          if (singleCustomer.siteCode == item.code) {
            setSiteName(item.name);
            setSiteCode(item.code);
          }
        }
        arr.push({ value: item.code, label: item.name });
      });
    }
    setSitesList(arr);
  };

  useEffect(() => {
    dispatch(getCustomerByDocId(docid));
  }, []);

  const handleEditCustomer = (values: any) => {
    values.avatar = avatar;
    values.siteCode = siteCode;
    dispatch(updateCustomerByDocId(docid, values, history));
  };

  const onChange = (avatar:string)=> (
    setAvatar(avatar)
  ); 

  const handleChange = (selectedOption: any) => {
      setSiteName(selectedOption.label);
      setSiteCode(selectedOption.value);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Customer</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/customers" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Customer Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: CustomerId: {singleCustomer?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>   
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditCustomer(values);
            }}
          >
            <AvatarEdit 
              singleSelect={singleCustomer?.avatar }            
              onChange={onChange}
              title={"Customer"}
            />
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label for="siteCode">Site</Label>
                  <SiteSelect
                    siteCode={siteCode}
                    siteName={siteName}
                    productList={siteList}
                    handleChange={selectedOption =>
                      handleChange(selectedOption)
                    }
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="userName"
                    label={t("customers.userName")}
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.userName || ''}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="firstName"
                    label={t("customers.firstName")}
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.firstName || ''}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="lastName"
                    label={t("customers.lastName")}
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.lastName || ''}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label={t("customers.phone")}
                    type="text"
                    errorMessage="Invalid Phone number"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.phone || ''}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label={t("customers.email")}
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.email || ''}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="title"
                    label={t("customers.Title")}
                    type="text"
                    errorMessage="Invalid title"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.title || ''}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="postCode"
                    label={t("customers.postCode")}
                    type="text"
                    errorMessage="Invalid postCode"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.postcode || ''}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="houseName"
                    label={t("customers.houseName")}
                    type="text"
                    errorMessage="Invalid House Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.houseName || ''}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="address"
                    label={t("customers.address")}
                    type="text"
                    errorMessage="Invalid Address"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.address || ''}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="driverLicenseNo"
                    label={t("customers.driverLicenseNo")}
                    type="text"
                    errorMessage="Invalid Driver License No"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.driverLicenseNo || ''}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="dealership"
                    label={t("customers.dealership")}
                    type="text"
                    errorMessage="Invalid dealership"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCustomer?.dealership || ''}
                  />
                </div>
              </Col>
            </Row>
              
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={loading}
                  >
                    {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>{t("customers.Update")}</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>    
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditCustomer);
