import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";
import { ThemeColors } from "src/constants/colors";
import Icon from "@ailibs/feather-react-ts";
import useOnboardings from "src/hooks/useOnboardings";
import OnboardingCard from "./OnboardingCard";
import { IOnboard } from "src/interfaces/general";


const Onboarding = () => {

  const history = useHistory();
  const { cards, onRemove, onChangeVisible } = useOnboardings();    
  
  const onAddNew = () => {
    history.push(`/settings/onboarding/add`)
  };

  const onEdit = (card: IOnboard) => {
    const index = cards.findIndex(item => item.title == card.title)
    history.push(`/settings/onboarding/edit/${index}`)
  };
  
  return (
    <React.Fragment>
      <MetaTags>
        <title>Onboarding</title>
      </MetaTags>
      <div className="page-content" style={{ height: '100vh', overflow:'scroll'}}>
        <Container fluid style={{display:"flex", flexWrap:"wrap"}}>
          {cards?.map((card:any, index) => (
            <OnboardingCard
              key={index}
              onChange={onChangeVisible}
              onEdit={onEdit}
              onRemove={onRemove}
              onboarding={card}
            />            
          ))}
          <CardContainer style={{justifyContent:"center"}}>              
            <Icon  onClick={onAddNew} name="plus-circle" size={30} color={ThemeColors.main}/>              
            <Title>Add New</Title> 
          </CardContainer>              
        </Container>
      </div>
    </React.Fragment>
  )
};

export default withRouter(Onboarding);

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 400px;
  border-radius: 20px;
  border-color: ${ThemeColors.main};
  border: solid;
  border-width: 1px;
  position: relative;
  background: ${ThemeColors.white};
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  margin-left: 10px;
  margin-top: 10px;
`

const Title = styled.div`
  color: ${ThemeColors.main};
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
`
