import React, { useEffect, useState } from "react";
import { withRouter, Link, useHistory, Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getCompanies,
  getCompanyByCode,
  deleteCompnay,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import AddCompany from "./add-company";
import { useTranslation } from "react-i18next";
import BaseTableCard from "src/components/Common/BaseTableCard";
import styled from "styled-components";
import { ThemeColors } from "src/constants/colors";

const Company = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { companies, loading, success, singleCompany } = useSelector(
    (state: any) => state.company
  );
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [companyList, setCompanyList] = useState<any>([]);
  const [currentCompany, setCurrentCompany] = useState<any>([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);

  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 10,
    totalSize: companies.length, // replace later with size(companies),
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const companyListColumns = [
    {
      dataField: "logo",
      text: "Logo",
      formatter: (cellContent: any, company: any) => (
        <>
          <div>
            <img
              className="rounded-circle avatar-sm"
              src={company?.logo}
              alt=""
            />
          </div>
        </>
      ),
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "postcode",
      text: "Postcode",
      sort: true,
    },
    {
      dataField: "managerName",
      text: "managerName",
      sort: true,
    },
    {
      dataField: "location",
      text: "location",
      formatter: (location: any) => {
        return (
          <>
            <div className="">
              {" "}
              {location.lat}, {location.lon}
            </div>
          </>
        );
      },
    },
    {
      dataField: "menu",
      editable: false,
      text: "Action",
      formatter: (_: any, company: any) => (
        <div className="d-flex gap-3">
          <div className="flex-d row">
            <ActionButton danger={false}>
              <i
                className="mdi mdi-pencil"
                id="edittooltip"
                onClick={() => handleEditCompany(company)}
              ></i>
            </ActionButton>
            <ActionButton danger>
              <i
                className="mdi mdi-delete"
                id="deletetooltip"
                onClick={() => handleDeleteCompany(company)}
              ></i>
            </ActionButton>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (currentUser?.permissions?.companies) {
      dispatch(getCompanies());
    } else {
      if (currentUser?.LOGIN_METHOD === "companyLogiin") {
        dispatch(
          getCompanyByCode(currentUser?.code || currentUser?.companyCode)
        );
      }
      if (
        currentUser?.LOGIN_METHOD === "staffLogiin" &&
        currentUser?.role === "companyAdmin"
      ) {
        dispatch(getCompanyByCode(currentUser?.companyCode));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.permissions?.companies) {
      setCompanyList(companies);
    } else {
      setCompanyList(Array(singleCompany));
    }
  }, [companies, singleCompany]);

  const handleEditCompany = (company: any) => {
    history.push(`/companies/${company.id}`);
  };

  const handleDeleteCompany = (company: any) => {
    setCurrentCompany(company);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteCompnay(currentCompany.code));
    if (success) {
      setShowSuccessAlert(true);
    }
  };

  const handleAddNewCompany = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  if (currentUser !== null && currentUser.code !== "00000") {
    return (<Redirect
      to={{ pathname: "/login" }}
    />);
  } else {
    return (
      <BaseTableCard
        loading={loading}
        metaTitle={"Company"}
        footer={
          <>
            {showConfirmAlert && (
              <SweetAlert
                title={t("alert.confirm")}
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={confirmDelete}
                onCancel={() => setShowConfirmAlert(false)}
              >
                {t("confirmAlert")}
              </SweetAlert>
            )}

            {showSuccessAlert && (
              <SweetAlert
                success
                title={t("alert.deleted")}
                onConfirm={() => setShowSuccessAlert(false)}
              />
            )}

            <AddCompany
              open={drawer}
              onDrawerClose={onDrawerClose}
              loading={loading}
            />
          </>
        }
      >
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              data={companyList}
              columns={companyListColumns}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <div className="row align-ite  ms-center">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <h5 className="card-title">
                            {t("company.title")}
                            <span className="text-muted fw-normal ms-2">
                              ({companies.length})
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                          <div>
                            <Link
                              to="#"
                              className="btn btn-light"
                              onClick={handleAddNewCompany}
                            >
                              <i className="bx bx-plus me-1"></i>{" "}
                              {t("company.Add New")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder={t("search")}
                            {...toolkitProps.searchProps}
                          />
                          <i className="bx bx-search-alt search-icon-search" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          defaultSorted={defaultSorted}
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          bordered={false}
                          striped={false}
                          // responsive
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2">
                      <PaginationListStandalone {...paginationProps} />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </BaseTableCard>
    );
  }
};

export default withRouter(Company);

const ActionButton = styled.div<{ danger: boolean }>`
  width: 24px;
  height: 24px;
  color: white;
  background-color: ${({ danger }) =>
    danger ? ThemeColors.red : ThemeColors.main};
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
`;
