import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { AdminTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  getAdminsBySiteCodeSuccess,
  getAdminsBySiteCodeFail,
  addAdminSuccess,
  addAdminFail,
  getAdminByDocIdSuccess,
  getAdminByDocIdFail,
  updateAdminByDocIdSuccess,
  updateAdminByDocIdFail,
  getAllAdminsByCompanyCodeSuccess,
  deleteAdminByDocIdSuccess,
  deleteAdminByDocIdFail
} from "./actions";

import {
  getAdminsBySiteCodeAsync,
  getAdminsByCompanyCodeAsync,
  addAdminAsync,
  getAdminByDocIdAsync,
  updateAdminByDocIdAsync,
  deleteAdminByDocIdAsyc
} from "../../helpers/backend_helper";

function* getAdminsBySiteCode({ payload: siteCode}: any) {
  try {
    const response: IResponse = yield call(getAdminsBySiteCodeAsync, siteCode);
    if(response.success) {
      yield put(getAdminsBySiteCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getAdminsBySiteCodeFail(error));
  }
}

function* getAdminsByCompanyCode({ payload: companyCode}: any) {
  try {
    const response: IResponse = yield call(getAdminsByCompanyCodeAsync, companyCode);
    if(response.success) {
      yield put(getAllAdminsByCompanyCodeSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}


function* addAdmin({ payload: admin }: any) {
  try {
    const response: IResponse = yield call(addAdminAsync, admin);
    if(response.success) {
      yield put(addAdminSuccess(response.result));
    }
  } catch (error) {
    yield put(addAdminFail(error));
  }
}

function* getAdminByDocId({ payload: docid}: any) {
  try {
    const response: IResponse = yield call(getAdminByDocIdAsync, docid);
    if(response.success) {
      yield put(getAdminByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getAdminByDocIdFail(error));
  }
}


function* updateAdminByDocId({ payload: {docid, admin, history} }: any) {
  try {
    const response: IResponse = yield call(updateAdminByDocIdAsync, docid, admin);
    if(response.success) {
      yield put(updateAdminByDocIdSuccess(response.result));
      history.push('/admins')
    }
  } catch (error) {
    yield put(updateAdminByDocIdFail(error));
  }
}


function* deleteAdminByDocId({ payload: docId }: any) {
  try {
    const response: IResponse = yield call(deleteAdminByDocIdAsyc, docId);
    yield put(deleteAdminByDocIdSuccess(response.result));
  } catch (error) {
    console.log(error)
    yield put(deleteAdminByDocIdFail(error));
  }
}



function* adminSaga() {
  yield takeEvery(AdminTypes.GET_ADMINS_BY_SITECODE, getAdminsBySiteCode)
  yield takeEvery(AdminTypes.GET_ADMINS_BY_COMPANYCODE, getAdminsByCompanyCode)
  yield takeEvery(AdminTypes.ADD_ADMIN, addAdmin)
  yield takeEvery(AdminTypes.GET_ADMIN_BY_DOCID, getAdminByDocId)
  yield takeEvery(AdminTypes.DELETE_ADMIN_BY_DOCID, deleteAdminByDocId),
  yield takeEvery(AdminTypes.UPDATE_ADMIN_BY_DOCID, updateAdminByDocId);
}

export default adminSaga;


