import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { getAllProductsAsync, getVehicleByRegNoAsync } from "src/helpers/backend_helper";


export interface IProduct {
    id: string;
    wasInfo: {
        ProductName: string;
        DealerProductId: number;
        PricingStructureId: number;
        DealerCost: number;
        MonthlyDuration: number;
        AgeFrom: number;
        AgeTo: number;
        MileageFrom: number;
        MileageTo: number;
        ClaimLimit: number;
        AggregatedClaimLimit: number;
        Monthly: number;
    };
    hendyInfo: {
        siteCode: string;
        video: string;
        description: string;
        image: string;
        terms: string;
    };
    wasDealerId: string;
    createdAt: number;
}

const Tier1 = [
    "MG",
    "SUZUKI",
    "TOYOTA",
    "HONDA",
    "VOLVO",
    "DACIA",
    "FIAT",
    "MINI",
    "KIA",
    "HYUNDAI",
    "RENAULT",
    "SMART",
    "MAZDA",
    "SKODA",
    "SEAT",
    "DS",
    "VOLKSWAGEN",
    "CITROEN",
    "VAUXHALL",
    "NISSAN",
    "PEUGEOT",
    "FORD"
]

const Tier2 = [
    "ABARTH",
    "ALFA ROMEO",
    "CHEVROLET",
    "CHRYSLER",
    "ISUZU",
    "LEXUS",
    "SSANGYONG",
    "SUBARU",
    "MITSUBISHI",
    "MERCEDES-BENZ",
    "BMW",
    "INFINITI",
    "AUDI",
    "MASERATI"
]

const Tier3 = [
    "JAGUAR",
    "Land Rover",
    "4x4 over 2.0Litre"
]

const useProducts = (regNo?: string, mileAge?: number, drive?: boolean) => {

    const { currentUser } = useSelector((state: any) => state.login);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [filterProducts, setFilterProducts] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const filterFunction = (resultSmartVehicle: any, newProducts: IProduct[]) => {
        if (drive === true) {
            setFilterProducts(newProducts.filter((item: IProduct) => (
                item.wasInfo.ProductName.includes("Tier 3")
            )));
        } else {
            const make = resultSmartVehicle?.VehicleDetails?.Make;
            if (Tier1.includes(make)) {
                setFilterProducts(newProducts.filter((item: IProduct) => (
                    item.wasInfo.ProductName.includes("Tier 1")
                )));
            } else if (Tier2.includes(make)) {
                setFilterProducts(newProducts.filter((item: IProduct) => (
                    item.wasInfo.ProductName.includes("Tier 2")
                )));
            } else if (Tier3.includes(make)) {
                setFilterProducts(newProducts.filter((item: IProduct) => (
                    item.wasInfo.ProductName.includes("Tier 3")
                )));
            }
        }
    }

    const fetchProducts = async () => {
        if (currentUser) {
            setLoading(true);
            try {
                const res = await getAllProductsAsync();
                if (res.success) {
                    setProducts(res.result
                        // .filter((item: IProduct) => (
                        //     item.hendyInfo.siteCode == currentUser.siteCode
                        //     || currentUser.siteCode == undefined
                        // ))
                    );
                };
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
    }

    const fetchFilterProducts = async () => {
        if (regNo && mileAge) {
            const res = await getVehicleByRegNoAsync(regNo);
            if (res.Success) {
                if (mileAge > 60000) {
                    const newProducts = products.filter((item: IProduct) => (
                        item.wasInfo.ProductName.includes("Platinum")))
                    filterFunction(res, newProducts);
                } else {
                    const newProducts = products.filter((item: IProduct) => (
                        item.wasInfo.ProductName.includes("Diamond")))
                    filterFunction(res, newProducts);
                };
            }
        }
    }

    useEffect(() => {
        fetchProducts();
    }, [currentUser])

    useEffect(() => {
        fetchFilterProducts();
    }, [regNo, mileAge, drive])

    return {
        products,
        filterProducts,
        loading
    }
}

export default useProducts;