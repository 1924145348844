import { useEffect, useRef, useState } from "react";
import {Dropdown,DropdownToggle,DropdownMenu,Row,Col} from "reactstrap";
import { useSelector } from "react-redux";
import { ISite } from "src/interfaces/general";
import useSites from "src/hooks/useSites";
import { useTranslation } from "react-i18next";

interface IProps {
  onChangeSiteCode:(siteCode: string) => void;
}

const SiteSelector = (props: IProps) => {
  
  const {t} = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ logo, setLogo ] = useState("");
  const [ title, setTitle ] = useState("");  
  const { currentUser } = useSelector((state: any) => state.login);
  const { sites, loading } = useSites(currentUser?.code||currentUser?.companyCode);
  const isChanged = useRef<boolean>(false);

  const onSelectSite = (site: ISite) =>{
    setTitle(site.name);
    setLogo(site.logo);
    props.onChangeSiteCode(site.code);
    setIsOpen(false);
    isChanged.current = true;
  }

  useEffect(()=>{
    if (!isChanged.current){
      setTitle(t("directory.selectSite"))
    }
  },[t])
      
  return (
    <Row>
      <Col md={4}>        
        <Dropdown  toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}>

          <DropdownToggle type="button" className="btn btn-light" tag="label" >
            <div className="d-flex justify-content-between align-items-center">
            <div className="form-check-site d-flex align-items-center " >                
                {logo && (
                  <img className="rounded avatar-md" src={logo}  />
                )}                
                <label className="form-check-label" htmlFor="formCheck2" style={{margin:0}} >
                    {title}
                </label>                
            </div>
                <i className="mdi mdi-chevron-down justify-content-end" ></i>
            </div>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-md p-4" style={{width: '100%'}}>
            <div className="site-list-for-service">

              {loading && (
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 24}}></i>  
              )}
              
              {sites.map((item: ISite, index: number) => {
                
                return (
                  <div key={index} >
                    <label className="form-check-label" onClick={()=>onSelectSite(item)}>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <img title={item?.code} className="rounded avatar-md" src={item?.logo} alt="site-logo" />
                        </div>
                        <h5>{item?.name} </h5>
                      </div>
                    </label>
                  </div>
                )
              })}

            </div>
          </DropdownMenu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default SiteSelector;