export enum AdminTypes {
  GET_ADMINS_BY_SITECODE = "GET_ADMINS_BY_SITECODE",
  GET_ADMINS_BY_SITECODE_SUCCESS = 'GET_ADMINS_BY_SITECODE_SUCCESS',
  GET_ADMINS_BY_SITECODE_FAIL = 'GET_ADMINS_BY_SITECODE_FAIL',

  ADD_ADMIN = 'ADD_ADMIN',
  ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL',

  GET_ADMIN_BY_DOCID = 'GET_ADMIN_BY_DOCID',
  GET_ADMIN_BY_DOCID_SUCCESS = 'GET_ADMIN_BY_DOCID_SUCCESS',
  GET_ADMIN_BY_DOCID_FAIL = 'GET_ADMIN_BY_DOCID_FAIL',

  UPDATE_ADMIN_BY_DOCID = 'UPDATE_ADMIN_BY_DOCID',
  UPDATE_ADMIN_BY_DOCID_SUCCESS = 'UPDATE_ADMIN_BY_DOCID_SUCCESS',
  UPDATE_ADMIN_BY_DOCID_FAIL = 'UPDATE_ADMIN_BY_DOCID_FAIL',

  GET_ADMINS_BY_COMPANYCODE = 'GET_ADMINS_BY_COMPANYCODE',
  GET_ADMINS_BY_COMPANYCODE_SUCCESS = 'GET_ADMINS_BY_COMPANYCODE_SUCCESS',

  DELETE_ADMIN_BY_DOCID = 'DELETE_ADMIN_BY_DOCID',
  DELETE_ADMIN_BY_DOCID_SUCCESS = 'DELETE_ADMIN_BY_DOCID_SUCCESS',
  DELETE_ADMIN_BY_DOCID_FAIL = 'DELETE_ADMIN_BY_DOCID_FAIL'
}
