import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config: any = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: process.env.REACT_APP_AZURE_APPLICATION_ID,
    redirectUri: process.env.REACT_APP_AZURE_CALLBACK_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)