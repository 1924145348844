import { IButton, ICheckBox } from "../types";


export const CheckBox = ({
    value,
    label,
    onChange
}: ICheckBox) => (
    <div className="form-check">
        <input
            className="form-check-input"
            type="checkbox"
            id="remember-check"
            onChange={onChange}
            // @ts-ignore
            checked={value}   // ???????????
        />
        <label
            className="form-check-label"
        >
            {label}
        </label>
    </div>
);

export const Button = ({
    title,
    theme,
    onPress,
    style
}: IButton) => (
    <button
        className={`btn btn-${theme}`}
        onClick={onPress}
        style={style}
    >
        {title}
    </button>
)