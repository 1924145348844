import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col } from "reactstrap";


interface IProps {
    value?: string;
    onChangeColor:(color:string) => void;
    isModal?:boolean;
}

const ColorInput = (props:IProps) => {

    const {t} = useTranslation();

    const [color, setColor] = useState(props.value||"#ff0000");
    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal(!showModal)
    };

    const onCloseModal = () => {
        setShowModal(false)
    };

    useEffect(()=>{
        if ( props.value){
            setColor(props.value);
        }
    },[props.value])

    const Body = ()=>(
        <>
            <SketchPicker
                onChange={(color:any) => {
                    setColor(color.hex);
                    props.onChangeColor(color.hex);
                }}
                color={color}
            />
            {props.isModal?<div className="modal-footer">
                <button
                    type="button"              
                    onClick={onCloseModal}
                    className="btn btn-secondary "
                    data-dismiss="modal"
                >
                    {t("colorPicker.close")}
                </button>
            </div>:null}
        </>
    )

    return (
        <>
            <div className="d-flex flex-row align-items-end">
                <input
                    name="color"
                    aria-label="Color"
                    type="text"
                    value={color}
                    className="form-control color-text-input"
                    onChange={(e) => setColor(e.target.value)}
                />           
                <div 
                    className="color-picker-button" 
                    onClick={toggleModal}
                    style={{background:color}}
                >
                    <span className={`fa ${showModal?"fa-times":"fa-edit"} text-white`}/>
                </div>
            </div>
            {props.isModal ?
                <Modal
                    isOpen={showModal}
                >
                    <div className="d-flex flex-column align-items-center p-3 ">
                        <Body/>
                    </div>
                </Modal>:(
                    showModal &&
                    <div className="d-flex flex-column align-items-center p-3 ">
                        <Body/>
                    </div>
                )
            }
        </>
    )
};

export default ColorInput;
