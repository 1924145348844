import { ThemeColors } from "src/constants/colors";
import styled from "styled-components";


export const Tag = styled.div<{type:string}>`
  height: 24px;
  border-radius: 12px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:${({type}) => type === "role" ? null : ThemeColors.green};
  border: solid 1px ${({type}) => type === "role" ? ThemeColors.main : null};
  margin: 2px;
  float: left;
  color: ${({type}) => type === "role" ? ThemeColors.main : ThemeColors.white};
  font-size: 10px;
  font-family: futura;
`
export const CarImage = styled.img`
    width: 50px;
    height: 40px;
    border-radius: 5px;
    background-color: grey;
`

export const Dot = styled.div<{isExpired:boolean}>`
    background-color: ${({isExpired})=>isExpired?"rgb(200, 30, 10)":"green"};
    width: 12px;
    height: 12px;
    border-radius: 6px;
`

export const Desc = styled.div`
    font-size: 12px;
    color: ${ThemeColors.main};
    text-align: center;
`

export const Cell = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`