import { CompanyTypes } from "./actionTypes";

export const getCompanies = () => ({
  type: CompanyTypes.GET_COMPANIES,
});

export const getCompaniesSuccess = (companies: any) => ({
  type: CompanyTypes.GET_COMPANIES_SUCCESS,
  payload: companies,
});

export const getCompaniesFail = (error: any) => ({
  type: CompanyTypes.GET_COMPANIES_FAIL,
  payload: error,
});


export const addCompany = (company: any) => ({
  type: CompanyTypes.ADD_COMPANY,
  payload: company,
});

export const addCompanySuccess = (company: any) => ({
  type: CompanyTypes.ADD_COMPANY_SUCCESS,
  payload: company,
});

export const addCompanyFail = (error: any) => ({
  type: CompanyTypes.ADD_COMPANY_FAIL,
  payload: error,
});


export const deleteCompnay = (code: string) => ({
  type: CompanyTypes.DELETE_COMPANY,
  payload: code,
});

export const confirmSuccess = () => ({
  type:CompanyTypes.CONFIRM_DELETE
})

export const deleteCompanySuccess = (code: any) => ({
  type: CompanyTypes.DELETE_COMPANY_SUCCESS,
  payload: code,
});

export const deleteCompanyFail = (error: any) => ({
  type: CompanyTypes.DELETE_COMPANY_FAIL,
  payload: error,
});



export const updateCompany = (id: string, company: any, history: any) => ({
  type: CompanyTypes.UPDATE_COMPANY,
  payload: {id, company, history}
});

export const updateCompanySuccess = (company: any) => ({
  type: CompanyTypes.UPDATE_COMPANY_SUCCESS,
  payload: company,
});

export const updateCompanyFail = (error: any) => ({
  type: CompanyTypes.UPDATE_COMPANY_FAIL,
  payload: error,
});


export const getCompanyByCode = (docid: string) => ({
  type: CompanyTypes.GET_COMPANY_BY_CODE,
  payload: docid,
});


export const getCompanyByCodeSuccess = (company: any) => ({
  type: CompanyTypes.GET_COMPANY_BYCODE_SUCCESS,
  payload: company,
});

export const getCompanyByCodeFail = (error: any) => ({
  type: CompanyTypes.GET_COMPANY_BYCODE_FAIL,
  payload: error,
});




