import { call, put, take, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { GeoFenceTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {
  getGeoFenceSuccess,
  getGeoFenceFail,
  addGeoFenceSuccess,
  addGeoFenceFail,
  getGeoFenceByDocIdSuccess,
  getGeoFenceByDocIdFail,
  updateGeoFenceByDocIdSuccess,
  updateGeoFenceByDocIdFail,
  deleteGeoFenceByDocIdSuccess,
  deleteGeoFenceByDocIdFail
} from "./actions";

import {
  getAllGeoFenceListAsync,
  getGeoFenceByDocIdAsync,
  addGeoFenceAsync,
  deleteGeoFenceByIdAsync,
  updateGeoFenceByDocIdAsync,
} from "../../helpers/backend_helper";

function* getAllGeoFenceList() {
  try {
    const response: IResponse = yield call(getAllGeoFenceListAsync);
    if (response.success) {
      yield put(getGeoFenceSuccess(response.result));
    }
  } catch (error) {
    yield put(getGeoFenceFail(error));
  }
}

function* addGeoFence({ payload: geofence }: any) {
  try {
    const response: IResponse = yield call(addGeoFenceAsync, geofence);
    if (response.success) {
      yield put(addGeoFenceSuccess(response.result));
    }
  } catch (error) {
    yield put(addGeoFenceFail(error));
  }
}

function* getGeoFenceByDocId({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(getGeoFenceByDocIdAsync, docid);
    if (response.success) {
      yield put(getGeoFenceByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getGeoFenceByDocIdFail(error));
  }
}

function* deleteGeoFenceById({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(deleteGeoFenceByIdAsync, docid);
    if (response.success) {
      yield put(deleteGeoFenceByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(deleteGeoFenceByDocIdFail(error));
  }
}


function* updateGeoFenceByDocId({ payload: { docid, geofence, history } }: any) {
  try {
    const response: IResponse = yield call(
      updateGeoFenceByDocIdAsync,
      docid,
      geofence
    );
    if (response.success) {
      yield put(updateGeoFenceByDocIdSuccess(response.result));
      history.push("/geo-fence");
    }
  } catch (error) {
    yield put(updateGeoFenceByDocIdFail(error));
  }
}


function* geofenceSaga() {
  yield takeEvery(GeoFenceTypes.GET_ALL_GEOFENCE_LISTS, getAllGeoFenceList);
  yield takeEvery(GeoFenceTypes.ADD_GEO_FENCE, addGeoFence);
  yield takeEvery(GeoFenceTypes.GET_GEOFENCE_BY_DOCID, getGeoFenceByDocId);
  yield takeEvery(GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID, updateGeoFenceByDocId);
  yield takeEvery(GeoFenceTypes.DELETE_GEOFENCE_BYID, deleteGeoFenceById);
}

export default geofenceSaga;
