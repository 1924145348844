import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { loginMFA, resendMFA } from "../../store/actions";
import { Row, Col, Alert, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { SpinnerCircular } from "spinners-react";
//redux
import { useDispatch, useSelector } from "react-redux";

interface MfaCodeProps {
  history: any,
  onCancel?: () => void
}

const MfaCode = (props: MfaCodeProps) => {
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const { errorMsg, loading, loginMethod, mfaId, message  } = useSelector((state: any) => state.login);

  useEffect(() => {
    setErrMsg(errorMsg);
  }, [errorMsg]);

  const handleMfa = (event: any, values: any) => {
    values = {
        ...values,
        mfaId,
        loginMethod
    }

    dispatch(loginMFA(values, props.history))
  }

  const resendCode = () => {
    dispatch(resendMFA({mfaId, loginMethod}))
  }

  const cancel = () => {
    if (props.onCancel)
        props.onCancel();
  }

  return (
    <div>

                              <AvForm
                              className="custom-form mt-4 pt-2"
                              onValidSubmit={(e: any, v: any) => {
                                handleMfa(e, v);
                              }}
                            >
                              <h5 className="text-center">Enter code to continue</h5>
                                                              <p className="text-muted">
                         {message ? message : <span>A code has been sent via SMS, please enter it below</span>}
                        </p>
                              {errMsg ? (
                                <Alert color="danger"> {errMsg} </Alert>
                              ) : null}
                              <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <label className="form-label">
                                  Code
                                </label>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="">
                                  <a href="#" onClick={resendCode}
                                    className="text-muted"
                                  >
                                    Resend Code
                                  </a>
                                </div>
                              </div>
                            </div>
                              <div className="mb-3">
                                <AvField
                                  name="code"
                                  
                                  placeholder="Enter code"
                                  type="number"
                                  errorMessage="Invalid Code"
                                  validate={{
                                    required: { value: true }
                                  }}
                                />

                              </div>

                              <div className="mb-3">
                                <button
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                  disabled={loading}
                                  style={{ height: 50 }}
                                >
                                  {loading ? (
                                    <SpinnerCircular
                                      size={30}
                                      thickness={150}
                                      speed={150}
                                      color="#36ad47"
                                      secondaryColor="rgba(0, 0, 0, 0.44)"
                                    />
                                  ) : (
                                    <>Continue</>
                                  )}
                                </button>
                              </div>
                              <div className="mb-3">
                                <button
                                  className="btn btn-ling w-100 waves-effect waves-light"
                                  type="button"
                                  onClick={cancel}
                                  style={{ height: 50 }}>
                                  Cancel
                                </button>
                              </div>
                            </AvForm>
                            </div>
  );
};

export default MfaCode;