import { useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//redux
import AddRole from "./add-role";
import { useTranslation } from "react-i18next";
import { IRole } from "src/interfaces/general";
import useRoles from "src/hooks/useRoles";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { Tag } from "src/components/Common/SimpleComponents";

const Roles = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const { roles, loading, deleteRole, success, addRole } = useRoles()  
  const currentRole = useRef<IRole>();
  const hasPermission = true;
  
  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];
  
  const onEdit = (role: any) => {
    history.push(
      `/roles/${role.id}`
    );
  };

  const onDelete = (role: IRole) => {
    currentRole.current = role;
    setShowConfirmAlert(true);
  };

  const confirmDelete = async() => {
    setShowConfirmAlert(false);    
    const result = await deleteRole(currentRole.current?.id||"");
    if (result){
      setShowSuccessAlert(true);
    }
  };

  const onAdd = () => {
    setDrawer(true);
  };
  
  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Roles")}
      data={roles}
      onAdd={onAdd}
      hasPermission={hasPermission}
      title={t("roles.title")}
      description={`(${roles.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={roles.length}
      defaultSorted={defaultSorted}
      footer = {
        <AddRole
          open={drawer}
          onDrawerClose={onDrawerClose}
          addRole={addRole}
          loading={loading}
          success={success}
        />
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Roles);

const header = [
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true,
  },
  {
    dataField: "permissions",
    text: "Permissions",
    formatter: (_: any, role: any) => (
      role.permissions!=null?
      Object.keys(role.permissions)
        .filter(permission=>role.permissions[permission])
        .map((permission, index)=>(
          <Tag key={index} type = "role"><span>{permission.toUpperCase()}</span></Tag>
        )): null
    ),
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
