import { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { ITemplate } from "src/interfaces/general";
import useNotificationTemplates from "src/hooks/useNotiffcationTemplates";
import { useSelector } from "react-redux";

const Templates = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { templates, loading, deleteTemplate, fetchTemplatesByCompanyCode } = useNotificationTemplates()
  const user = useSelector((state: any) => state.login.currentUser);
  const currentTemplate = useRef<ITemplate>();
  
  const onDelete = (template: ITemplate) => {
    currentTemplate.current = template;
    setShowConfirmAlert(true);
  }

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    const result = await deleteTemplate(currentTemplate.current?.id || "");
    if (result) {
      setShowSuccessAlert(true);
    }
  };

  const onEdit = (item: ITemplate) => {
    history.push(`/notifications/templates/add/${item.id}`)
  }

  const onAdd = () => {
    history.push("/notifications/templates/add");
  }

  useEffect(() => {
    fetchTemplatesByCompanyCode();
  }, [user]);

  return (
    <TablePage
      loading={loading}
      metaTitle="Notification Templates"
      data={templates}
      onAdd={onAdd}
      hasPermission
      title="Notification Templates"
      description={`(${templates.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={templates.length}

      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Templates);

const header = [
  {
    dataField: "_",
    text: "No",
    formatter: (_: any, __: any, index: number) => index + 1
  },
  {
    dataField: "subject",
    text: "Subject",
    sort: true,
  },
  {
    dataField: "body",
    text: "Body",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
]