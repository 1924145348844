import { ProductTypes } from "./actionTypes";

export const INIT_STATE: any = {
  products: [],
  singleProduct: {},
  error: {},
  success: false,
  loading: true,
  confirm_del: false,
  DealerProductId: "",
  MonthlyDuration: "",
  siteCode: "",
  siteName: "",
  wasDealerId: ""
};

const product = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ProductTypes.GET_PRODUCTS_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case ProductTypes.GET_PRODUCTS_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };

    case ProductTypes.GET_PRODUCTS_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ProductTypes.ADD_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case ProductTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: [...state.products, action.payload],
      };

    case ProductTypes.ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ProductTypes.DELETE_PRODUCT_BYID:
      return {
        ...state,
        loading: true,
        success: false
      };

    case ProductTypes.GET_PRODUCT_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case ProductTypes.GET_PRODUCT_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleProduct: action.payload,
      };

    case ProductTypes.GET_PRODUCT_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ProductTypes.UPDATE_PRODUCT_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case ProductTypes.UPDATE_PRODUCT_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleProduct: action.payload,
      };

    case ProductTypes.UPDATE_PRODUCT_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ProductTypes.DELETE_PRODUCT_BYID:
      return {
        ...state,
        loading: true,
        success: false
      };

    case ProductTypes.DELETE_PRODUCT_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        customers: state.products.filter((item: any) => item.id !== action.payload),
      };

    case ProductTypes.DELETE_PRODUCT_BYID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      }

    case ProductTypes.SET_CURRENT_WAS_PRODUCT_INFO:
      return {
        ...state,
        DealerProductId: action.payload.DealerProductId,
        MonthlyDuration: action.payload.MonthlyDuration,
        siteCode: action.payload.siteCode,
        siteName: action.payload.siteName,
        wasDealerId:action.payload.wasDealerId
      }
    default:
      return state;
  }
};

export default product;
