import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
const ProductSetting = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Product</title>
      </MetaTags>
      <div className="page-content">
        <p>Product setting</p>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductSetting);
