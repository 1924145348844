import { useEffect, useState } from "react";
import { fetchSitesByCompanyCodeAsync } from "src/helpers/backend_helper";
import { ISite } from "src/interfaces/general";

const useSites = (companyCode?: string) => {

    const [sites, setSites] = useState<Array<ISite>>([]);
    const [loading, setLoading] = useState(false);

    const fetchSites = () => {
        setLoading(true);
        if ( companyCode ){
            fetchSitesByCompanyCodeAsync(companyCode)
            .then(res=>{                
                if (res.success){
                    setSites(res.result||[]);
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    }

    useEffect(()=>{
        fetchSites();
    }, [companyCode])

    return {
        sites,
        loading
    };
}

export default useSites;