
import { Modal } from 'reactstrap';

interface TutorialModalProps {
    isOpen: boolean;
    toggle: () => void;
    url: string;
}

const TutorialModal = (props: TutorialModalProps) => {

    return (
        <Modal
            isOpen={props.isOpen}
            backdrop={true}
            size='lg'
            centered
            toggle={props.toggle}
        >
            <video
                src={props.url}
                autoPlay
                controls
            />
        </Modal>
    );
}

export default TutorialModal;