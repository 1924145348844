import { SiteTypes } from "./actionTypes";

export const INIT_STATE: any = {
  sites: [],
  signleSite: {},
  authSite: {},
  error: {},
  success: false,
  loading: false,
  confirm_del: false,
};

const site = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SiteTypes.GET_SITES_BY_COMPANY_CODE:
      return {
        ...state,
        loading: true,
      };

    case SiteTypes.GET_SITES_BY_COMPANY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        sites: action.payload,
      };

    case SiteTypes.GET_SITES_BY_COMPANY_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SiteTypes.ADD_SITE:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SiteTypes.ADD_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        sites: [...state.sites, action.payload],
      };

    case SiteTypes.ADD_SITE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case SiteTypes.GET_SITE_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case SiteTypes.GET_SITE_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        signleSite: action.payload,
      };

    case SiteTypes.GET_SITE_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SiteTypes.UPDATE_SITE_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case SiteTypes.UPDATE_SITE_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        signleSite: action.payload,
      };

    case SiteTypes.UPDATE_SITE_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SiteTypes.GET_SITE_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case SiteTypes.GET_SITE_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        authSite: action.payload,
      };

    case SiteTypes.GET_SITE_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case SiteTypes.DELETE_SITE_BYSITECODE:
        return {
          ...state,
          loading: true,
          success: false
        };

        
      case SiteTypes.DELETE_SITE_BYSITECODE_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          sites: state.sites.filter(
            (site: any) => site.code !== action.payload
          ),
        };

    default:
      return state;
  }
};

export default site;
