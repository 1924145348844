import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetaTags from "react-meta-tags";
import Icon from "@ailibs/feather-react-ts";
import { ThemeColors } from "src/constants/colors";
import { useTranslation } from "react-i18next";
import styled, { ThemeConsumer } from "styled-components";
import moment from "moment";
import Select from "react-select";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import useSites from "src/hooks/useSites";
import { ISite } from "src/interfaces/general";
import useProducts from "src/hooks/useProducts";
import { IProduct } from "src/hooks/useProducts";
import useRoles from "src/hooks/useRoles";
import { IRole } from "src/interfaces/general";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ProductSelect2 from "src/components/Common/ProductSelect2";
import RoleSelect2 from "src/components/Common/RoleSelect2";
import SaleOfProductsTable from "./saleOfProductsTable";

interface ISiteItem {
  label: string;
  id: string;
}

interface ISiteSelectedOption {
  value: ISite | null;
  label: string;
}

interface IProductSelectedOption {
  value: IProduct;
  label: string;
}

interface IRoleSelectedOption {
  value: IRole;
  label: string;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const pricetext = (price: number) => {
  return `£${price}`;
};

const states = [
  { value: undefined, label: "All States" },
  { value: "Quote", label: "Quote" },
  { value: "Live", label: "Live" },
  { value: "Expired", label: "Expired" },
  { value: "Deleted", label: "Deleted" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Void", label: "Void" },
  { value: "Cancellation Requested", label: "Cancellation Requested" },
  { value: "Financial Change Request", label: "Financial Change Request" },
  { value: "Data Change Request", label: "Data Change Request" },
  { value: "Suspended", label: "Suspended" },
];

const SaleOfProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.login.currentUser);
  const { sites } = useSites(user?.code || user?.companyCode);
  const { products } = useProducts();
  const { roles } = useRoles();

  const [show, setShow] = useState<Boolean>(true);

  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(7, "days").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleDateRange = (item: any) => {
    setDateRange([item.selection]);
  };

  const [currentSum, setCurrentSum] = useState<number>(280);
  const [currentState, setCurrentState] = useState<any>([]);
  const [currentSite, setCurrentSite] = useState<any>([]);
  const [currentProduct, setCurrentProduct] = useState<any>([]);
  const [currentCover, setCurrentCover] = useState<any>([]);
  const [price, setPrice] = useState<number | number[]>([0, 1000]);
  const [currentRole, setCurrentRole] = useState<any>([]);
  const [currentCC, setCurrentCC] = useState<any>([]);
  const [searchPara, setSearchPara] = useState<any>([]);

  useEffect(() => {
    setSearchPara({
      startDate: dateRange[0].startDate.getTime(),
      endDate: dateRange[0].endDate.getTime(),
      state: currentState.value,
      site: currentSite.value?.name,
      product: currentProduct.value?.id,
      cover: currentCover?.value,
      price: price,
      role: currentRole.value?.name,
      cc: currentCC?.value,
    });
  }, []);

  const handleCallback = (sum: number) => {
    setCurrentSum(sum);
  };

  const handleStateChange = (selectedOption: any) => {
    setCurrentState(selectedOption);
  };

  const handleSiteChange = (selectedOption: ISiteSelectedOption) => {
    setCurrentSite(selectedOption);
  };

  const handleProductChange = (selectedOption: IProductSelectedOption) => {
    setCurrentProduct(selectedOption);
  };

  const handleCoverChange = (selectedOption: any) => {
    setCurrentCover(selectedOption);
  };

  const handlePriceChange = (event: Event, newPrice: number | number[]) => {
    setPrice(newPrice);
  };

  const handleCCChange = (selectedOption: any) => {
    setCurrentCC(selectedOption);
  };

  const handleRoleChange = (selectedOption: any) => {
    setCurrentRole(selectedOption);
  };

  const handleSearch = () => {
    setSearchPara({
      startDate: dateRange[0].startDate.getTime(),
      endDate: dateRange[0].endDate.getTime(),
      state: currentState.value,
      site: currentSite.value?.name,
      product: currentProduct.value?.id,
      cover: currentCover?.value,
      price: price,
      role: currentRole.value?.name,
      cc: currentCC?.value,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sale of Products</title>
        </MetaTags>
        <ApplyBox>
          <PageTitleRow>
            <RightBox>
              <PageTitle>Sale of Products</PageTitle>
              <IconButton>
                <Icon
                  name="filter"
                  color={ThemeColors.green}
                  size={30}
                  onClick={() => setShow(!show)}
                />
              </IconButton>
            </RightBox>
            <LeftBox>
              <LeftBoxTitle>Total Revenue</LeftBoxTitle>
              <LeftValueBox>
                <LeftValue>£{(currentSum * 0.05).toFixed(2)}</LeftValue>
              </LeftValueBox>
            </LeftBox>
          </PageTitleRow>
          {!show && (
            <Card>
              <Grid container>
                <Grid item xs>
                  <FormControl>
                    <FormLabel
                      id="date-group"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      Date Created
                    </FormLabel>
                    <Grid item xs>
                      <DateRange
                        color={ThemeColors.main}
                        showDateDisplay={false}
                        editableDateInputs={false}
                        onChange={item => handleDateRange(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <FormControl sx={{ width: 200 }}>
                    <FormLabel
                      id="state-radio-buttons-group-label"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      Status
                    </FormLabel>
                    <Select
                      value={
                        currentState.value
                          ? {
                            value: currentState.value,
                            label: currentState.label,
                          }
                          : null
                      }
                      onChange={handleStateChange}
                      options={
                        states.length > 0
                          ? states.map((state: any) => ({
                            value: state.value,
                            label: state.label,
                          }))
                          : [{ value: null, label: "Loading" }]
                      }
                    />
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <FormControl sx={{ width: 200 }}>
                    <FormLabel
                      id="site-search-label"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      Site
                    </FormLabel>
                    {/* <SiteSelect2
                      onChange={handleSiteChange}
                      value={currentSite}
                    /> */}
                    <Select
                      value={
                        currentSite.value
                          ? {
                            value: currentSite.value,
                            label: currentSite.value.name,
                          }
                          : null
                      }
                      onChange={handleSiteChange}
                      options={
                        sites.length > 0
                          ? [{ value: null, label: "ALL Sites" }].concat(
                            sites.map((site: any) => ({
                              value: site,
                              label: site.name,
                            }))
                          )
                          : [{ value: null, label: "Loading" }]
                      }
                    />
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <FormControl sx={{ width: 200 }}>
                    <FormLabel
                      id="product-search-label"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      Product
                    </FormLabel>
                    <Select
                      value={
                        currentProduct.value
                          ? {
                            value: currentProduct.value,
                            label: currentProduct.value.wasInfo.ProductName,
                          }
                          : null
                      }
                      onChange={handleProductChange}
                      options={
                        products.length > 0
                          ? [{ value: null, label: "ALL Products" }].concat(
                            products.map((product: any) => ({
                              value: product,
                              label: product.wasInfo.ProductName,
                            }))
                          )
                          : [{ value: null, label: "Loading" }]
                      }
                    />
                    {/* <ProductSelect2
                      onChange={handleProductChange}
                      value={currentProduct}
                    /> */}
                    <Select
                      placeholder="Cover..."
                      value={currentCover}
                      onChange={(selectedOption: any) => {
                        handleCoverChange(selectedOption);
                      }}
                      options={[
                        { value: undefined, label: "ALL" },
                        { value: "Platinum", label: "Platinum" },
                        { value: "Diamond", label: "Diamond" },
                      ]}
                      isSearchable={true}
                    />
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <FormControl sx={{ width: 150 }}>
                    <FormLabel
                      id="price-range-label"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      Price
                    </FormLabel>
                    <Box sx={{ width: 150 }}>
                      <Slider
                        min={0}
                        max={1000}
                        getAriaLabel={() => "Price Range"}
                        value={price}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={pricetext}
                      />
                    </Box>
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <FormControl sx={{ width: 150 }}>
                    <FormLabel
                      id="job-role-label"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      Job Role
                    </FormLabel>
                    <Select
                      value={
                        currentRole.value
                          ? {
                            value: currentRole.value,
                            label: currentRole.value?.name,
                          }
                          : null
                      }
                      onChange={handleRoleChange}
                      options={
                        roles.length > 0
                          ? [{ value: null, label: "ALL Roles" }].concat(
                            roles.map((role: any) => ({
                              value: role,
                              label: role.name,
                            }))
                          )
                          : [{ value: null, label: "Loading" }]
                      }
                    />
                    {/* <RoleSelect2
                      onChange={handleRoleChange}
                      value={currentRole}
                    /> */}
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <FormControl sx={{ width: 150 }}>
                    <FormLabel
                      id="cc-label"
                      sx={{ color: ThemeColors.main, fontWeight: 600 }}
                    >
                      CC
                    </FormLabel>
                    <Select
                      placeholder="CC..."
                      value={currentCC}
                      onChange={(selectedOption: any) => {
                        handleCCChange(selectedOption);
                      }}
                      options={[
                        { value: undefined, label: "ALL" },
                        { value: "Pending", label: "Pending" },
                        { value: "Done", label: "Done" },
                      ]}
                      isSearchable={true}
                    />
                  </FormControl>
                </Grid>
                <Divider
                  sx={{ marginLeft: 1, marginRight: 1, borderColor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs>
                  <Button type="submit" onClick={handleSearch}>
                    Apply
                    {/* {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Apply</>
                    )} */}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
        </ApplyBox>
        <SearchResult>
          <SaleOfProductsTable data={searchPara} callback={handleCallback} />
        </SearchResult>
      </div>
    </React.Fragment>
  );
};

export default SaleOfProducts;

const ApplyBox = styled.div`
  padding: 10px;
`;

const PageTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const PageTitle = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftBoxTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const LeftValueBox = styled.div`
  padding: 4px;
  background: ${ThemeColors.main};
  border-radius: 4px;
`;

const LeftValue = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${ThemeColors.white};
  white-space: nowrap;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  border: solid 1px ${ThemeColors.lightGrey};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
`;

const DatePickerBox = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${ThemeColors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 8px;
  margin: 3px;
`;

const CustomDatePickerWrapper = styled.div`
  .react-datepicker__input-container input {
    border: none;
    outline: none;
    width: 100px;
    background: ${ThemeColors.white};
    font-size: 16px;
    font-weight: 600;
    color: ${ThemeColors.main};
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${ThemeColors.main};
  white-space: nowrap;
`;

const SearchResult = styled.div`
  margin-top: -100px;
`;

const IconButton = styled.button`
  border: none;
  background-color: transparent;
`;

const Button = styled.button`
  background: ${ThemeColors.main};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  padding: 8px;
  font-size: 16px;
  color: ${ThemeColors.white};
  border: none;
`;
