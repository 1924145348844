import { DirectoryTypes } from "./actionTypes";

export const getDirectoriesBySiteCode = (siteCode: string) => ({
  type: DirectoryTypes.GET_DIRECTORY_BY_SITECODE,
  payload: siteCode
});

export const getDirectoriesBySiteCodeSuccess = (directory: any) => ({
  type: DirectoryTypes.GET_DIRECTORY_BY_SITECODE_SUCCESS,
  payload: directory
});

export const getDirectoryBySiteFail = () => ({
  type: DirectoryTypes.GET_DIRECTORY_BY_SITECODE_FAIL
});

export const getSitesByServiceId = (serviceId: string) => ({
  type: DirectoryTypes.GET_SITES_BY_SERVICEID,
  payload: serviceId
});


export const getSitesByServiceIdSuccess = (sites: any) => ({
  type: DirectoryTypes.GET_SITES_BY_SERVICEID_SUCCESS,
  payload: sites
});

export const getSitesByServiceIdFail = (error: any) => ({
  type: DirectoryTypes.GET_SITES_BY_SERVICEID_FAIL,
  payload: error
});

export const updateDirectoryStatusByService = (docid:string, data: any) => ({
  type: DirectoryTypes.UPDATE_DIR_STATUS_BY_SERVICE,
  payload: {docid, data}
});

export const updateDirectoryStatusByServiceSuccess = (result: boolean) => ({
  type: DirectoryTypes.UPDATE_DIR_STATUS_BY_SERVICE_SUCCESS,
  payload: result
});















