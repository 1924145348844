import Icon from '@ailibs/feather-react-ts/dist/Icon';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import TutorialModal from './TutorialModal';
// import { fetchHelpVideoUrl } from 'src/helpers/backend_helper';

const productPath = '/products/dashboard/add-notification'
const productPath2 = '/products/dashboard'
const productTutorial = "https://firebasestorage.googleapis.com/v0/b/hendy-production.appspot.com/o/videos%2FHow%20to%20send%20a%20product%20invite%20with%20direct%20debit.mp4?alt=media&token=09affe6f-91cc-46ef-bd16-1fa4c0af4a03";

const customerPath = '/customers';
const customerTutorial = 'https://firebasestorage.googleapis.com/v0/b/hendy-production.appspot.com/o/videos%2FHow%20to%20create%20a%20new%20customer%20and%20send%20them%20a%20Hendy%20APP%20invitation.mp4?alt=media&token=901cc0ad-2a51-4437-950b-9dadd9c00048'

const notificationTemplatePath = '/notifications/templates/add';
const notificationTemplatePath2 = '/notifications/templates';
const notificationTemplateTutorial = 'https://firebasestorage.googleapis.com/v0/b/hendy-production.appspot.com/o/videos%2FHow%20to%20create%20a%20notification%20template.mp4?alt=media&token=5ba8acbb-bd63-4768-b615-542f9a9535f1';

const notificationPath = '/notifications';
const notificationPath2 = '/notifications/add';
const notificationTutorial = 'https://firebasestorage.googleapis.com/v0/b/hendy-production.appspot.com/o/videos%2FHow%20to%20send%20a%20notification.mp4?alt=media&token=10b33eca-9fd8-48d1-a5d9-d62c7e54ee97';

const helpVideoUrlMap: { [id: string]: string } = {
    [productPath]: productTutorial,
    [productPath2]: productTutorial,
    [customerPath]: customerTutorial,
    [notificationTemplatePath]: notificationTemplateTutorial,
    [notificationTemplatePath2]: notificationTemplateTutorial,
    [notificationPath]: notificationTutorial,
    [notificationPath2]: notificationTutorial,
}

const HelpButton = () => {

    const route = useRouteMatch();
    const [helpVideoUrl, setHelpVideoUrl] = useState<string>(productTutorial);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onPlay = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        // fetchHelpVideoUrl(route.path)
        //     .then((res: any) => {
        //         setHelpVideoUrl(res.data);
        //     }).catch((err: any) => {
        //         console.log(err);
        //     });
        setHelpVideoUrl(helpVideoUrlMap[route.path]);
    }, []);


    return helpVideoUrl != null ? (
        <>
            <Container onClick={onPlay}>
                <Icon name="help-circle" color="white" size={30} />
            </Container>
            <TutorialModal
                url={helpVideoUrl}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            />
        </>
    ) : null

}

export default HelpButton;

const Container = styled.div`
    position: fixed;
    bottom: 50px;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #36ad47;
    border-radius: 50%;
    margin: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    transition: all 0.3s ease-in-out;
    &:hover{
        transform: scale(1.1);
    }
    &:active{
        transform: scale(1);
    }
    &:focus{
        outline: none;
    }
    img{
        width: 30px;
        height: 30px;
    }
`;