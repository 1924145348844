import { BookingTypes } from "./actionTypes";

export const getAllBookings = () => ({
  type: BookingTypes.GET_ALL_BOOKINGS,
});

export const getAllBookingsSuccess = (bookings: any) => ({
  type: BookingTypes.GET_ALL_BOOKINGS_SUCCESS,
  payload: bookings,
});

export const getAllBookingsFail = (error: any) => ({
  type: BookingTypes.GET_ALL_BOOKINGS_FAIL,
  payload: error,
});

export const getAllBookingsByCompanyCode = (companyCode: string) => ({
  type: BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE,
  payload: companyCode
});

export const getAllBookingsByCompanyCodeSuccess = (bookings: any) => ({
  type: BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE_SUCCESS,
  payload: bookings,
});

export const getAllBookingsByCompanyCodeFail = (error: any) => ({
  type: BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE_FAIL,
  payload: error,
});

export const getBookingsBySiteCode = (siteCode: string) => ({
  type: BookingTypes.GET_BOOKINGS_BY_SITECODE,
  payload: siteCode
});


export const getBookingsBySiteCodeSuccess = (bookings: any) => ({
  type: BookingTypes.GET_BOOKINGS_BY_SITECODE_SUCCESS,
  payload: bookings,
});

export const getBookingsBySiteCodeFail = (error: any) => ({
  type: BookingTypes.GET_BOOKINGS_BY_SITECODE_FAIL,
  payload: error,
});

export const addBooking = (booking: any) => ({
  type: BookingTypes.ADD_BOOKING,
  payload: booking,
});

export const addBookingSuccess = (booking: any) => ({
  type: BookingTypes.ADD_BOOKING_SUCCESS,
  payload: booking,
});

export const addBookingFail = (error: any) => ({
  type: BookingTypes.ADD_BOOKING_FAIL,
  payload: error,
});

// export const deleteCompnay = (code: string) => ({
//   type: BookingTypes.DELETE_COMPANY,
//   payload: code,
// });

// export const confirmSuccess = () => ({
//   type:BookingTypes.CONFIRM_DELETE
// })

// export const deleteCompanySuccess = (code: any) => ({
//   type: BookingTypes.DELETE_COMPANY_SUCCESS,
//   payload: code,
// });

// export const deleteCompanyFail = (error: any) => ({
//   type: BookingTypes.DELETE_COMPANY_FAIL,
//   payload: error,
// });

// export const updateCompany = (id: string, company: any) => ({
//   type: BookingTypes.UPDATE_COMPANY,
//   payload: {id, company}
// });


// export const updateCompanySuccess = (company: any) => ({
//   type: BookingTypes.UPDATE_COMPANY_SUCCESS,
//   payload: company,
// });

// export const updateCompanyFail = (error: any) => ({
//   type: BookingTypes.UPDATE_COMPANY_FAIL,
//   payload: error,
// });

export const deleteBookingById = (docid: string) => ({
  type: BookingTypes.DELETE_BOOKING_BYID,
  payload: docid,
});

export const deleteBookingByIdSuccess = (docid: any) => ({
  type: BookingTypes.DELETE_BOOKING_BYID_SUCCESS,
  payload: docid,
});

export const getBookingByDocId = (docid: string) => ({
  type: BookingTypes.GET_BOOKING_BY_DOCID,
  payload: docid
})

export const getBookingByDocIdSuccess = (booking: any) => ({
  type: BookingTypes.GET_BOOKING_BY_DOCID_SUCCESS,
  payload: booking,
});

export const getBookingByDocIdFail = (error: any) => ({
  type: BookingTypes.GET_BOOKING_BY_DOCID_FAIL,
  payload: error,
});

// export const updateSiteByDocId = (docid: string, site: any) => ({
//   type: BookingTypes.UPDATE_SITE_BY_DOCID,
//   payload: {docid, site}
// })

// export const updateSiteByDocIdSuccess = (site: any) => ({
//   type: BookingTypes.UPDATE_SITE_BY_DOCID_SUCCESS,
//   payload: site,
// });

// export const updateSiteByDocIdFail = (error: any) => ({
//   type: BookingTypes.UPDATE_SITE_BY_DOCID_FAIL,
//   payload: error,
// });




