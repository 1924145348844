import { useEffect, useState } from "react";
import { getDirectoriesBySite, updateDirectoriesBySite } from "src/helpers/backend_helper";
import { IDirectories } from "src/pages/Directory2/types";




const useDirectories = (siteCode: string) => {

    const [directories, setDirectories] = useState<IDirectories>({});

    const updateDirectories = async (updatedDirectories: IDirectories, updateServer?: boolean) => {

        setDirectories(updatedDirectories);
        if (updateServer) {
            const converted: { [id: string]: Array<string> } = {};
            Object.keys(updatedDirectories).map(key => {
                converted[key] = updatedDirectories[key].map(site => site.code);
            });
            const result = await updateDirectoriesBySite(siteCode, converted);

            if (result.success) {
                return 1;
            } else {
                return -1;
            }
        }
        return 0;
    }


    const fetchDirectories = () => {
        getDirectoriesBySite(siteCode)
            .then(res => {
                if (res.success) {
                    setDirectories(res.result);
                };
            }).catch(console.log)
    }

    useEffect(() => {
        fetchDirectories();
    }, [siteCode])

    return {
        directories,
        updateDirectories,
    }

}

export default useDirectories;