import React, { useEffect, useState } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import {
  deleteGeoFenceById,
  getAllGeoFenceList,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import AddGeoFence from "./add-geo-fence";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
const Products = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { siteCode } = useParams<any>();

  const { geoFenceList, loading, success } = useSelector((state: any) => state.geofence);
  const { currentUser } = useSelector((state: any) => state.login);

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [currentGeoFence, setCurrentGeoFence] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const hasPermission = currentUser?.permissions?.geofence;

  const defaultSorted: any = [
    {
      dataField: "name", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    dispatch(getAllGeoFenceList());
  }, []);

  const handleEditGeoFence = (geofence: any) => {
    history.push(`/geo-fence/${geofence.id}`);
  };

  const handleDeleteGeoFence = (geofence: any) => {
    setCurrentGeoFence(geofence);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteGeoFenceById(currentGeoFence.id));
    if (success) {
      setShowSuccessAlert(true);
    }
  };

  const handleAddNewGeoFence = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  return (
    <TablePage
      loading={loading}
      metaTitle={"GeoFence"}
      data={geoFenceList}
      onAdd={handleAddNewGeoFence}
      hasPermission={hasPermission}
      title={"GeoFence List"}
      description={`(${geoFenceList.length})`}
      columns={makeColumns(handleDeleteGeoFence, handleEditGeoFence, header)}
      pageSize={10}
      totalSize={geoFenceList.length}
      defaultSorted={defaultSorted}
      footer={
        <>
          <AddGeoFence open={drawer} onDrawerClose={onDrawerClose} />
        </>
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false),
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false),
      }}
    />
  );
};

export default withRouter(Products);


const header = [
  {
    dataField: "image",
    text: "Image",
    formatter: (_: any, geofence: any) => (
      <img
        className="rounded avatar-sm"
        src={geofence?.image}
        alt=""
      />
    ),
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
  },
  {
    dataField: "radius",
    text: "Raidus",
    sort: true,
  },
  {
    dataField: "latitude",
    text: "Latitude",
    sort: true,
  },
  {
    dataField: "longitude",
    text: "Longitude",
    sort: true,
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
];
