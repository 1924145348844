import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import { Col, DropdownToggle, DropdownMenu, DropdownItem, Row, Card, CardBody, UncontrolledDropdown } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllSitesByCompanyCode } from "../../store/actions";
import BookingToolComponent from './BookingToolComponent'

const BookingTool = () => {
  const dispatch = useDispatch();
  const [currentSiteCode, setCurrentSiteCode] = useState(null)
  const { currentUser } = useSelector((state: any) => state.login);
  const { sites } = useSelector((state: any) => state.site);

  useEffect(() => {
    if(currentUser?.LOGIN_METHOD === 'COMPANY_LOGIN') {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if(currentUser?.LOGIN_METHOD === 'ADMIN_LOGIN') {
      setCurrentSiteCode(currentUser.siteCode)
    }
  }, [currentUser]);

  const removeSiteCode = () => (
    setCurrentSiteCode(null)
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Booking Tool </title>
        </MetaTags>

        {(currentUser?.LOGIN_METHOD === 'COMPANY_LOGIN' && currentSiteCode === null ) && (
          <Row>
            <h4>Select the Site</h4>
            {sites?.map((item: any, index: number) => {
              return (
                <Col xl="3" sm="6" key={index}>
                  <Card className="text-center">
                    <CardBody>
                      <UncontrolledDropdown className="dropdown text-end">
                        <DropdownToggle className="text-muted font-size-16" tag="a">
                          <i className="bx bx-dots-horizontal-rounded"></i>
                        </DropdownToggle>
    
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#">Edit</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {!item.logo ? (
                        <div className="avatar-xl mx-auto mb-4">
                          <span
                            className={
                              "avatar-title bg-soft-light text-light display-4 m-0 rounded-circle"
                            }
                          >
                            <i className="bx bxs-user-circle"></i>
                          </span>
                        </div>
                      ) : (
                        <div className="mx-auto mb-4">
                          <img
                            className="avatar-xl rounded-circle img-thumbnail"
                            src={item.logo}
                            alt=""
                          />
                        </div>
                      )}
    
                      <h5 className="font-size-15 mb-1">
                        <Link to="#" className="text-dark">
                          {item.name}
                        </Link>
                      </h5>
                      <p className="text-muted mb-2">{item.designation}</p>
                    </CardBody>
                    <div className="btn-group" role="group">
                     
                      <button
                        type="button"
                        className="btn btn-outline-light text-truncate"
                        onClick={() => setCurrentSiteCode(item.code)}
                      >
                        <i className="uil uil-envelope-alt me-1"></i> Setting
                      </button>
                    </div>
                  </Card>
                </Col>  
              )
            })}
           
          </Row>
        )}
        {currentSiteCode && (
          <BookingToolComponent siteCode={currentSiteCode} removeSiteCode = {removeSiteCode} LOGIN_METHOD = {currentUser.LOGIN_METHOD}/>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(BookingTool);
