import { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import useSaleOfProducts from "src/hooks/useSaleOfProducts";
import moment from "moment";
import { ISaleOfProduct } from "src/interfaces/general";
import { getContractByAgreementAsync } from "src/helpers/backend_helper";

import { filter } from "lodash";

// const isMatchProduct = (product: ISaleOfProduct, contract: any): boolean =>
//   moment(product.createdAt).format('YYYY-MM-DD') === moment(contract?.ContractProducts?.[0]?.StartDate).format('YYYY-MM-DD')
//   && (contract?.ContractProducts || []).map((item: any) => item?.CoverLevel.trim()).includes(product.productName)

const SaleOfProductsTable = (props: any) => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state: any) => state.login);
  const { saleOfProducts, loading } = useSaleOfProducts();
  const searchPara = props.data;
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [mergedProducts, setMergedProducts] = useState<any>([]);
  const [contractStates, setContractStates] = useState<any>({});

  const hasPermission = currentUser?.permissions?.saleOfProducts;
  let mergedData;

  useEffect(() => {
    setFilteredProducts(
      saleOfProducts.filter(item => {
        return (
          // item.admin.code === currentUser.code &&
          (item.createdAt === undefined ||
            searchPara.startDate < item.createdAt) &&
          (item.createdAt === undefined ||
            searchPara.endDate > item.createdAt) &&
          // (searchPara.state === undefined || searchPara.state === item.state) &&
          (searchPara.site === undefined || searchPara.site === item.site) &&
          (searchPara.product === undefined ||
            searchPara.product === item.product.id) &&
          (searchPara.cover === undefined || searchPara.cover === item.cover) &&
          searchPara.price[0] < item.price &&
          searchPara.price[1] > item.price &&
          (searchPara.role === undefined ||
            searchPara.role === item.job_role) &&
          (searchPara.cc === undefined || searchPara.cc === item.cc)
        );
      })
    );
  }, [searchPara, saleOfProducts]);

  useEffect(() => {
    if (filteredProducts.length > 0) {
      let agreementArray: string[] = [];
      let contractsArray: any = {};
      filteredProducts.map((product: ISaleOfProduct) => {
        if (!agreementArray.includes(product.agreement.toUpperCase())) {
          agreementArray.push(product.agreement.toUpperCase());
        }
      });

      Promise.all(
        agreementArray.map((agreement: string) =>
          getContractByAgreementAsync(agreement)
        )
      ).then((results: any[]) => {
        setContractStates(
          results.reduce(
            (accum, item, index) => ({
              ...accum,
              [agreementArray[index]]: item,
            }),
            {}
          )
        );
      });
    } else {
    }
  }, [filteredProducts]);

  useEffect(() => {
    mergedData =
      filteredProducts.length > 0
        ? filteredProducts.map((product: ISaleOfProduct) => {
          const contractInfo: [] =
            contractStates[product.agreement.toUpperCase()]?.result || [];
          const foundContract: any = contractInfo.find(
            (item: any) => product?.contractNumber === item?.ContractNumber
          );
          if (foundContract) {
            return {
              ...product,
              state: foundContract?.ContractStatusDescription ?? "",
            };
          }
          return product;
        })
        : [];
    setMergedProducts(
      mergedData.filter((item: any) => {
        return (
          searchPara.state === undefined || searchPara.state === item.state
        );
      })
    );
  }, [filteredProducts, contractStates]);

  useEffect(() => {
    if (mergedProducts.length > 0) {
      let totalSum = 0;
      totalSum = mergedProducts.filter((item: any) => {
        return (
          item.state === "Live"
        )
      }).reduce((sum: number, item: ISaleOfProduct) => (sum = sum * 1 + Number(item.price) * 1), 0);
      if (totalSum > 0) {
        props.callback(Math.round(totalSum * 100) / 100);
      } else {
        props.callback(0);
      }
    } else {
      props.callback(0);
    }
  }, [mergedProducts]);

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Sale of Products")}
      // data={filteredProducts}
      data={mergedProducts}
      hasPermission={hasPermission}
      title={t("Sale of Products")}
      description={`(${mergedProducts.length})`}
      columns={makeColumns(null, null, header)}
      pageSize={10}
      totalSize={mergedProducts.length}
      defaultSorted={defaultSorted}
    />
  );
};

export default withRouter(SaleOfProductsTable);

const header = [
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true,
  },
  {
    dataField: "agreement",
    text: "Agreement",
    sort: true,
  },
  {
    dataField: "state",
    text: "Status",
    sort: true,
  },
  {
    dataField: "createdAt",
    text: "Created",
    formatter: (_: any, saleOfProduct: any) =>
      moment(saleOfProduct.createdAt).format("DD/MM/YYYY"),
    sort: true,
  },
  {
    dataField: "productName",
    text: "Products",
    sort: true,
  },
  {
    dataField: "cover",
    text: "Cover",
    sort: true,
  },
  {
    dataField: "price",
    text: "Price",
    formatter: (_: any, saleOfProduct: any) =>
      saleOfProduct.price.toFixed(2),
    sort: true,
  },
  {
    dataField: "username",
    text: "Username",
    formatter: (_: any, saleOfProduct: any) =>
      saleOfProduct?.admin?.userName || saleOfProduct?.admin?.name,
    sort: true,
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    formatter: (_: any, saleOfProduct: any) =>
      saleOfProduct?.admin?.siteCode,
    sort: true,
  },
  {
    dataField: "department",
    text: "Department",
    sort: true,
  },
  {
    dataField: "job_role",
    text: "Job Role",
    sort: true,
  },
  {
    dataField: "cc",
    text: "CC",
    sort: true,
  },
  {
    dataField: "policy",
    text: "Policy",
    formatter: (_: any, saleOfProduct: any) => (
      <a target="_blank" href={saleOfProduct.policy}>
        <i className="mdi mdi-eye" id="edittooltip"></i>
      </a>
    ),
  },
];
