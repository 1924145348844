import { useEffect, useState } from "react";
import { getSitesByServiceIdAsync } from "src/helpers/backend_helper";
import { ISite } from "src/interfaces/general";


const useSitesByService = (serviceKey: string)=>{

    const [sites, setSites] = useState<Array<ISite>>([]);
    const [loading, setLoading] = useState(false);

    const fetchSites = ()=>{
        setLoading(true);
        getSitesByServiceIdAsync(serviceKey)
            .then(res=>{
                if (res.success){
                    setSites(res.result);
                }
            }).catch(console.log).finally(()=>setLoading(false));
    }

    useEffect(()=>{
        fetchSites();
    }, [])

    return {
        sites,
        loading
    };
}

export default useSitesByService;