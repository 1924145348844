import { call, put, takeEvery } from "redux-saga/effects";
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, loginFail, mfaRequired, ADMIN_LOGIN } from "./actions";
import { LoginResponse } from '../../../interfaces/general'
import { companyAdminLogin, loginStaffAsync, loginMFAAsync, resendMFAAsync } from '../../../helpers/backend_helper'
import { axiosApi } from "src/helpers/api_helper";

function* loginCompanyAdmin({ payload: { user, history } }: any) {
  try {
    const response: LoginResponse = yield call(companyAdminLogin, user);
    // console.log('response', response, process.env.REACT_APP_API_URL);
    if (response.success) {

      console.log(response);
      
      if (response.mfaRequired){
        yield put(mfaRequired(response))
        return;
      }

      //axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.accessToken
      const authUser = setAuthUser(response, "COMPANY_LOGIN");
      yield put(loginSuccess(authUser));
      history.push(`/dashboard`);
    } else {
      yield put(loginFail(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginStaff({ payload: { user, history } }: any) {
  try {
    const response: LoginResponse = yield call(loginStaffAsync, user);
    if (response.success) {
      if (response.mfaRequired){
        yield put(mfaRequired(response))
        return;
      }
      const authUser = setAuthUser(response, "ADMIN_LOGIN");
      yield put(loginSuccess(authUser));
      history.push(`/dashboard`);
    } else {
      yield put(loginFail(response));
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* loginMFA({ payload : { mfa, history}} : any){
  try {
    const response: LoginResponse = yield call(loginMFAAsync, mfa);
    if (response.success){
      const authUser = setAuthUser(response, mfa.loginMethod);
      yield put(loginSuccess(authUser));
      history.push(`/dashboard`);
    } else {
      yield put(loginFail(response));
    }
  } catch(error){
    yield put(apiError(error))
  }
}

function* resendMFA({ payload :  mfa } : any){
  try {
    const response: LoginResponse = yield call(resendMFAAsync, mfa);
    if (response.success){
      yield put(mfaRequired(response))
        return;
    }else {
      yield put(loginFail(response));
    }
  } catch(error){
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_STAFF_ADMIN, loginStaff);
  yield takeEvery(LoginTypes.LOGIN_COMPANY_ADMIN, loginCompanyAdmin);
  yield takeEvery(LoginTypes.LOGIN_MFA, loginMFA);
  yield takeEvery(LoginTypes.RESEND_MFA, resendMFA);
}

function setAuthUser(response: LoginResponse, loginMethod: string){
  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.accessToken
  sessionStorage.setItem('accessToken', response.accessToken);
  const authUser = response.user ?? response.result;
  authUser.LOGIN_METHOD = loginMethod;
  sessionStorage.setItem("authUser", JSON.stringify(authUser));
  return authUser;
}

export default authSaga;
