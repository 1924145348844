import { CustomerTypes } from "./actionTypes";

export const INIT_STATE: any = {
  customers: [],
  singleCustomer: {},
  error: {},
  loading: false,
  success: false,
};

const customer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CustomerTypes.GET_CUSTOMERS_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case CustomerTypes.GET_CUSTOMERS_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload,
      };

    case CustomerTypes.GET_CUSTOMERS_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE:
        return {
          ...state,
          loading: true,
        };
  
      case CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE_SUCCESS:
        return {
          ...state,
          loading: false,
          customers: action.payload,
        };
  
      case CustomerTypes.GET_CUSTOMERS_BY_COMPANYCODE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    case CustomerTypes.ADD_CUSTOMER:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CustomerTypes.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        customers: [...state.customers, action.payload],
      };

    case CustomerTypes.ADD_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case CustomerTypes.GET_CUSTOMER_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case CustomerTypes.GET_CUSTOMER_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCustomer: action.payload,
      };

    case CustomerTypes.GET_CUSTOMER_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CustomerTypes.UPDATE_CUSTOMER_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case CustomerTypes.UPDATE_CUSTOMER_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCustomer: action.payload,
      };

    case CustomerTypes.UPDATE_CUSTOMER_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CustomerTypes.DELETE_CUSTOMER:
      return {
        ...state,
        loading: true,
        success: false
      };

    case CustomerTypes.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        customers: state.customers.filter((item: any) => item.id !== action.payload),
      };

    case CustomerTypes.DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      }

    default:
      return state;
  }
};

export default customer;
