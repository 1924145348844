import { IServiceProps } from "../types";
import styled from 'styled-components';
import { CheckBox } from "./general";
import AddButton from "./AddButton";
import { useTranslation } from "react-i18next";

const ServiceItem = ({
    service,
    onToggle,
    onAddItem,
    onRemoveItem,
    directories
}: IServiceProps) =>{

    const {t} = useTranslation();
    
    return(
        <ServiceItemContainer>
            <CheckBox 
                value={service.selected}
                label={t("directory."+service.key)} // in this way
                onChange={onToggle}
            />
            <DirContainer>
                {directories.map((site, index)=>(
                    <DirectoryItem key={index}>
                        <SiteLogo src={site.logo}/>
                        <Body>
                            <SiteName>{site.name}</SiteName>
                            <SiteCode>{site.code}</SiteCode>
                        </Body>
                        <RemoveIcon onClick={()=>onRemoveItem(site.code)}>
                            <span className='fa fa-trash'/>
                        </RemoveIcon>
                    </DirectoryItem>
                ))}
                <AddButton service={service} onSelect={onAddItem}/>
            </DirContainer>
        </ServiceItemContainer>
    )
}

export default ServiceItem;

const ServiceItemContainer = styled.div`

    margin: 20px;
`

const DirContainer = styled.div`
    display: flex;
    align-items: center;
`

const DirectoryItem = styled.div`
    height: 50px;
    border-radius: 8px;
    background-color: white;
    border: solid 1px black;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: black;
    margin: 10px;
    position: relative;
`

const RemoveIcon = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: red;
    font-size: 12px;
    cursor: pointer;
`

const SiteLogo = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-left: 10px;
    border: solid 1px green;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
`

const SiteName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 600;
`

const SiteCode = styled.div`
    font-size: 12px;
    color: grey;
`