import { DirectoryTypes } from "./actionTypes";

export const INIT_STATE: any = {
  directories: [],
  singleDirectory: {},
  error: {},
  success: false,
  loading: false,
  loading_sites: false,
  sites_by_services: [],
};

const directory = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    
    case DirectoryTypes.GET_DIRECTORY_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };
    
    case DirectoryTypes.GET_DIRECTORY_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleDirectory: action.payload,
      };

    case DirectoryTypes.GET_DIRECTORY_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        singleDirectory: null,
      };

    case DirectoryTypes.GET_SITES_BY_SERVICEID:
      return {
        ...state,
        loading_sites: true,
      };
    case DirectoryTypes.GET_SITES_BY_SERVICEID_SUCCESS:
      return {
        ...state,
        loading_sites: false,
        sites_by_services: action.payload,
      };

    case DirectoryTypes.GET_SITES_BY_SERVICEID_FAIL:
      return {
        ...state,
        loading_sites: false,
        error: action.payload,
      };

    case DirectoryTypes.UPDATE_DIR_STATUS_BY_SERVICE:
      return {
        ...state,
        loading: true,
      };

    case DirectoryTypes.UPDATE_DIR_STATUS_BY_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default directory;
