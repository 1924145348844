import { useEffect, useState } from "react";
import { addOnboardingAsync, getAllOnboardingsAsync } from "src/helpers/backend_helper";
import { IOnboard } from "src/interfaces/general";

const useOnboardings = () => {

    const [onboardings, setOnboardings] = useState({});
    const [cards, setCards] = useState<Array<IOnboard>>([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const fetchOnboardings = () => {
        setLoading(true);
        getAllOnboardingsAsync()
            .then(res => {
                if (res.success) {
                    setOnboardings(res.result || {});
                    setCards(res.result.cards || []);
                }
            }).catch(console.log).finally(() => setLoading(false));
    }


    const addOnboardings = async (onboardings: any) => {

        try {
            setLoading(true);
            const result = await addOnboardingAsync(onboardings);
            if (result.success) {
                fetchOnboardings()
                setLoading(false);
                setSuccess(true);
                return true;
            }
        } catch (error) {
            console.log("Onboardings add error", error);
        }
        setLoading(false);
        return false;
    }

    const onRemove = (onboard: IOnboard) => {
        const index = cards.findIndex(card => card == onboard);
        cards.splice(index, 1);
        setCards([...cards]);
        onUpdate();
    }

    const onAdd = (onboard: IOnboard) => {
        cards.push(onboard);
        setCards([...cards]);
        onUpdate();
    }

    const onEdit = (card: IOnboard) => {
        setCards([...cards, card]);
        onUpdate();
    }

    const onChangeVisible = (onboard: IOnboard) => {
        onboard.visible = !onboard.visible;
        setCards([...cards]);
        onUpdate();
    }

    const onUpdate = () => {
        setOnboardings({ ...onboardings, cards: cards });
        addOnboardings(onboardings);
    }

    useEffect(() => {
        fetchOnboardings();
    }, [])

    return {
        onboardings,
        cards,
        loading,
        addOnboardings,
        onRemove,
        onAdd,
        onEdit,
        onChangeVisible,
        success
    };
}

export default useOnboardings;
