import { AdminTypes } from "./actionTypes";

// export const getSitesByCompanyCode = (companyCode: string) => ({
//   type: AdminTypes.GET_SITES,
//   payload: companyCode
// });

//get all dmin by sitecode
export const getAllAdminsBySiteCode = (siteCode: string) => ({
  type: AdminTypes.GET_ADMINS_BY_SITECODE,
  payload: siteCode
});

export const getAdminsBySiteCodeSuccess = (admins: any) => ({
  type: AdminTypes.GET_ADMINS_BY_SITECODE_SUCCESS,
  payload: admins,
});

export const getAdminsBySiteCodeFail = (error: any) => ({
  type: AdminTypes.GET_ADMINS_BY_SITECODE_FAIL,
  payload: error,
});


export const addAdmin = (admin: any) => ({
  type: AdminTypes.ADD_ADMIN,
  payload: admin,
});

export const addAdminSuccess = (admin: any) => ({
  type: AdminTypes.ADD_ADMIN_SUCCESS,
  payload: admin,
});

export const addAdminFail = (error: any) => ({
  type: AdminTypes.ADD_ADMIN_FAIL,
  payload: error,
});

//delete admin
export const deleteAdminByDocId = (docId: string) => ({
  type: AdminTypes.DELETE_ADMIN_BY_DOCID,
  payload: docId,
});

export const deleteAdminByDocIdSuccess = (code: any) => ({
  type: AdminTypes.DELETE_ADMIN_BY_DOCID_SUCCESS,
  payload: code,
});

export const deleteAdminByDocIdFail = (error: any) => ({
  type: AdminTypes.DELETE_ADMIN_BY_DOCID_FAIL,
  payload: error,
});

// export const updateCompany = (id: string, company: any) => ({
//   type: AdminTypes.UPDATE_COMPANY,
//   payload: {id, company}
// });


// export const updateCompanySuccess = (company: any) => ({
//   type: AdminTypes.UPDATE_COMPANY_SUCCESS,
//   payload: company,
// });

// export const updateCompanyFail = (error: any) => ({
//   type: AdminTypes.UPDATE_COMPANY_FAIL,
//   payload: error,
// });


export const getAdminByDocId = (docid: string) => ({
  type: AdminTypes.GET_ADMIN_BY_DOCID,
  payload: docid
})

export const getAdminByDocIdSuccess = (admin: any) => ({
  type: AdminTypes.GET_ADMIN_BY_DOCID_SUCCESS,
  payload: admin,
});

export const getAdminByDocIdFail = (error: any) => ({
  type: AdminTypes.GET_ADMIN_BY_DOCID_FAIL,
  payload: error,
});

export const updateAdminByDocId = (docid: string, admin: any, history: any) => ({
  type: AdminTypes.UPDATE_ADMIN_BY_DOCID,
  payload: {docid, admin, history}
})

export const updateAdminByDocIdSuccess = (admin: any) => ({
  type: AdminTypes.UPDATE_ADMIN_BY_DOCID_SUCCESS,
  payload: admin,
});

export const updateAdminByDocIdFail = (error: any) => ({
  type: AdminTypes.UPDATE_ADMIN_BY_DOCID_FAIL,
  payload: error,
});

//get all Admin by CompanyCode

export const getAllAdminsByCompanyCode = (companyCode: string) => ({
  type: AdminTypes.GET_ADMINS_BY_COMPANYCODE,
  payload: companyCode
});

export const getAllAdminsByCompanyCodeSuccess = (admins: any) => ({
  type: AdminTypes.GET_ADMINS_BY_COMPANYCODE_SUCCESS,
  payload: admins,
});






