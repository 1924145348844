import { useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row } from "reactstrap";

export interface IButton {
    name: string;
    icon?: string;
    link?: string;
    type: "link" | "booking" | "date" | "none"
}

interface IProps {
    value: IButton;
    onChange: (btn: IButton) => void;
}

const InputButton = (props: IProps) => {

    const btn = useRef<IButton>(props.value);

    const onChange = (field: string, value: string) => {
        // @ts-ignore;
        btn.current[field] = value;
        props.onChange(btn.current);
    }

    return (
        <div>
            <AvForm>
                <Row>
                    <Col>
                        <AvField
                            name="name"
                            label="Name"
                            type="text"
                            className="template-subject"
                            onChange={(e: any) => onChange("name", e.target.value)}
                            value={props.value?.name}
                        />
                    </Col>
                    <Col>
                        <AvField
                            type="select"
                            name="type"
                            label="Type"
                            className="template-type"
                            onChange={(e: any) => onChange("type", e.target.value)}
                            value={props.value?.type}
                        >
                            <option>link</option>
                            <option>booking</option>
                            <option>date</option>
                            <option>none</option>
                        </AvField>
                    </Col>
                    <Col>
                        <AvField
                            type="select"
                            name="icon"
                            label="Icon"
                            className="template-icon"
                            onChange={(e: any) => onChange("icon", e.target.value)}
                            value={props.value?.icon}
                        >
                            <option>none</option>
                            <option>arrow-forward</option>
                            <option>calendar</option>
                        </AvField>
                    </Col>
                    <Col>
                        <AvField
                            type="text"
                            name="link"
                            label="Link"
                            className="template-link"
                            onChange={(e: any) => onChange("link", e.target.value)}
                            value={props.value.type != 'link' ? "" : props.value?.link}
                            disabled={props.value.type != 'link'}
                        />
                    </Col>
                </Row>
            </AvForm>
        </div>
    )
}

export default InputButton;