import { CompanyTypes } from "./actionTypes";

export const INIT_STATE: any = {
  companies: [],
  singleCompany: {},
  error: {},
  loading: false,
  confirm_del: false,
  add_company_status: false,
};

const company = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CompanyTypes.GET_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case CompanyTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload,
      };

    case CompanyTypes.GET_COMPANIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CompanyTypes.GET_COMPANY_BY_CODE:
      return {
        ...state,
        loading: true,
      };

    case CompanyTypes.GET_COMPANY_BYCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCompany: action.payload,
      };

    case CompanyTypes.GET_COMPANY_BYCODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CompanyTypes.ADD_COMPANY:
      return {
        ...state,
        add_company_status: false,
        loading: true,
      };
    case CompanyTypes.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        add_company_status: true,
        companies: [...state.companies, action.payload],
      };

    case CompanyTypes.DELETE_COMPANY:
      return {
        ...state,
        confirm_del: false,
        loading: true,
      };

    case CompanyTypes.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        confirm_del: true,
        companies: state.companies.filter(
          (item: any) => item.code !== action.payload
        ),
      };

    case CompanyTypes.CONFIRM_DELETE:
      return {
        ...state,
        loading: false,
        confirm_del: false,
      };

    case CompanyTypes.UPDATE_COMPANY:
      return {
        ...state,
        loading: true,
      };

    case CompanyTypes.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: [
          ...state.companies.filter(
            (item: any) => item.code === action.payload.code
          ),
          ...action.payload,
        ],
      };

    case CompanyTypes.UPDATE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // case ContactTypes.GET_USERS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    default:
      return state;
  }
};

export default company;
