export enum GeoFenceTypes {
  ADD_GEO_FENCE = 'ADD_GEO_FENCE',
  GET_ALL_GEOFENCE_LISTS = "GET_ALL_GEOFENCE_LISTS",
  GET_GEOFENCE_LIST_SUCCESS = "GET_GEOFENCE_LIST_SUCCESS",
  GET_GEOFENCE_LIST_FAIL = "GET_GEOFENCE_LIST_FAIL",
  ADD_GEO_FENCE_SUCCESS = "ADD_GEO_FENCE_SUCCESS",
  ADD_GEO_FENCE_FAIL = "ADD_GEO_FENCE_FAIL",
  GET_GEOFENCE_BY_DOCID = "GET_GEOFENCE_BY_DOCID",
  GET_GEOFENCE_BY_DOCID_SUCCESS = "GET_GEOFENCE_BY_DOCID_SUCCESS",
  GET_GEOFENCE_BY_DOCID_FAIL = "GET_GEOFENCE_BY_DOCID_FAIL",
  UPDATE_GEOFENCE_BY_DOCID = "UPDATE_GEOFENCE_BY_DOCID",
  UPDATE_GEOFENCE_BY_DOCID_SUCCESS = "UPDATE_GEOFENCE_BY_DOCID_SUCCESS",
  UPDATE_GEOFENCE_BY_DOCID_FAIL = "UPDATE_GEOFENCE_BY_DOCID_FAIL",
  DELETE_GEOFENCE_BYID = "DELETE_GEOFENCE_BYID",
  DELETE_GEOFENCE_BYID_SUCCESS = "DELETE_GEOFENCE_BYID_SUCCESS",
  DELETE_GEOFENCE_BYID_FAIL = "DELETE_GEOFENCE_BYID_FAIL",
}
