import React, { Fragment, useEffect, useState } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { deleteProductById, getAllProducts } from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getAllSitesByCompanyCode,
  getSiteBySiteCode,
  getAllWasProducts,
  setCurrentWasProductInfo
} from "../../store/actions";
import AddProduct from "./add-product";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { getAllDealerProductsAsync } from "src/helpers/backend_helper";
const Products = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [siteList, setSitesList] = useState<any>([]);
  const [siteCode, setSiteCode] = useState<any>("");
  const [siteName, setSiteName] = useState<string>("");
  const [wasDealerId, setWasDealerId] = useState<string>("");
  const { sites, authSite, loading } = useSelector((state: any) => state.site);
  const { currentUser } = useSelector((state: any) => state.login);
  const { products, success, } = useSelector(
    (state: any) => state.product
  );
  const productData = useSelector((state: any) => state.product);

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [currentProduct, setCurrentProduct] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const hasPermission = currentUser?.permissions?.products;

  const defaultSorted: any = [
    {
      dataField: "name", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const getSites = () => {
    return currentUser?.LOGIN_METHOD === "ADMIN_LOGIN" ? authSite : sites;
  }

  useEffect(() => {
    if (productData?.siteName) {
      setSiteCode(productData.siteCode);
      setSiteName(productData.siteName);
      if (sites.length > 0) {
        getAlldealerWasProduct(productData.siteCode);
      }
    }
  }, [productData?.siteName])

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      dispatch(getSiteBySiteCode(currentUser.siteCode));
    }
  }, [currentUser]);

  useEffect(() => {
    siteCodeLists(getSites());
  }, [sites, authSite]);

  const siteCodeLists = async (lists: any) => {
    let arr: { id: string; value: string; label: string }[] = [];
    if (lists.length > 0) {
      lists.filter((item: any) => item != null).map((item: any) => {
        arr.push({ id: item.code, value: item.name, label: item.name });
      });
    }
    setSitesList(arr);
  };
  const handleChange = (selectedOption: any) => {
    setSiteCode(selectedOption.id);
    setSiteName(selectedOption.value);
    getAlldealerWasProduct(selectedOption.id);
  };

  const getAlldealerWasProduct = async (siteCode: string) => {
    const result = getSites().filter(
      (item: any) => item.code == siteCode
    );
    let dealerData = {
      DealerId: ""
    };
    if (result.length > 0) {
      dealerData = {
        DealerId: result[0].dealerId
      };
      setWasDealerId(result[0].dealerId);
      dispatch(getAllWasProducts(dealerData));
    } else {
      setWasDealerId("")
      dispatch(getAllWasProducts(dealerData));
    }
  }

  const handleEditProduct = (product: any) => {
    history.push(`/products/${product.DealerProductId}-${product.MonthlyDuration}`);
    dispatch(setCurrentWasProductInfo(product.DealerProductId, product.MonthlyDuration, siteCode, siteName, product.wasDealerId))
  };

  const handleDeleteProduct = (product: any) => {
    setCurrentProduct(product);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteProductById(currentProduct.id));
    if (success) {
      setShowSuccessAlert(true);
    }
  };

  const handleAddNewProduct = () => {
    setDrawer(true);
  };
  const productEmpty = [
    { ProductName: 'There is no products for this site. Please select another site' },
  ]

  return (
    <Fragment>
      <TablePage
        siteName={siteName}
        siteList={siteList}
        handleChange={handleChange}
        loading={loading}
        metaTitle={t("Products")}
        data={products.length > 0 ? products : productEmpty}
        onAdd={handleAddNewProduct}
        hasPermission={hasPermission}
        title={t("products.title")}
        description={`(${products.length})`}
        columns={
          products.length > 0 ? (
            makeColumns(handleDeleteProduct, handleEditProduct, header, undefined, true)
          ) : (
            makeColumns(handleDeleteProduct, handleEditProduct, headerEmpty, undefined, true)
          )
        }
        pageSize={10}
        totalSize={products.length}
        defaultSorted={defaultSorted}
        confirmAlert={{
          visible: showConfirmAlert,
          onConfirm: confirmDelete,
          onCancel: () => setShowConfirmAlert(false),
        }}
        successAlert={{
          visible: showSuccessAlert,
          onConfirm: () => setShowSuccessAlert(false),
        }}
      />
    </Fragment>
  );
};

export default withRouter(Products);

const hiddenColForLastRow = (cell: any, row: any, rowIndex: number, colIndex: number) => {
  if (rowIndex === 0) {
    return { hidden: true }
  }
}

const headerEmpty = [
  {
    dataField: "ProductName",
    text: "Product Name",
    sort: true,
    classes: 'text-center font-weight-bolder',
    attrs: { colSpan: "9" }
  },
  {
    dataField: "DealerCost",
    text: "Dealer Cost",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "MonthlyDuration",
    text: "Monthly Duration",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "AgeFrom",
    text: "Age From",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "AgeTo",
    text: "Age To",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "MileageFrom",
    text: "Mileage From",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "MileageTo",
    text: "Mileage To",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "ClaimLimit",
    text: "Claim Limit",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "AggregatedClaimLimit",
    text: "Aggregated Claim Limit",
    sort: true,
    attrs: hiddenColForLastRow
  },
  {
    dataField: "menu",
    editable: true,
    text: "Action",
    hidden: true,
    attrs: hiddenColForLastRow
  },
];

const header = [
  {
    dataField: "ProductName",
    text: "Product Name",
    sort: true,
  },
  {
    dataField: "DealerCost",
    text: "Dealer Cost",
    sort: true,
  },
  {
    dataField: "Monthly",
    text: "Monthly Price",
    formatter: (_: any, product: any) => (
      product.Monthly.toFixed(2)
    ),
    sort: true,
  },
  {
    dataField: "AgeFrom",
    text: "Age From",
    sort: true,
  },
  {
    dataField: "AgeTo",
    text: "Age To",
    sort: true,
  },
  {
    dataField: "MileageFrom",
    text: "Mileage From",
    sort: true,
  },
  {
    dataField: "MileageTo",
    text: "Mileage To",
    sort: true,
  },
  {
    dataField: "ClaimLimit",
    text: "Claim Limit",
    sort: true,
  },
  {
    dataField: "AggregatedClaimLimit",
    text: "Aggregated Claim Limit",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  },
];
