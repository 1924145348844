import { VehicleTypes } from "./actionTypes";

export const INIT_STATE: any = {
  vehicles: [],
  signleVehicle: {},
  error: {},
  success: false,
  loading: false,
  confirm_del: false,
};

const site = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case VehicleTypes.GET_ALL_VEHICLES:
      return {
        ...state,
        loading: true,
      };

    case VehicleTypes.GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicles: action.payload,
      };

    case VehicleTypes.GET_ALL_VEHICLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VehicleTypes.ADD_VEHICLE:
      return {
        ...state,
        loading: true,
      };
    case VehicleTypes.ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicles: [...state.vehicles, action.payload],
      };

    case VehicleTypes.ADD_VEHICLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VehicleTypes.GET_VEHICLE_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case VehicleTypes.GET_VEHICLE_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        signleVehicle: action.payload,
      };

    case VehicleTypes.GET_VEHICLE_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VehicleTypes.DELETE_VEHICLE_BYID:
      return {
        ...state,
        loading: false,
        success: false,
      };

    case VehicleTypes.DELETE_VHEICLE_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vehicles: state.vehicles.filter(
          (vehicle:any) => vehicle.id !== action.payload
        ),
      };

    // case VehicleTypes.UPDATE_SITE_BY_DOCID:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case VehicleTypes.UPDATE_SITE_BY_DOCID_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     signleSite: action.payload,
    //   };

    // case VehicleTypes.UPDATE_SITE_BY_DOCID_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    // case VehicleTypes.DELETE_COMPANY:
    //   return {
    //     ...state,
    //     confirm_del: false,
    //     loading: true,
    //   };

    // case VehicleTypes.DELETE_COMPANY_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     confirm_del: true,
    //     companies: state.companies.filter(
    //       (item: any) => item.code !== action.payload
    //     ),
    //   };

    // case VehicleTypes.CONFIRM_DELETE:
    //   return {
    //     ...state,
    //     loading: false,
    //     confirm_del: false,
    //   };

    // case VehicleTypes.UPDATE_COMPANY:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case VehicleTypes.UPDATE_COMPANY_SUCCESS:

    //   return {
    //     ...state,
    //     loading: false,
    //     companies: [
    //       ...state.companies.filter((item: any) => item.code === action.payload.code),
    //       ...action.payload
    //     ]
    //   };

    // case VehicleTypes.UPDATE_COMPANY_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload
    //   };
    default:
      return state;
  }
};

export default site;
