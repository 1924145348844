import React from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";

const CheckinSettings = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                <title>Checkins</title>
                </MetaTags>
                <Container fluid>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CheckinSettings;