import { WarrantyTypes } from "./actionTypes";


export const getAllWarrantyList = () => ({
  type: WarrantyTypes.GET_ALL_WARRANTY_LISTS,
});

export const getWarrantySuccess = (WarrantyList: any) => ({
  type: WarrantyTypes.GET_WARRANTY_LIST_SUCCESS,
  payload: WarrantyList,
});

export const getWarrantyFail = (error: any) => ({
  type: WarrantyTypes.GET_WARRANTY_LIST_FAIL,
  payload: error,
});

export const addWarranty = (warranty: any) => ({
  type: WarrantyTypes.ADD_WARRANTY,
  payload: warranty,
});

export const addWarrantySuccess = (warranty: any) => ({
  type: WarrantyTypes.ADD_WARRANTY_SUCCESS,
  payload: warranty,
});

export const addWarrantyFail = (error: any) => ({
  type: WarrantyTypes.ADD_WARRANTY_FAIL,
  payload: error,
});

export const getWarrantyByDocId = (docid: string) => ({
  type: WarrantyTypes.GET_WARRANTY_BY_DOCID,
  payload: docid
})

export const getWarrantyByDocIdSuccess = (admin: any) => ({
  type: WarrantyTypes.GET_WARRANTY_BY_DOCID_SUCCESS,
  payload: admin,
});

export const getWarrantyByDocIdFail = (error: any) => ({
  type: WarrantyTypes.GET_WARRANTY_BY_DOCID_FAIL,
  payload: error,
});

export const deleteWarrantyById = (docid: string) => ({
  type: WarrantyTypes.DELETE_WARRANTY_BYID,
  payload: docid,
});

export const deleteWarrantyByDocIdSuccess = (docid: any) => ({
  type: WarrantyTypes.DELETE_WARRANTY_BYID_SUCCESS,
  payload: docid,
});

export const deleteWarrantyByDocIdFail = (error: any) => ({
  type: WarrantyTypes.DELETE_WARRANTY_BYID_FAIL,
  payload: error,
});

export const updateWarrantyByDocId = (docid: string, warranty: any, history: any) => ({
  type: WarrantyTypes.UPDATE_WARRANTY_BY_DOCID,
  payload: { docid, warranty, history },
});

export const updateWarrantyByDocIdSuccess = (warranty: any) => ({
  type: WarrantyTypes.UPDATE_WARRANTY_BY_DOCID_SUCCESS,
  payload: warranty,
});

export const updateWarrantyByDocIdFail = (error: any) => ({
  type: WarrantyTypes.UPDATE_WARRANTY_BY_DOCID_FAIL,
  payload: error,
});
