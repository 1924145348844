import { call, put, take, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { ProductTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  addProductFail,
  addProductSuccess,
  getProductsBySiteCodeFail,
  getProductsBySiteCodeSuccess,
  deleteProductByDocIdSuccess,
  deleteProductByDocIdFail,
  getProductByDocIdSuccess,
  getProductByDocIdFail,
  updateProductByDocIdSuccess,
  updateProductByDocIdFail,
} from "./actions";

import {
  getProductsBySiteCodeAsync,
  getAllProductsAsync,
  getProductByDocIdAsync,
  addProductAsync,
  deleteProductByIdAsync,
  updateProductByDocIdAsync,
  getAllDealerProductsAsync
} from "../../helpers/backend_helper";

function* getProductsBySiteCode({ payload: siteCode }: any) {
  try {
    const response: IResponse = yield call(
      getProductsBySiteCodeAsync,
      siteCode
    );
    if (response.success) {
      yield put(getProductsBySiteCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getProductsBySiteCodeFail(error));
  }
}

function* getAllProducts() {
  try {
    const response: IResponse = yield call(getAllProductsAsync);
    if (response.success) {
      yield put(getProductsBySiteCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getProductsBySiteCodeFail(error));
  }
}

function* addProduct({ payload: product }: any) {
  try {
    const response: IResponse = yield call(addProductAsync, product);
    if (response.success) {
      try {
        const response: IResponse = yield call(getAllProductsAsync);
        if (response.success) {
          yield put(getProductsBySiteCodeSuccess(response.result));
        }
      } catch (error) {
        yield put(getProductsBySiteCodeFail(error));
      }
    }
  } catch (error) {
    yield put(addProductFail(error));
  }
}

function* deleteProductById({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(deleteProductByIdAsync, docid);
    if (response.success) {
      try {
        const response: IResponse = yield call(getAllProductsAsync);
        if (response.success) {
          yield put(getProductsBySiteCodeSuccess(response.result));
        }
      } catch (error) {
        yield put(getProductsBySiteCodeFail(error));
      }
    }
  } catch (error) {
    yield put(deleteProductByDocIdFail(error));
  }
}

function* getProductByDocId({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(getProductByDocIdAsync, docid);
    if (response.success) {
      yield put(getProductByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getProductByDocIdFail(error));
  }
}

function* updateProductByDocId({ payload: { docid, product, history } }: any) {
  try {
    const response: IResponse = yield call(
      updateProductByDocIdAsync,
      docid,
      product
    );
    if (response.success) {
      yield put(updateProductByDocIdSuccess(response.result));
      history.push("/products");
    }
  } catch (error) {
    yield put(updateProductByDocIdFail(error));
  }
}

function* getAllWasProducts({ payload: data }: any) {
  try {
    const response: IResponse = yield call(getAllDealerProductsAsync, data);
    if (response?.success) {
      if (response?.result.length > 0) {
        yield put(getProductsBySiteCodeSuccess(
          response.result.map((item: any) => ({
            ...item,
            wasDealerId: data.DealerId,
          }))
        ));
      } else {
        yield put(getProductsBySiteCodeSuccess([]));
      }
    }
  } catch (error) {
    yield put(getProductsBySiteCodeFail(error));
  }
}

function* productSaga() {
  yield takeEvery(ProductTypes.GET_PRODUCTS_BY_SITECODE, getProductsBySiteCode);
  yield takeEvery(ProductTypes.GET_ALL_PRODUCTS, getAllProducts);
  yield takeEvery(ProductTypes.ADD_PRODUCT, addProduct);
  yield takeEvery(ProductTypes.DELETE_PRODUCT_BYID, deleteProductById);
  yield takeEvery(ProductTypes.GET_PRODUCT_BY_DOCID, getProductByDocId);
  yield takeEvery(ProductTypes.UPDATE_PRODUCT_BY_DOCID, updateProductByDocId);
  yield takeEvery(ProductTypes.GET_ALL_WAS_PRODUCTS, getAllWasProducts);
}

export default productSaga;
