import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { IAdmin } from "src/interfaces/general";
import AddStaffButton from './AddStaffButton';


interface IProps {
    members:Array<IAdmin>;
    onAddStaff:(admin:IAdmin) => void;
    onRemoveStaff: (admin: IAdmin) => void;
}
const StaffList = (props:IProps) =>{

    const {t} = useTranslation();
    
    return(
        <>            
            <StaffListContainer>
                {props.members.map((admin, index)=>(
                    <StaffItem key={index}>
                        <StaffAvatar src={admin.avatar}/>
                        <Body>
                            <SiteName>{admin.userName}</SiteName>
                        </Body>
                        <RemoveIcon onClick={()=>props.onRemoveStaff(admin)}>
                            <span className='fa fa-trash'/>
                        </RemoveIcon>
                    </StaffItem>
                ))}
                <AddStaffButton onSelect={props.onAddStaff}/>
            </StaffListContainer>
        </>
    )
}

export default StaffList;


const StaffListContainer = styled.div`
    display: flex;
    align-items: center;
`

const StaffItem = styled.div`
    height: 50px;
    border-radius: 8px;
    background-color: white;
    border: solid 1px black;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: black;
    margin: 10px;
    position: relative;
`

const RemoveIcon = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: red;
    font-size: 12px;
    cursor: pointer;
`

const StaffAvatar = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-left: 10px;
    border: solid 1px green;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
`

const SiteName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 600;
`
