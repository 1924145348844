import { useEffect, useState } from "react";
import {
  getNotificationsByCustomerIdAsync,
  addNotificationAsync,
  deleteNotificationByIdAsync,
  updateNotificationByIdAsync,
  getNotificationsByCompanyCodeAsync,
  getNotificationsBySiteCodeAsync,
  getNotificationsByDocIdAsync,
  getNotificationsByDocTypeAsync,
} from "src/helpers/backend_helper";
import { INotification } from "src/interfaces/general";

const useNotifications = (
  companyCode?: string,
  customerId?: string,
  siteCode?: string
) => {
  const [notifications, setNotifications] = useState<Array<INotification>>([]);
  const [singleNotification, setSingleNotification] = useState<INotification>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const fetchNotificationsByCompanyCode = () => {
    setLoading(true);
    if (companyCode) {
      getNotificationsByCompanyCodeAsync(companyCode)
        .then(res => {
          if (res.success) {
            setNotifications(res.result || []);
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const fetchNotificationById = (id: string) => {
    setLoading(true);
    if (id) {
      getNotificationsByDocIdAsync(id)
        .then(res => {
          if (res.success) {
            setSingleNotification(res.result || {});
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const fetchNotificationByType = (type: string, code: string) => {
    setLoading(true);
    if (type) {
      const result =  getNotificationsByDocTypeAsync(type, code)
        .then((res: { success: any; result: any; }) => {
          if (res.success) {
            return(res.result || []);
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
      return result;
    }
  };

  const fetchNotificationsBySiteCode = () => {
    setLoading(true);
    if (siteCode) {
      getNotificationsBySiteCodeAsync(siteCode)
        .then(res => {
          if (res.success) {
            setNotifications(res.result || []);
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const fetchNotifications = () => {
    setLoading(true);
    if (customerId) {
      getNotificationsByCustomerIdAsync(customerId)
        .then(res => {
          if (res.success) {
            setNotifications(res.result || []);
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const addNotification = async (notification: INotification) => {
    try {
      setLoading(true);
      const result = await addNotificationAsync(notification);
      if (result.success) {
        notifications.push(notification);
        setNotifications([...notifications]);
        setLoading(false);
        setSuccess(true);
        return true;
      }
    } catch (error) {
      console.log("notification add error", error);
    }
    setLoading(false);
    return false;
  };

  const deleteNotification = async (id: string) => {
    const index = notifications.findIndex(
      notification => notification.id === id
    );
    try {
      setLoading(true);
      const result = await deleteNotificationByIdAsync(id);
      if (result.success) {
        notifications.splice(index, 1);
        setNotifications([...notifications]);
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.log("notification delete error", error);
    }
    setLoading(false);
    return false;
  };

  const updateNotification = async (
    id: string,
    notification: INotification
  ) => {
    try {
      setLoading(true);
      setSuccess(true);
      const result = await updateNotificationByIdAsync(id, notification);
      if (result.success) {
        notifications.push(notification);
        setNotifications([...notifications]);
        setLoading(false);
        setSuccess(false);
        return true;
      }
    } catch (error) {
      console.log("notification edit error", error);
    }
    setLoading(false);
    setSuccess(false);
    return false;
  };

  useEffect(() => {
    fetchNotificationsByCompanyCode();
  }, [companyCode]);

  // useEffect(() => {
  //   fetchNotifications();
  // }, [customerId]);

  return {
    notifications,
    loading,
    fetchNotifications,
    fetchNotificationById,
    fetchNotificationByType,
    deleteNotification,
    addNotification,
    updateNotification,
    success,
    singleNotification,
  };
};

export default useNotifications;
