import Slider from "react-rangeslider";
import 'react-rangeslider/lib/index.css';
import { useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { storage } from "src/helpers/firebase_config";

interface IProps{
  singleSelect:string;
  onChange:(avatar:string)=>void;
  title:string;
}

const AvatarEdit = (props:IProps) => {  

  const {t} = useTranslation();
  const [editor, setEditor] = useState<any>();
  const [filetype, setFiletype] = useState("");
  const [filesize, setFilesize] = useState("");
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: "",
    zoom: 2,
    croppedImg: props.singleSelect
  });
  const [filename, setFilename] = useState("");

  useEffect(() => {
    setPicture({
      ...picture,
      croppedImg: props.singleSelect|| "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
    });
  }, [props.singleSelect]);

  const [modal_standard, setmodal_standard] = useState(false);

  const handleSlider = (value:any) => {
    setPicture({
      ...picture,
      zoom: value
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false
    });
  };

  const setEditorRef = (ed:any) => {
    setEditor(ed)
  };

  const handleSave = async (e:any) => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      setPicture({
        ...picture,
        img: "",
        cropperOpen: false,
        croppedImg: croppedImg
      }); 

      const storageRef = storage.ref();
      const filesRef = storageRef.child('images/' + filename + '.png');
      await filesRef.putString(croppedImg, 'data_url')
        .then((snapshot) => {
          console.log(snapshot)
        }).catch((error) => {
          console.log("error")
        })
      const newURL = await filesRef.getDownloadURL();
      props.onChange(newURL);
    }
  };

  const handleFileChange = async (e:any) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setFiletype(e.target.files[0].type);
    const size = ((e.target.files[0].size/1024).toFixed(0)).toString() + "KB";
    setFilesize (size);
    
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true
    });
    setmodal_standard(true);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  };
  
  
  return (
    <div>
      <div className="d-flex" >
        <div className="d-flex align-items-end" style={{width:"50%"}}>
          <div>
            <h6>{t("avatar."+props.title)}</h6>            
            <img 
              className="rounded-circle avatar-edit "
              src={picture.croppedImg}
            />
          </div>
          <div className="d-flex flex-column">
            <FileInfo>
              <label>file type: {filetype}</label>
              <label>Size: {filesize}</label>
            </FileInfo>            
            <button
              type="submit"
              className="btn btn-success save-user mb-3"            
              style={{height:50}}
            >
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </button>
          </div>          
        </div>

        {picture.cropperOpen && (
          <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t("avatar.avatar_edit")}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>{t("avatar.Position and size your new avatar")}</h5>
          </div>
          <div className="d-flex flex-column align-items-center p-3 ">
            <AvatarEditor
              ref={setEditorRef}
              image={picture.img}
              width={200}
              height={200}
              border={25}
              color={[255, 255, 255, 0.6]} 
              rotate={0}
              scale={picture.zoom}
            />
            <div className="p-3" style={{width:"100%"}}>                        
              <Slider
                aria-label="raceSlider"
                orientation="horizontal"
                value={picture.zoom}
                min={1}
                max={10}
                step={0.1}
                onChange={handleSlider}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"              
              onClick={handleCancel}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              {t("avatar.close")}
            </button>
            <button 
              type="button" 
              className="btn btn-primary " 
              onClick={handleSave} 
            >
              {t("avatar.save_changes")}
            </button>
          </div>
        </Modal>
        )}
      </div>
    </div>
  );
};

export default AvatarEdit;

const FileInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 300px;
`