import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Button, Col, Container, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import styled from 'styled-components';
import InputButton, { IButton } from './InputButton';
import useNotificationTemplates from 'src/hooks/useNotiffcationTemplates';
import { useSelector } from 'react-redux';
import NotifcifationTemplateModal from './NotificationTemplateModal';
import ImagePicker from 'src/components/Common/ImagePicker';
import NavHeader from 'src/components/Common/NavHeader';
import { useHistory, useParams } from 'react-router';

const iPhoneImage = 'https://firebasestorage.googleapis.com/v0/b/hendy-aa44c.appspot.com/o/images%2Fnotification.png?alt=media&token=b3f83cd9-f502-40c2-ba9e-ac7f10fcf9c0'

const AddTemplate = () => {
    const { docid: templateId } = useParams<any>()
    const { currentUser } = useSelector((state: any) => state.login);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [buttons, setButtons] = useState<IButton[]>([]);
    const [coverImg, setCoverImg] = useState("");
    const [type, setType] = useState<"tax" | "mot" | "warranty" | "service" | "insurance" | "normal" | undefined>();
    const {
        loading, singleTemplate, addTemplate,
        updateTemplate, fetchTemplateById
    } = useNotificationTemplates();
    const [uploading, setUploading] = useState<boolean>(false);
    const history = useHistory();


    const onCreate = async (values: any) => {
        values.companyCode = currentUser?.code || currentUser?.companyCode;
        values.buttons = buttons;
        values.coverImage = coverImg;

        try {
            const res = (templateId ? await updateTemplate(templateId, values) : await addTemplate(values));
            if (res) {
                history.goBack();
            }
        } catch (err) {
            console.log(err);
        }
    }

    const onAddButton = () => {
        setButtons([...buttons, { name: "", type: "none" }]);
    }

    const onChangeButton = (button: IButton, index: number) => {
        buttons[index] = button;
        setButtons([...buttons]);
    }

    const onChangeCoverImg = (coverImg: string) => {
        setCoverImg(coverImg);
    }

    useEffect(() => {
        if (templateId) {
            fetchTemplateById(templateId);
        }
    }, []);

    useEffect(() => {
        if (singleTemplate) {
            setTitle(singleTemplate.subject);
            setDescription(singleTemplate.body);
            setType(singleTemplate.type);
            setButtons(singleTemplate.buttons);
            setCoverImg(singleTemplate.coverImage || "");
        }
    }, [singleTemplate]);

    return (
        <React.Fragment>
            <MetaTags>
                <title>{templateId ? "Edit Template" : "Add Template"}</title>
            </MetaTags>
            <div className="page-content" style={{ height: '100vh', overflow: 'scroll' }}>
                <Container fluid>
                    <NavHeader
                        title={templateId ? "Edit Template" : "Add Template"}
                        description={templateId ? "You can edit this template" : "Please create a new template."}
                    />
                    <Row>
                        <Col llg={3} className="mt-3">
                            <IPhone>
                                <IPhoneImage src={iPhoneImage} />
                                <NotificationContent>
                                    <Title>{title}</Title>
                                    <Description>{description}</Description>
                                </NotificationContent>
                            </IPhone>
                        </Col>
                        <Col llg={3} className="mt-3">
                            <NotifcifationTemplateModal
                                singleTemplate={{
                                    subject: title,
                                    body: description,
                                    type: type,
                                    buttons,
                                    coverImage: coverImg
                                }}
                            />
                        </Col>
                        <Col llg={6} className="mt-3">
                            <AvForm
                                onValidSubmit={(e: any, values: any) => {
                                    onCreate(values);
                                }}
                            >
                                <ImagePicker
                                    placeholder='Pick a cover image'
                                    onChange={onChangeCoverImg}
                                    uploadingStatus={setUploading}
                                    value={coverImg}
                                />
                                <Spacer />
                                <AvField
                                    name="subject"
                                    label="Subject"
                                    type="text"
                                    className="template-subject"
                                    value={title}
                                    onChange={(e: any) => setTitle(e.target.value)}
                                />
                                <Spacer />
                                <AvField
                                    name="body"
                                    label="Body"
                                    type="textarea"
                                    className="template-body"
                                    value={description}
                                    onChange={(e: any) => setDescription(e.target.value)}
                                />
                                <Spacer />
                                <AvField
                                    type="select" name="type" label="Type"
                                    className="template-type"
                                    onChange={setType}
                                    value={type}
                                >
                                    <option>none</option>
                                    <option>warrany</option>
                                    <option>mot</option>
                                    <option>tax</option>
                                    <option>insurance</option>
                                    <option>service</option>
                                </AvField>
                                <Spacer />
                                {buttons?.map((button, index) => (
                                    <InputButton
                                        key={index}
                                        value={button}
                                        onChange={value => onChangeButton(value, index)}
                                    />
                                ))}
                                {buttons.length < 2 && <AddButton>
                                    <Button type="button" onClick={onAddButton}>
                                        <span className='fa fa-plus' />
                                    </Button>
                                </AddButton>}
                                <CreateButton>
                                    <Button
                                        type="submit"
                                        color='primary'
                                        className='w-md'
                                        disabled={loading || uploading}
                                    >
                                        {(loading || uploading) ? <i className="bx bx-loader bx-spin align-middle me-2" /> : null}
                                        {uploading ? "Wait..." : (templateId ? "Update" : "Create")}
                                    </Button>
                                </CreateButton>
                            </AvForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddTemplate;

const IPhone = styled.div`
    position: relative;
`

const IPhoneImage = styled.img`
    width: 310px;
    height 672px;
`

const NotificationContent = styled.div`
    position: absolute;
    left: 50px;
    top 190px;
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    font-size: 12px;
    color: black;
    font-weight: bold;
    margin-top: 2px;
    line-height: 20px;
`

const Description = styled.div`
    font-size: 10px;
    font-weight: bold;
    color: black;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 220px;
`
const AddButton = styled.div`
    margin-top:10px;
`
const CreateButton = styled.div`
    margin-top:10px;
    display: flex;
    flex-direction: row;
    justify-content: end;
`

const Spacer = styled.div`
    height: 10px;   
`