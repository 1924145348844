export enum BookingTypes {

  GET_BOOKINGS_BY_COMPANY_CODE = "GET_BOOKINGS_BY_COMPANY_CODE",
  GET_BOOKINGS_BY_COMPANY_CODE_SUCCESS = "GET_BOOKINGS_BY_COMPANY_CODE_SUCCESS",
  GET_BOOKINGS_BY_COMPANY_CODE_FAIL = "GET_BOOKINGS_BY_COMPANY_CODE_FAIL",
  
  GET_ALL_BOOKINGS = "GET_ALL_BOOKINGS",
  GET_ALL_BOOKINGS_SUCCESS = 'GET_ALL_BOOKINGS_SUCCESS',
  GET_ALL_BOOKINGS_FAIL = 'GET_ALL_BOOKINGS_FAIL',
  
  GET_BOOKINGS_BY_SITECODE = "GET_BOOKINGS_BY_SITECODE",
  GET_BOOKINGS_BY_SITECODE_SUCCESS = 'GET_BOOKINGS_BY_SITECODE_SUCCESS',
  GET_BOOKINGS_BY_SITECODE_FAIL = 'GET_BOOKINGS_BY_SITECODE_FAIL',

  ADD_BOOKING = 'ADD_BOOKING',
  ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS',
  ADD_BOOKING_FAIL = 'ADD_BOOKING_FAIL',

  GET_BOOKING_BY_DOCID = 'GET_BOOKING_BY_DOCID',
  GET_BOOKING_BY_DOCID_SUCCESS = 'GET_BOOKING_BY_DOCID_SUCCESS',
  GET_BOOKING_BY_DOCID_FAIL = 'GET_BOOKING_BY_DOCID_FAIL',

  DELETE_BOOKING_BYID = 'DELETE_BOOKING_BYID',
  DELETE_BOOKING_BYID_SUCCESS = 'DELETE_BOOKING_BYID_SUCCESS'
  
}
