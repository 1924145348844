import React, { useEffect, useState } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import {
  deleteWarrantyById,
  getAllWarrantyList,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import AddWarranty from "./add-warranty";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
const Warranty = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { siteCode } = useParams<any>();

  const { warrantyList, loading, success } = useSelector((state: any) => state.warranty);
  const { currentUser } = useSelector((state: any) => state.login);

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [currentWarranty, setCurrentWarranty] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const hasPermission = currentUser?.permissions?.warranty;

  const defaultSorted: any = [
    {
      dataField: "name", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    dispatch(getAllWarrantyList());
  }, []);

  const handleEditWarranty = (warranty: any) => {
    history.push(`/warranty/${warranty.id}`);
  };

  const handleDeleteWarranty = (warranty: any) => {
    setCurrentWarranty(warranty);
    setShowConfirmAlert(true);
  };

  const confirmDelete = () => {
    setShowConfirmAlert(false);
    dispatch(deleteWarrantyById(currentWarranty.id));
    if (success) {
      setShowSuccessAlert(true);
    }
  };

  const handleAddNewWarranty = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  return (
    <TablePage
      loading={loading}
      metaTitle={"Warranty"}
      data={warrantyList}
      onAdd={handleAddNewWarranty}
      hasPermission={hasPermission}
      title={"Warranty List"}
      description={`(${warrantyList.length})`}
      columns={makeColumns(handleDeleteWarranty, handleEditWarranty, header)}
      pageSize={10}
      totalSize={warrantyList.length}
      defaultSorted={defaultSorted}
      footer={
        <>
          <AddWarranty open={drawer} onDrawerClose={onDrawerClose} />
        </>
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false),
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false),
      }}
    />
  );
};

export default withRouter(Warranty);


const header = [
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true,
  },
  {
    dataField: "brand",
    text: "Brand",
    sort: true,
  },
  {
    dataField: "warranty.duration",
    text: "Warranty Duration",
    sort: true,
  },
  {
    dataField: "warranty.mileage",
    text: "Warranty Mileage",
    sort: true,
  },
  {
    dataField: "recovery.duration",
    text: "Recovery Duration",
    sort: true,
  },
  {
    dataField: "recovery.phone",
    text: "Recovery Phone",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action"
  }
];
