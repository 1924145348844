import { CommsTypes } from "./actionTypes";

export const INIT_STATE: any = {
  comms: [],
  signleComm: {},
  error: {},
  success: false,
  loading: false,
};

const comms = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case CommsTypes.GET_COMMS_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case CommsTypes.GET_COMMS_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        signleComm: action.payload,
      };

    case CommsTypes.GET_COMMS_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        signleComm: action.payload,
      };
      
    case CommsTypes.SAVE_COMMS:
      return {
        ...state,
        loading: true,
      };

    case CommsTypes.SAVE_COMMS_SUCCESS:
      return {
        ...state,
        loading: false,
        signleComm: action.payload,
      };

    default:
      return state;
  }
};

export default comms;
