import React, { useEffect, useState, useRef } from "react";
import {  Col, Container, Row, Button, FormGroup } from "reactstrap";
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { saveComms, getCommsBySiteCode } from "../../store/actions";

interface IProps {
    siteCode: string;
    removeSiteCode: () => void;
    LOGIN_METHOD: string
  }

const CommsComponent = (props: IProps) => {
  const dispatch = useDispatch();
  const [focusedName, setFocusedName] = useState("")
  const [selectedPosition, setSelectedPosition] = useState(0)
  const [initialValues, setInitialValues] = useState({})

  const form =useRef<any>(null);
  
  const { signleComm, loading } = useSelector((state: any) => state.comms);

  useEffect(() => {
    dispatch(getCommsBySiteCode(props.siteCode));
  }, [props.siteCode]);

  useEffect(() => {
    handleInitialValues(signleComm)
  }, [signleComm])

  const handleInitialValues = (result:any) => {
    let initialValues: any = {}
    initialValues.sharing_sms_subject = result?.sharing?.sms?.title
    initialValues.sharing_sms_content = result?.sharing?.sms?.description
    initialValues.sharing_email_subject = result?.sharing?.email?.title
    initialValues.sharing_email_content = result?.sharing?.email?.description
    initialValues.preconfirmation_email_subject = result?.preconfirm?.email?.title
    initialValues.preconfirmation_email_content = result?.preconfirm?.email?.description
    initialValues.preconfirmation_sms_subject = result?.preconfirm?.sms?.title
    initialValues.preconfirmation_sms_content = result?.preconfirm?.sms?.description
    initialValues.checkin_email_subject = result?.checkin?.email?.title
    initialValues.checkin_email_content = result?.checkin?.email?.description
    initialValues.checkin_sms_subject = result?.checkin?.sms?.title
    initialValues.checkin_sms_content = result?.checkin?.sms?.description
    initialValues.checkout_email_subject = result?.checkout?.email?.title
    initialValues.checkout_email_content = result?.checkout?.email?.description
    initialValues.checkout_sms_subject = result?.checkout?.sms?.title
    initialValues.checkout_sms_content = result?.checkout?.sms?.description
    initialValues.checkincomp_email_subject = result?.checkinCompletion?.email?.title
    initialValues.checkincomp_email_content = result?.checkinCompletion?.email?.description
    initialValues.checkincomp_sms_subject = result?.checkinCompletion?.sms?.title
    initialValues.checkincomp_sms_content = result?.checkinCompletion?.sms?.description
    setInitialValues(initialValues)
  }

  const addStr = (str: string, index: any, stringToAdd: any) => {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const getFocusedItem = (itemName: string, position: any) => {
    setFocusedName(itemName)
    setSelectedPosition(position)
  }

  const handleLabel = (symbol: string) => {
    const values = form.current.values
    const temp = values[focusedName] || ""
    const attachedString = addStr(temp, selectedPosition, ` {${symbol}}`)
    form.current.setFieldValue(focusedName, attachedString)
  }

  const submitComms = (values: any) => {

    const commsData: any = {
      sharing: {
        email: { title: values.sharing_email_subject, description: values.sharing_email_content },
        sms: { title: values.sharing_sms_subject, description: values.sharing_sms_content },
      },
      preconfirm: {
        email: { title: values.preconfirmation_email_subject, description: values.preconfirmation_email_content },
        sms: { title: values.preconfirmation_sms_subject, description: values.preconfirmation_sms_content },
      },
      checkin: {
        email: { title: values.checkin_email_subject, description: values.checkin_email_content },
        sms: { title: values.checkin_sms_subject, description: values.checkin_sms_content },
      },
      checkout: {
        email: { title: values.checkout_email_subject, description: values.checkout_email_content },
        sms: { title: values.checkout_sms_subject, description: values.checkout_sms_content },
      },
      checkinCompletion: {
        email: { title: values.checkincomp_email_subject, description: values.checkincomp_email_content },
        sms: { title: values.checkincomp_sms_subject, description: values.checkincomp_sms_content },
      }
    }
    commsData.siteCode = props.siteCode
    dispatch(saveComms(commsData));
  }

  return (
        <Container fluid>
            <Row className="mt-4">
            <div className="mb-4">
              {props.LOGIN_METHOD === 'COMPANY_LOGIN' && (
                 <button type="button" className="btn btn-primary waves-effect waves-light" onClick={props.removeSiteCode}>
                 <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>{" "}
                 Back
               </button>
              )}
            </div>
          </Row>
          <Row className = "mt-4">
            <Col md = {6}>
              <Formik initialValues={initialValues} enableReinitialize onSubmit={submitComms} innerRef={form}>
                {
                  ({errors, touched}) => (
                    <Form ref={form} className="av-tooltip tooltip-label-bottom" >
                      <Row>
                          <h3 className="form-Label">Sharing</h3>
                          <Col md = {6}>
                            
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "SMS (Subject)"
                                className="form-control comm-component"
                                name="sharing_sms_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("sharing_sms_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("sharing_sms_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "SMS (Content)"
                                className="form-control comm-component mail-content"
                                name="sharing_sms_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("sharing_sms_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("sharing_sms_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md = {6}>
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "Email (Subject)"
                                className="form-control comm-component"
                                name="sharing_email_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("sharing_email_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("sharing_email_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "Email (Content)"
                                className="form-control comm-component mail-content"
                                name="sharing_email_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("sharing_email_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("sharing_email_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          
                        </Row>

                        <Row className="mt-4">
                          <h3 className="form-Label">Checkin</h3>
                          <Col md = {6}>
                            
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "SMS (Subject)"
                                className="form-control comm-component"
                                name="checkin_sms_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("checkin_sms_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkin_sms_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "SMS (Content)"
                                className="form-control comm-component mail-content"
                                name="checkin_sms_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("checkin_sms_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkin_sms_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md = {6}>
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "Email (Subject)"
                                className="form-control comm-component"
                                name="checkin_email_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("checkin_email_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkin_email_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "Email (Content)"
                                className="form-control comm-component mail-content"
                                name="checkin_email_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("checkin_email_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkin_email_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          
                        </Row>

                        <Row className="mt-4"> 
                          <h3 className="form-Label">CheckOut</h3>
                          <Col md = {6}>
                            
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "SMS (Subject)"
                                className="form-control comm-component"
                                name="checkout_sms_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("checkout_sms_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkout_sms_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "SMS (Content)"
                                className="form-control comm-component mail-content"
                                name="checkout_sms_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("checkout_sms_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkout_sms_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md = {6}>
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "Email (Subject)"
                                className="form-control comm-component"
                                name="checkout_email_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("checkout_email_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkout_email_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "Email (Content)"
                                className="form-control comm-component mail-content"
                                name="checkout_email_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("checkout_email_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkout_email_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          
                        </Row>

                        <Row className="mt-4"> 
                          <h3 className="form-Label">Pre confirmation</h3>
                          <Col md = {6}>
                            
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "SMS (Subject)"
                                className="form-control comm-component"
                                name="preconfirmation_sms_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("preconfirmation_sms_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("preconfirmation_sms_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "SMS (Content)"
                                className="form-control comm-component mail-content"
                                name="preconfirmation_sms_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("preconfirmation_sms_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("preconfirmation_sms_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md = {6}>
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "Email (Subject)"
                                className="form-control comm-component"
                                name="preconfirmation_email_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("preconfirmation_email_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("preconfirmation_email_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "Email (Content)"
                                className="form-control comm-component mail-content"
                                name="preconfirmation_email_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("preconfirmation_email_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("preconfirmation_email_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          
                        </Row>

                        <Row className="mt-4"> 
                          <h3 className="form-Label">Check In Completion</h3>
                          <Col md = {6}>
                            
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "SMS (Subject)"
                                className="form-control comm-component"
                                name="checkincomp_sms_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("checkincomp_sms_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkincomp_sms_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "SMS (Content)"
                                className="form-control comm-component mail-content"
                                name="checkincomp_sms_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("checkincomp_sms_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkincomp_sms_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md = {6}>
                            <FormGroup className="form-group">
                              <Field
                                placeholder = "Email (Subject)"
                                className="form-control comm-component"
                                name="checkincomp_email_subject"
                                onKeyDown={(e:any) => {
                                  getFocusedItem("checkincomp_email_subject", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkincomp_email_subject", e.target.selectionStart) }}
                              />
                            </FormGroup>
                            <FormGroup className="form-group mt-4">
                              <Field
                                placeholder = "Email (Content)"
                                className="form-control comm-component mail-content"
                                name="checkincomp_email_content"
                                component="textarea"
                                onKeyDown={(e: any) => {
                                  getFocusedItem("checkincomp_email_content", e.target.selectionStart + 1)
                                }}
                                onClick={(e: any) => { getFocusedItem("checkincomp_email_content", e.target.selectionStart) }}
                              />
                            </FormGroup>
                          </Col>
                          
                        </Row>
                        <Row className="mt-4">
                          {/* <Col md={6}> */}
                            <button type="submit" className="btn btn-success save-user" disabled={loading}>
                              {loading ? (
                                <SpinnerCircular
                                  size={30}
                                  thickness={150}
                                  speed={150}
                                  color="#36ad47"
                                  secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                              ) : (
                                <>Submit</>
                              )}
                            </button>
                          {/* </Col> */}
                        </Row>
                    </Form>
                  )
                }
              </Formik>

            </Col>

            <Col md = {6}>
              <h3 className="form-Label mt-4">Customer</h3>
              <Row>
                <div className="d-flex justify-content-between flex-wrap">
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("C1") }}>Name</Button>
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("C2") }}>Address</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("C3") }} >Phone Number</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("C4") }}>Email</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("C5") }}>Location</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("C7") }}> Customer Notes</Button>
                </div>
              </Row>
              <h3 className="form-Label mt-4">Vehicle</h3>
              <Row>
                <div className="d-flex justify-content-between flex-wrap">
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("V1") }}>Registration</Button>
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("V2") }}>Tax Date</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("V3") }} > Service Date </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("V4") }}>Make/Model</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("V5") }}>Color</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("V6") }}> Mot Expiry Date</Button>
                </div>
              </Row>
              <h3 className="form-Label mt-4">Garage</h3>
              <Row>
                <div className="d-flex justify-content-between flex-wrap">
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("G1") }}>Garage Name</Button>
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("G2") }}>Address</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G3") }} > Appointment date </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G4") }}> Service Options</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G5") }}>Garage Code</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G6") }}> Manager Name </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G7") }}>  Manager Position </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G8") }}>  Garage contact details  </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("G9") }}>   Car location    </Button>

                </div>
              </Row>
              <h3 className="form-Label mt-4">Pin Code</h3>
              <Row>
                <div className="d-flex justify-content-between flex-wrap">
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("K1") }}>Pin Code</Button>
                  <Button color="primary" className="label-btn m-2" onClick={() => { handleLabel("pre") }}>Pre Confirmation</Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("in") }} > Check in </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("out") }}> Check out </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("qr") }}> QR code </Button>
                  <Button className="label-btn" color="primary m-2" onClick={() => { handleLabel("dueDate") }}>  Due Date </Button>
                </div>
              </Row>
            </Col>
          </Row>     
        </Container>
  );
};

export default CommsComponent;
