import { useState } from "react";
import * as api from "src/helpers/backend_helper";
import { IMake } from "src/interfaces/general";

const useMakes = () => {

    const [makes, setMakes] = useState<Array<IMake>>([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const fetchMakes = () => {
        setLoading(true);
        api.getAllMakes()
            .then(res => {
                console.log(res);
                if (res.success) {
                    setMakes(res.result || []);
                    setSuccess(true);
                }
            })
            .catch(console.log)
            .finally(() => setLoading(false));
    }

    const deleteMake = async (id: string) => {
        setLoading(true);
        api.deleteMake(id)
            .then(res => {
                if (res.success) {
                    setMakes(makes.filter(make => make.id !== id));
                    setSuccess(true);
                }
            })
            .catch(console.log)
            .finally(() => setLoading(false));
    }

    const addMake = async (make: IMake) => {
        setLoading(true);
        api.addMake(make)
            .then(res => {
                if (res.success) {
                    setMakes([
                        ...makes,
                        {
                            ...make,
                            id: res.docid,
                        }
                    ]);
                    setSuccess(true);
                }
            })
            .catch(error=>{
                alert(error.response.data);
            })
            .finally(() => setLoading(false));
    }

    const updateMake = async (make: IMake) => {
        setLoading(true);
        api.updateMake(make)
            .then(res => {
                if (res.success) {
                    setMakes(
                        makes.map(m => {
                            if (m.id === make.id) {
                                return make;
                            }
                            return m;
                        })
                    );

                    setSuccess(true);
                }
            })
            .catch(error=>{
                alert(error.response.data);
            })
            .finally(() => setLoading(false));
    }

    return {
        loading,
        makes,
        success,

        fetchMakes,
        deleteMake,
        addMake,
        updateMake
    };
}

export default useMakes;