import React, { useEffect, useState } from "react";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SpinnerCircular } from "spinners-react";
import { IMake, IModel } from "src/interfaces/general";

interface IProps {
  open: boolean;
  onClose: () => void;

  make?: IMake;
  onAddMake: (make: IMake) => void;
  onUpdateMake: (make: IMake) => void;
  loading: boolean;
  success: boolean;
}

const AddMake = (props: IProps) => {

  const [adding, setAdding] = useState<boolean>(false);
  const [models, setModels] = useState<IModel[]>(props.make?.models || []);
  const [model, setModel] = useState<string>("");
  const [makeName, setMakeName] = useState<string>(props.make?.name || "");

  const onAddMake = (values: any) => {
    setAdding(true);
    if (props.make?.id) {
      props.onUpdateMake({
        ...props.make,
        name: values.name,
        models
      });
    } else {
      props.onAddMake({
        name: values.name,
        models
      });
    }
  }

  const onAddModel = () => {
    if (model && models.findIndex((m: IModel) => m.name === model.toUpperCase()) === -1) {
      setModels([...models, { name: model.toUpperCase() }]);
    }
    setModel("");
  }

  useEffect(() => {
    if (props.success && adding) {
      setAdding(false);
      props.onClose()
    }
  }, [props.success, adding]);

  useEffect(() => {
    setMakeName(props.make?.name || "");
    setModels(props.make?.models || []);
  }, [props.make]);

  return (
    <React.Fragment>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onClose}
      >
        <AvForm
          onValidSubmit={(_: any, values: any) => onAddMake(values)}
          className="d-flex flex-column p-4 h-100"
          style={{ width: 375 }}
        >
          <Row>
            <Col md="12">
              <div className="mb-3">
                <AvField
                  name="name"
                  label="Make Name"
                  placeholder="Make Name"
                  type="text"
                  errorMessage="Invalid Name"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={(e: any) => setMakeName(e.target.value)}
                  value={makeName}
                />
              </div>
            </Col>
          </Row>

          <div className="my-4" style={{ flex: 1, overflow: 'scroll', position: 'relative' }}>
            <div style={{ position: 'sticky', top: 0, background: 'white' }}>
              <h4>Models</h4>
            </div>
            {models.map((model: IModel, index: number) => (
              <div key={index} className="d-flex my-3 justify-content-between align-items-center">
                <div className="directory-item-content">
                  <div className="directory-item-content-name">{model.name.toUpperCase()}</div>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      setModels(
                        models.filter((_: IModel, i) => i !== index)
                      );
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="form-group mb-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Add Model"
                value={model}
                onChange={(e: any) => setModel(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={onAddModel}
                >
                  <i className="mdi mdi-plus" />
                </button>
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary w-md"
                  disabled={props.loading}
                >
                  {props.loading ? (
                    <SpinnerCircular
                      size={30}
                      thickness={150}
                      speed={150}
                      color="#36ad47"
                      secondaryColor="rgba(0, 0, 0, 0.44)"
                    />
                  ) : (
                    <>Save</>
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ReactDrawer>
    </React.Fragment >
  );
};

export default AddMake;
