import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, UncontrolledDropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { saveComms, getCommsBySiteCode } from "../../store/actions";
import { getAllSitesByCompanyCode } from "../../store/actions";
import CommsComponent from "./CommsComponent";

const Comms = () => {
  const dispatch = useDispatch();
  const [currentSiteCode, setCurrentSiteCode] = useState('')
  const { currentUser } = useSelector((state: any) => state.login);
  const { sites } = useSelector((state: any) => state.site);

  useEffect(() => {
    if(currentUser?.LOGIN_METHOD === 'COMPANY_LOGIN') {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }
    if(currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      setCurrentSiteCode(currentUser.siteCode)
    }
  }, [currentUser]);

  const removeSiteCode = () => (
    setCurrentSiteCode('')
  )

  const handleSiteCode = (siteCode: string) => {
    setCurrentSiteCode(siteCode)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Comms Setting</title>
        </MetaTags>

        {(currentUser?.LOGIN_METHOD === 'COMPANY_LOGIN' && currentSiteCode === '' ) && (
          <Row>
            <h4>Select the Site</h4>
            {sites?.map((item: any, index: number) => {
              return (
                <Col xl="3" sm="6" key={index}>
                  <Card className="text-center">
                    <CardBody>
                      <UncontrolledDropdown className="dropdown text-end">
                        <DropdownToggle className="text-muted font-size-16" tag="a">
                          <i className="bx bx-dots-horizontal-rounded"></i>
                        </DropdownToggle>
    
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#">Edit</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {!item.logo ? (
                        <div className="avatar-xl mx-auto mb-4">
                          <span
                            className={
                              "avatar-title bg-soft-light text-light display-4 m-0 rounded-circle"
                            }
                          >
                            <i className="bx bxs-user-circle"></i>
                          </span>
                        </div>
                      ) : (
                        <div className="mx-auto mb-4">
                          <img
                            className="avatar-xl rounded-circle img-thumbnail"
                            src={item.logo}
                            alt=""
                          />
                        </div>
                      )}
    
                      <h5 className="font-size-15 mb-1">
                        <Link to="#" className="text-dark">
                          {item.name}
                        </Link>
                      </h5>
                      <p className="text-muted mb-2">{item.designation}</p>
                    </CardBody>
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className="btn btn-outline-light text-truncate"
                        onClick={() => handleSiteCode(item.code)}
                      >
                        <i className="uil uil-envelope-alt me-1"></i> Setting
                      </button>
                    </div>
                  </Card>
                </Col>  
              )
            })}
          </Row>
        )}
        {currentSiteCode && (
          <CommsComponent siteCode={currentSiteCode} removeSiteCode = {removeSiteCode} LOGIN_METHOD = {currentUser.LOGIN_METHOD} />
        )}

      </div>
    </React.Fragment>
  );
};

export default withRouter(Comms);
