import React, { Fragment, useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Label,
  Input,
  Card,
  CardTitle,
  Spinner,
  Button,
  CardBody,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllGeoFenceList } from "../../store/actions";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";
const containerStyle = {
  width: "100%",
  height: "500px",
};

const DisplayGeoFence = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [center, setCenter] = useState({
    lat: 51.5072,
    lng: 0.1276,
  });
  useEffect(() => {
    dispatch(getAllGeoFenceList());
  }, []);
  const { geoFenceList, loading } = useSelector((state: any) => state.geofence);
  const [activeGeoFence, setActiveGeoFence] = useState<number>(-1);
  const [selectedInfo, setSelectedInfo] = useState({
    id: "",
    image: "",
    title: "",
    radius: "",
    latitude: "",
    longitude: "",
    description: "",
  });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD3WsIvzTCYUSVD5RojtqXwAOV8W-P8GPA",
  });
  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const handleClick = (e: any, index: number, item: any) => {
    setSelectedInfo({ ...selectedInfo, ...item });
    toggle();
  };
  const handleActive = (e: any, index: number, item: any) => {
    setActiveGeoFence(index);
    setCenter({
      lat: item.latitude,
      lng: item.longitude,
    });
  };
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Display GeoFence</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/geo-fence" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Display GeoFence
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={12} style={{ height: 500, overflow: "auto" }}>
              <h3 className="pb-3">GeoFence List</h3>
              {geoFenceList.length > 0 &&
                geoFenceList.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      {activeGeoFence == index ? (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className="border rounded p-2 mb-2 active-geo-fence"
                        >
                          <h6>{item.title}</h6>
                          <p className="p-0 m-0">
                            Latitude: {item.latitude}, Longitude:{" "}
                            {item.longitude}, Radius: {item.radius}
                          </p>
                        </div>
                      ) : (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className="border rounded p-2 mb-2"
                          onClick={e => {
                            handleActive(e, index, item);
                          }}
                        >
                          <h6>{item.title}</h6>
                          <p className="p-0 m-0">
                            Latitude: {item.latitude}, Longitude:{" "}
                            {item.longitude}, Radius: {item.radius}
                          </p>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </Col>
            <Col md={8} sm={12}>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={8}
                  onUnmount={onUnmount}
                >
                  {geoFenceList.length > 0 &&
                    geoFenceList.map((item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          <Marker
                            key={index}
                            clickable
                            position={{
                              lat: item.latitude,
                              lng: item.longitude,
                            }}
                            onClick={e => {
                              handleClick(e, index, item);
                            }}
                          />
                          <Circle
                            key={index}
                            center={{
                              lat: item.latitude,
                              lng: item.longitude,
                            }}
                            options={{
                              strokeColor: "#FF0000",
                              strokeOpacity: 0.6,
                              strokeWeight: 2,
                              fillColor: "#FF0000",
                              fillOpacity: 0.2,
                              clickable: false,
                              draggable: false,
                              editable: false,
                              visible: true,
                              radius: item.radius * 1000,
                              zIndex: 1,
                            }}
                          />
                        </Fragment>
                      );
                    })}
                </GoogleMap>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>GeoFence Detail</ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <div>
              <img className="round" src={selectedInfo.image} width={200} height={200} />
            </div>
            <div className="px-3">
              <h6>Title: {selectedInfo.title}</h6>
              <p className="py-1 m-0">Latitude: {selectedInfo.latitude}</p>
              <p className="m-0 p-0">Longitude: {selectedInfo.longitude}</p>
              <p className="m-0 p-0">Radius: {selectedInfo.radius}</p>
              <p className="m-0 p-0">Description: {selectedInfo.description}</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(DisplayGeoFence);
