import React, { useEffect } from "react";
import moment from "moment";
import {
  getFromFirebase,
  getAnalyticsCustomersVehicles,
} from "src/helpers/backend_helper";
import { IAnalytic } from "src/pages/Dashboard2/types";

const useAnalytics = () => {
  const [loading, setLoading] = React.useState(false);
  const [analytics, setAnalytics] = React.useState<Array<IAnalytic>>([]);

  const fetchAnalytics = async (from: string, to: string) => {
    setLoading(true);
    const analytics = await getFromFirebase(
      moment(from).format("YYYYMMDD"),
      moment(to).format("YYYYMMDD")
    );
    // sort by event_name
    setAnalytics(
      analytics
        .map((item: IAnalytic) => ({
          id: item.event_name,
          event_name: item.event_name.replace(/_/g, " ").toUpperCase(),
          count: item.count,
        }))
        .sort((a: IAnalytic, b: IAnalytic) =>
          a.event_name.localeCompare(b.event_name)
        )
    );
    // const customersVehicles = await getAnalyticsCustomersVehicles(duration);
    setLoading(false);
  };

  useEffect(() => {
    const from = moment().subtract(7, "days").toDate();
    const to = new Date();
    fetchAnalytics(
      moment(from).format("YYYYMMDD"),
      moment(to).format("YYYYMMDD")
    );
  }, []);

  return {
    loading,
    fetchAnalytics,
    analytics,
  };
};

export default useAnalytics;
