import moment from "moment";
import { storage } from "./firebase_config";


class ApiUtils {



    constructor(){

    }


    async uploadFile(file: any){
        const path = `/avatar/${file.name}`;
        const ref = storage.ref(path);
        
      
        await ref.put(file);
        const url = await ref.getDownloadURL();
        return url;
    }

    isExpired(date: any) {
        return moment(date).diff(moment(), 'd') <= 0;
    }
}


export default new ApiUtils();