import { useEffect, useRef, useState } from "react";
import {Dropdown,DropdownToggle,DropdownMenu} from "reactstrap";
import { IRole } from "src/interfaces/general";
import useRoles from "src/hooks/useRoles";
import { useTranslation } from "react-i18next";

interface IProps {
  singleAdminRole?:string;
  onChangeRole:(role: IRole) => void;
  method:string;
}

const AddRoleSelector = (props:IProps) => {
  
  const {t} = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ addTitle, setAddTitle ] = useState("");
  const [ editTitle, setEditTitle ] = useState(props?.singleAdminRole)
  const { roles, loading } = useRoles();
  const isChanged = useRef<boolean>(false);

  const onSelectRole = (role: IRole) =>{
    if (props.method == "add"){
      setAddTitle(role.name);
      isChanged.current = true;
    }

    if (props.method == "edit"){
      setEditTitle(role.name)
    }  
    
    props.onChangeRole(role);
    setIsOpen(false);
    
  };
  
  useEffect(()=>{
    if (!isChanged.current){
      setAddTitle(t("admin.selectRole"))
    }
  },[t]);

  useEffect(() => {
    setEditTitle(props?.singleAdminRole)
  }, [props?.singleAdminRole]);
      
  return (
            
    <Dropdown  toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}>

      <DropdownToggle type="button" className="btn btn-light" tag="label" >
        <div className="d-flex justify-content-between align-items-center">
        <div className="form-check-site d-flex align-items-center " > 
            <label className="form-check-label" htmlFor="formCheck2" style={{margin:0}} >
                {props.method == "add" ?addTitle : editTitle}
            </label>                
        </div>
            <i className="mdi mdi-chevron-down justify-content-end" ></i>
        </div>
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-md p-4 position-absolute" style={{width: '100%', height: 300, overflowY:'scroll'}}>
        <div >             
          {roles.map((item: IRole, index: number) => {
            
            return (
              <div key={index} >
                <label className="form-check-label" onClick={()=>onSelectRole(item)}>
                  <div className="d-flex align-items-center">
                    <h5>{item?.name} </h5>
                  </div>
                </label>
              </div>
            )
          })}
        </div>
      </DropdownMenu>

    </Dropdown>      
  );
};

export default AddRoleSelector;