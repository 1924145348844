import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Label,
  Input,
  Card,
  CardTitle,
  Spinner,
  FormGroup,
  CardBody,
  Container,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";
import { useSelector, useDispatch } from "react-redux";
import { getWarrantyByDocId, updateWarrantyByDocId } from "../../store/actions";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { storage } from "../../helpers/firebase_config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const WarrantySchema = Yup.object().shape({
  brand: Yup.string().required("The brand is required."),
  companyCode: Yup.string().required("Company Code is required."),
  warranty_duration: Yup.number().required(
    "The warranty duration is required."
  ),
  warranty_mileage: Yup.string().required("The warranty mileage is required."),
  warranty_pdf: Yup.string().required("The warranty PDF is required."),
  warranty_url: Yup.string().required("The warranty policy url is required."),
  recovery_duration: Yup.number().required(
    "The recovery duration is required."
  ),
  recovery_phone: Yup.string().required("The recovery phone is required."),
  recovery_pdf: Yup.string().required("The recovery PDF is required."),
  recovery_url: Yup.string().required("The recovery policy url is required."),
});

const EditGeoFence = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { docid } = useParams<any>();
  const { singleWarranty, loading } = useSelector(
    (state: any) => state.warranty
  );

  useEffect(() => {
    dispatch(getWarrantyByDocId(docid));
  }, []);
  const [warrantPDFUploading, setWarrantPDFUploading] = useState<any>(false);
  const [recoveryPDFUploading, setRecoveryPDFUploading] = useState<any>(false);
  const handleAcceptedWarrantyPDFFiles = async (
    setFieldValue: any,
    files: any
  ) => {
    setWarrantPDFUploading(true);
    const path = `/warrant_pdf/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setWarrantPDFUploading(false);
    setFieldValue("warranty_pdf", url);
  };

  const handleAcceptedRecoveryPDFFiles = async (
    setFieldValue: any,
    files: any
  ) => {
    setRecoveryPDFUploading(true);
    const path = `/recovery_pdf/${files[0].name}`;
    const ref = storage.ref(path);
    await ref.put(files[0]);
    const url = await ref.getDownloadURL();
    setRecoveryPDFUploading(false);
    setFieldValue("recovery_pdf", url);
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit GeoFence</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/warranty" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Warranty Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: WarrantyID: {singleWarranty?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Formik
            initialValues={{
              brand: singleWarranty?.brand || "",
              companyCode: singleWarranty?.companyCode || "",
              warranty_duration: singleWarranty?.warranty?.duration || "",
              warranty_mileage: singleWarranty?.warranty?.mileage || "",
              warranty_pdf: singleWarranty?.warranty?.pdf || "",
              warranty_url: singleWarranty?.warranty?.url || "",
              recovery_duration: singleWarranty?.recovery?.duration || "",
              recovery_phone: singleWarranty?.recovery?.phone || "",
              recovery_pdf: singleWarranty?.recovery?.pdf || "",
              recovery_url: singleWarranty?.recovery?.url || "",
            }}
            validationSchema={WarrantySchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              let data = {
                brand: values.brand,
                companyCode: values.companyCode,
                warranty: {
                  duration: values.warranty_duration,
                  mileage: values.warranty_mileage,
                  pdf: values.warranty_pdf,
                  url: values.warranty_url,
                },
                recovery: {
                  duration: values.recovery_duration,
                  phone: values.recovery_phone,
                  pdf: values.recovery_pdf,
                  url: values.recovery_url,
                },
              }
              dispatch(updateWarrantyByDocId(docid, data, history));
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="brand">Brand</Label>
                        <Field
                          name="brand"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.brand && touched.brand ? (
                          <div className="error-message">{errors.brand}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <FormGroup>
                        <Label for="companyCode">Company Code</Label>
                        <Field
                          name="companyCode"
                          render={({ field }: any) => (
                            <Input {...field} type="text" />
                          )}
                        />
                        {errors.companyCode && touched.companyCode ? (
                          <div className="error-message">{errors.companyCode}</div>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <CardTitle>Warranty</CardTitle>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <FormGroup>
                            <Label for="warranty_duration">
                              Duration (year)
                            </Label>
                            <Field
                              name="warranty_duration"
                              render={({ field }: any) => (
                                <Input {...field} type="number" />
                              )}
                            />
                            {errors.warranty_duration &&
                            touched.warranty_duration ? (
                              <div className="error-message">
                                {errors.warranty_duration}
                              </div>
                            ) : null}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3">
                          <FormGroup>
                            <Label for="warranty_mileage">
                              Mileage (km)
                            </Label>
                            <Field
                              name="warranty_mileage"
                              render={({ field }: any) => (
                                <Input {...field} type="number" />
                              )}
                            />
                            {errors.warranty_mileage &&
                            touched.warranty_mileage ? (
                              <div className="error-message">
                                {errors.warranty_mileage}
                              </div>
                            ) : null}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <CardTitle>PDF</CardTitle>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedWarrantyPDFFiles(
                              setFieldValue,
                              acceptedFiles
                            );
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h6>Drop files here or click to upload.</h6>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews" id="file-previews">
                          {warrantPDFUploading && (
                            <div className="d-flex justify-content-center">
                              <i
                                className="bx bx-loader bx-spin align-middle me-2"
                                style={{ fontSize: 46 }}
                              ></i>
                            </div>
                          )}
                          <div className="pt-3 pb-3">
                            <FormGroup>
                              <Label for="warranty_pdf">
                                Or Please enter PDF URL.
                              </Label>
                              <Field
                                name="warranty_pdf"
                                render={({ field }: any) => (
                                  <Input {...field} type="text" />
                                )}
                              />
                              {errors.warranty_pdf && touched.warranty_pdf ? (
                                <div className="error-message">
                                  {errors.warranty_pdf}
                                </div>
                              ) : null}
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <FormGroup>
                            <Label for="warranty_url">Policy URL</Label>
                            <Field
                              name="warranty_url"
                              render={({ field }: any) => (
                                <Input {...field} type="text" />
                              )}
                            />
                            {errors.warranty_url && touched.warranty_url ? (
                              <div className="error-message">
                                {errors.warranty_url}
                              </div>
                            ) : null}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CardTitle>Recovery</CardTitle>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <FormGroup>
                            <Label for="recovery_duration">
                              Duration (day)
                            </Label>
                            <Field
                              name="recovery_duration"
                              render={({ field }: any) => (
                                <Input {...field} type="number" />
                              )}
                            />
                            {errors.recovery_duration &&
                            touched.recovery_duration ? (
                              <div className="error-message">
                                {errors.recovery_duration}
                              </div>
                            ) : null}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <FormGroup>
                            <Label for="recovery_phone">Phone</Label>
                            <Field
                              name="recovery_phone"
                              render={({ field }: any) => (
                                <Input {...field} type="text" />
                              )}
                            />
                            {errors.recovery_phone && touched.recovery_phone ? (
                              <div className="error-message">
                                {errors.recovery_phone}
                              </div>
                            ) : null}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <CardTitle>PDF</CardTitle>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedRecoveryPDFFiles(
                              setFieldValue,
                              acceptedFiles
                            );
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h6>Drop files here or click to upload.</h6>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews" id="file-previews">
                          {recoveryPDFUploading && (
                            <div className="d-flex justify-content-center">
                              <i
                                className="bx bx-loader bx-spin align-middle me-2"
                                style={{ fontSize: 46 }}
                              ></i>
                            </div>
                          )}
                          <div className="pt-3 pb-3">
                            <FormGroup>
                              <Label for="recovery_pdf">
                                Or Please enter PDF URL.
                              </Label>
                              <Field
                                name="recovery_pdf"
                                render={({ field }: any) => (
                                  <Input {...field} type="text" />
                                )}
                              />
                              {errors.recovery_pdf && touched.recovery_pdf ? (
                                <div className="error-message">
                                  {errors.recovery_pdf}
                                </div>
                              ) : null}
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <FormGroup>
                            <Label for="recovery_url">Policy URL</Label>
                            <Field
                              name="recovery_url"
                              render={({ field }: any) => (
                                <Input {...field} type="text" />
                              )}
                            />
                            {errors.recovery_url && touched.recovery_url ? (
                              <div className="error-message">
                                {errors.recovery_url}
                              </div>
                            ) : null}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col className="pb-3">
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={loading}
                      >
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>{t("customers.Update")}</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditGeoFence);
