import { ThemeColors } from "src/constants/colors";
import styled from 'styled-components';
import { ITemplate } from "src/interfaces/general";
import Icon from "@ailibs/feather-react-ts";

interface Iprops {
    singleTemplate?: ITemplate
}

const NotifcifationTemplateModal = (props: Iprops) => {

    return (
        <ModalWrapper>
            <Content>
                <CusImg src={props.singleTemplate?.coverImage} />
                <Title>{props.singleTemplate?.subject}</Title>
                <Description>{props.singleTemplate?.body}</Description>
            </Content>
            {(props.singleTemplate?.buttons || []).length > 0 && <Bottom>
                {props.singleTemplate?.buttons.map((button, index) => (
                    <BottomButton key={index} index={index} >
                        <ButtonTitle>
                            {button.name}
                        </ButtonTitle>
                        {button?.icon != 'none' && button?.icon != null &&
                            <Icon size={16} name={button?.icon == 'calendar' ? 'calendar' : 'arrow-right'} />}
                    </BottomButton>
                ))}
            </Bottom>}
        </ModalWrapper>
    )
}

export default NotifcifationTemplateModal;

const ModalWrapper = styled.div`
    width: 300px;
    height: 455px;
    border-radius: 20px;
    border-color: ${ThemeColors.main};
    border: solid;
    border-width: 1px;
    position: relative;
    background: ${ThemeColors.white};
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`

const Content = styled.div`
    margin: 30px;
    flex: 1;
`

const CusImg = styled.img`
    width: 100%;
    height: 190px;
    border-radius: 10px;
    object-fit: cover;
    background: ${ThemeColors.lightGrey};
    border: solid 1px grey;
`

const Title = styled.div`
    color: black;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
`

const Description = styled.div`
    color: black;
    font-size: 12px;
    margin-top: 10px;
    white-space: break-spaces;
`

const Bottom = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
    border:solid;
    border-width: 1px 0 0 0;
    position: absolute;
    bottom: 0px;
`

const BottomButton = styled.div<{ index: number }>`
    display:flex;
    width:100%;
    height:50px;
    padding: 10px;
    border:solid;
    border-width:0 0 0 ${({ index }) => index === 1 ? 1 : 0}px ;
    align-items: center;   
`

const ButtonTitle = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: black;
    flex: 1;
    text-align: center;
`
