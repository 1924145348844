import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { VehicleTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  getAllVehiclesSuccess,
  getAllVehiclesFail,
  addVehicleSuccess,
  addVehicleFail,
  getVehicleByDocIdSuccess,
  getVehicleByDocIdFail,
  deleteVehicleByIdSuccess
} from "./actions";

import {
  getAllVehiclesAsync,
  addVehicleAsync,
  getVehicleByDocIdAsync,
  deleteVehicleByIdAsync
} from "../../helpers/backend_helper";

function* getAllVehicles() {
  try {
    const response: IResponse = yield call(getAllVehiclesAsync);
    if(response.success) {
      yield put(getAllVehiclesSuccess(response.result));
    }
  } catch (error) {
    yield put(getAllVehiclesFail(error));
  }
}

function* addVehicle({ payload: vehicle }: any) {
  try {
    const response: IResponse = yield call(addVehicleAsync, vehicle);
    if(response.success) {
      yield put(addVehicleSuccess(response.result));
    }
  } catch (error) {
    yield put(addVehicleFail(error));
  }
}

function* getVehicleByDocId({ payload: docid}: any) {
  try {
    const response: IResponse = yield call(getVehicleByDocIdAsync, docid);
    if(response.success) {
      yield put(getVehicleByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getVehicleByDocIdFail(error));
  }
}

function* deleteVehicleById({ payload: docid}: any) {
  try {
    const response: IResponse = yield call(deleteVehicleByIdAsync, docid);
    yield put(deleteVehicleByIdSuccess(response.result));    
  } catch(error) {
    console.log(error)
  }
}

// function* deleteCompany({ payload: code }: any) {
//   try {
//     const response: IResponse = yield call(deleteCompanyAsync, code);
//     yield put(deleteCompanySuccess(response.result));
//   } catch (error) {
//     yield put(deleteCompanyFail(error));
//   }
// }

// function* updateSiteByDocId({ payload: {docid, site} }: any) {
//   try {
//     const response: IResponse = yield call(updateSiteByDocIdAsync, docid, site);
//     console.log('response =>', response)
//     if(response.success) {
//       yield put(updateSiteByDocIdSuccess(response.result));
//     }
//   } catch (error) {
//     yield put(updateSiteByDocIdFail(error));
//   }
// }

function* vehicleSaga() {
  yield takeEvery(VehicleTypes.GET_ALL_VEHICLES, getAllVehicles)
  yield takeEvery(VehicleTypes.ADD_VEHICLE, addVehicle)
  yield takeEvery(VehicleTypes.GET_VEHICLE_BY_DOCID, getVehicleByDocId)
  yield takeEvery(VehicleTypes.DELETE_VEHICLE_BYID, deleteVehicleById)

  // yield takeEvery(CoCompanyTypesmpanyTypes.DELETE_COMPANY, deleteCompany),
  // yield takeEvery(VehicleTypes.UPDATE_SITE_BY_DOCID, updateSiteByDocId);
}

export default vehicleSaga;


