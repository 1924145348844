import { BookingTypes } from "./actionTypes";

export const INIT_STATE: any = {
  bookings: [],
  signleBooking: {},
  error: {},
  success: false,
  loading: false,
  confirm_del: false,
};

const booking = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE:
      return {
        ...state,
        loading: true,
      };

    case BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload,
      };

    case BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BookingTypes.GET_ALL_BOOKINGS:
      return {
        ...state,
        loading: true,
      };

    case BookingTypes.GET_ALL_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload,
      };

    case BookingTypes.GET_ALL_BOOKINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case BookingTypes.GET_BOOKINGS_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case BookingTypes.GET_BOOKINGS_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload,
      };

    case BookingTypes.GET_BOOKINGS_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BookingTypes.ADD_BOOKING:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case BookingTypes.ADD_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        bookings: [...state.bookings, action.payload],
      };

    case BookingTypes.ADD_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case BookingTypes.GET_BOOKING_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case BookingTypes.GET_BOOKING_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        signleBooking: action.payload,
      };

    case BookingTypes.GET_BOOKING_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BookingTypes.DELETE_BOOKING_BYID:
      return {
        ...state,
        loading: true,
      };

    case BookingTypes.DELETE_BOOKING_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: state.bookings.filter(
          (booking: any) => booking.id !== action.payload
        ),
      };

    // case BookingTypes.UPDATE_SITE_BY_DOCID:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case BookingTypes.UPDATE_SITE_BY_DOCID_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     signleSite: action.payload,
    //   };

    // case BookingTypes.UPDATE_SITE_BY_DOCID_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    // case BookingTypes.DELETE_COMPANY:
    //   return {
    //     ...state,
    //     confirm_del: false,
    //     loading: true,
    //   };

    // case BookingTypes.DELETE_COMPANY_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     confirm_del: true,
    //     companies: state.companies.filter(
    //       (item: any) => item.code !== action.payload
    //     ),
    //   };

    // case BookingTypes.CONFIRM_DELETE:
    //   return {
    //     ...state,
    //     loading: false,
    //     confirm_del: false,
    //   };

    // case BookingTypes.UPDATE_COMPANY:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case BookingTypes.UPDATE_COMPANY_SUCCESS:

    //   return {
    //     ...state,
    //     loading: false,
    //     companies: [
    //       ...state.companies.filter((item: any) => item.code === action.payload.code),
    //       ...action.payload
    //     ]
    //   };

    // case BookingTypes.UPDATE_COMPANY_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload
    //   };
    default:
      return state;
  }
};

export default booking;
