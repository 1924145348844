import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SpinnerCircular } from "spinners-react";
import { IAdmin, IGroup } from "src/interfaces/general";
import "react-datepicker/dist/react-datepicker.css";
import ManagerSelect from "./managerSelect";
import StaffList from "./StaffList";
import { useSelector } from "react-redux";

const AddGroup = (props: any) => {

  const { currentUser } = useSelector((state: any) => state.login);
  const [ members, setMembers ] = useState<Array<IAdmin>>([]);
  const [ manager, setManager ] = useState<string>("");

  const onAddStaff = (admin:IAdmin) => {
    if (members.findIndex(item=>item.id == admin.id)>=0) return;
    members.push(admin);
    setMembers([...members]);    
  };

  const onRemoveStaff = (admin: IAdmin) => {
    const index = members.findIndex(item=>item.id == admin.id);
    members.splice(index, 1);
    setMembers([...members]);
  };

  const onSelectManager = ( admin: IAdmin) => {
    setManager(admin.id);    
  };
  
  const handleAddGroup = (values: IGroup) => { 
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      values.companyCode = currentUser.code;
    } else {
      values.companyCode = currentUser.companyCode;
    }   
    const staff:Array<string> =[]
    members.map((member) => {
      staff.push(member.id);
    })
    values.staff = staff;
    values.manager = manager;
    props.addGroup(values);       
  };

  
  useEffect(() => {
    if(props.success) {
      props.onDrawerClose(false)
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Group</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-company-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddGroup(values);
            }}
          >            
            <Row>                          
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  />
                </div>
              </Col>
            
              <Col md="12">
                <div className="mb-3">
                  <label> Choose Manager </label>
                  <ManagerSelect
                    onSelectManager={onSelectManager}
                  />
                </div>
              </Col>
            
              <Col md="12">
                <div className="mb-3">
                  <label> Staff List </label>            
                  <StaffList
                    onAddStaff={onAddStaff}
                    onRemoveStaff={onRemoveStaff}
                    members={members}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button 
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Save</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddGroup);
