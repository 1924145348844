import 'react-rangeslider/lib/index.css';
import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { storage } from 'src/helpers/firebase_config';

interface IProps {
    placeholder?: string;
    value?: string;
    onChange?: (url: string) => void;
    uploadingStatus?: (uploading: boolean) => void;
    style?: CSSProperties;
}

const ImagePicker = (props: IProps) => {
    const [image, setImage] = useState(props.value);
    const input_ref = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = async (e: any) => {
        if (e.target?.files && e.target.files.length > 0) {
            props.uploadingStatus?.(true);
            try {
                const localUrl = URL.createObjectURL(e.target.files[0]);
                setLoading(true);
                setImage(localUrl);

                const ref = storage.ref("/images/" + e.target.files[0].name);
                await ref.put(e.target.files[0]);
                const url = await ref.getDownloadURL();
                setLoading(false);
                props.onChange?.(url);
                setImage(url);
                console.log(url);
            } catch (error) {
                console.log(error);
            }
            props.uploadingStatus?.(false);
        }
    };

    const onOpen = () => {
        input_ref.current?.click();
    }

    useEffect(() => {
        setImage(props.value);
    }, [props.value]);

    return (
        <ImageContainer style={props.style}>
            <Image src={image} onClick={onOpen} />
            <Placeholder
                onClick={onOpen}
                hidden={image != null && !loading}
            >
                {loading ? "Uploading..." : props.placeholder || "Click here"}
            </Placeholder>
            <input
                ref={input_ref}
                type="file"
                onChange={handleFileChange}
                hidden
            />
        </ImageContainer>
    );
};

export default ImagePicker;

const ImageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
    position: relative;
    align-items: center;
    justify-content: center;
`

const Image = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgb(200, 200, 200);
    z-index: 1;
    cursor: pointer;
    object-fit: cover;
`

const Placeholder = styled.div`
    font-size: 24px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    cursor: pointer;
`