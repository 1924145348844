import React from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col } from "reactstrap";
import loginlogo from "../../assets/images/loginlogo.png";
import styled from "styled-components";

const Unauthorized = () => {
    return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Page Not Found</title>
                </MetaTags>
        <Container fluid>
          <Row>
            <Col md="12" className="text-center">
            <LogoImg className="avatar-edit" src={loginlogo} />
            <h1>404; PAGE NOT FOUND</h1>
            <p>Sorry, the page you are looking for can't be found.</p>
            <p><a href="/dashboard">Return Home</a></p>
            </Col>
          </Row>
</Container>
</div>
        </React.Fragment>
    );
};
 
export default Unauthorized;

const LogoImg = styled.img`
  width: 300px;
  margin-bottom: 60px;
`