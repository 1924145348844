import { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import useAdmins from "src/hooks/useAdmins";
import { IAdmin } from "src/interfaces/general";
import styled from 'styled-components';

interface IProps {
    onSelect:(admin:IAdmin) =>  void;
    excludes?: {[id:string]:boolean}
}
const AddStaffButton = (props:IProps)=>{

    const [open, setOpen] = useState(false);
    const { currentUser } = useSelector((state: any) => state.login);
    const { admins, loading } = useAdmins(currentUser?.code||currentUser?.companyCode);

    const onSelect = (data:IAdmin)=>{
        props.onSelect(data);
        setOpen(false);
    }

    const rest = admins.filter(admin=>props.excludes?.[admin.id]!=true);

    return rest.length>0?(
        <Container>
            <Dropdown toggle={() => setOpen(!open)} isOpen={open}>
            <DropdownToggle>
                <span className='fa fa-plus'/>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-md p-4 position-absolute">
                {loading && <i className="bx bx-loader bx-spin align-middle me-2"/>}
                {
                    rest.map((admin, index)=>(
                        <StaffItem key={index} onClick={()=>onSelect(admin)}>
                            <StaffAvatar src={admin.avatar}/>
                            <div>
                                <StaffName>{admin.userName}</StaffName>
                            </div>
                        </StaffItem>
                    ))
                }
            </DropdownMenu>
            </Dropdown>
        </Container>
    ):null;
}

export default AddStaffButton;

const Container = styled.div`    
    margin: 10px;
`

const StaffItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: grey;
    border-radius: 5px;
    margin: 2px;
    padding: 10px;
`

const StaffAvatar = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    object-fit: cover;
`

const StaffName = styled.div`
    font-size: 12px;
    color: white;
    margin-left: 15px;
`