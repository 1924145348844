import { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import AddMake from "./AddMake";
import { useTranslation } from "react-i18next";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import { Tag } from "src/components/Common/SimpleComponents";
import useMakes from "src/hooks/useMakes";
import usePermission from "src/hooks/usePermission";
import { IMake } from "src/interfaces/general";

const MakeList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [drawer, setDrawer] = useState<boolean>(false);
  const { makes, fetchMakes, deleteMake, updateMake, addMake, loading, success } = useMakes();
  const [make, setMake] = useState<IMake>({ name: "", models: [] }); // [TODO
  const makeRef = useRef<IMake>();

  const makePermission = usePermission('make');

  const defaultSorted: any = [
    {
      dataField: "code",
      order: "desc",
    },
  ];

  const onEdit = (make: IMake) => {
    setMake(make);
    setDrawer(true);
  };

  const onDelete = (make: IMake) => {
    makeRef.current = make;
    setShowConfirmAlert(true);
  };

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    if (makeRef.current?.id) {
      deleteMake(makeRef.current?.id);
    }
  };

  const onAdd = () => {
    setMake({ name: "", models: [] });
    setDrawer(true);
  };

  const onDrawerClose = () => {
    setDrawer(false);
  };

  useEffect(() => {
    fetchMakes();
  }, []);

  return (
    <TablePage
      loading={loading}
      metaTitle={t("Make List")}
      data={makes}
      onAdd={onAdd}
      hasPermission={makePermission.write || true}
      title={t("Make List")}
      description={`(${makes.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={makes.length}
      defaultSorted={defaultSorted}
      footer={
        <AddMake
          open={drawer}
          onClose={onDrawerClose}
          onUpdateMake={updateMake}
          make={make}
          onAddMake={addMake}
          loading={loading}
          success={success}
        />
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(MakeList);

const header = [
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "models",
    text: "Models",
    formatter: (_: any, make: IMake) => (
      make.models?.map((model, index) => (
        <Tag key={index} type="role"><span>{model.name.toUpperCase()}</span></Tag>
      ))
    ),
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
