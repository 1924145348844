import Slider from "react-rangeslider";
import 'react-rangeslider/lib/index.css';
import { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import { storage } from "src/helpers/firebase_config";

interface IProps{  
    onChange:(url:string)=>void;
    title:string;
}

const AddAvatar = (props:IProps) => {  

  const {t} = useTranslation();
  const [ editor, setEditor] = useState<any>()
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: "",
    zoom: 2,
    croppedImg: "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png"
  });

  const [filename, setFilename] = useState("");

  const handleSlider = (value:any) => {
    setPicture({
      ...picture,
      zoom: value
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false
    });
  };

  const setEditorRef = (ed:any) => {
    setEditor(ed)
  };

  const handleSave = async (e:any) => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      setPicture({
        ...picture,
        img: "",
        cropperOpen: false,
        croppedImg: croppedImg
      });

      const storageRef = storage.ref();
      const filesRef = storageRef.child('images/' + filename + '.png');
      await filesRef.putString(croppedImg, 'data_url')
        .then((snapshot) => {
          console.log('Uploaded a data_url string!')
        }).catch((error) => {
          console.log("error")
        });
      const newURL = await filesRef.getDownloadURL();  
      props.onChange(newURL);
    }
  };

  const handleFileChange = (e:any) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true
    });
  }; 
    
  return (    
    <div className="d-flex justify-content-between" >
      <div className="d-flex flex-column"style={{width:"50%"}} >
        <div>
          <h6>{t("avatar."+props.title)}</h6>            
          <img 
            className="rounded-circle avatar-edit "
            src={picture.croppedImg}
          />
        </div>
        <button
          type="submit"
          className="btn btn-success save-user mb-3 mt-3"            
          style={{height:45}}
        >
          <input type="file"  onChange={handleFileChange} />
        </button>
      </div>

      {picture.cropperOpen && (        
        <div className="mt-4 mb-3">
          <AvatarEditor
            ref={setEditorRef}
            image={picture.img}
            width={100}
            height={100}
            border={25}
            color={[255, 255, 255, 0.6]} 
            rotate={0}
            scale={picture.zoom}
          />
          <div className="p-3" style={{width:"100%"}}>
            <Slider
              aria-label="raceSlider"
              value={picture.zoom}
              min={1}
              max={10}
              step={0.1}
              onChange={handleSlider}
            />
          </div>
          <div className=" d-flex justify-content-between mb-3 mt-1" >
            <button 
              type="button"
              className="btn btn-secondary "
              data-dismiss="modal"
              onClick={handleCancel}
            >
              {t("avatar.close")}
            </button>
            <button 
              type="button" 
              className="btn btn-primary " 
              onClick={handleSave}
            >
              {t("avatar.save_changes")}
            </button>
          </div>
        </div>
      )}
    </div>    
  );
};

export default AddAvatar;

