import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Dropdown,DropdownToggle,DropdownMenu,DropdownItem,} from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useHistory } from "react-router-dom";
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { useSelector } from "react-redux";

const ProfileMenu = (props: any) => {
  let history = useHistory()
  const [menu, setMenu] = useState<boolean>(false);
  const [username, setusername] = useState("");
  const { currentUser } = useSelector((state: any) => state.login)
  const { authSite } = useSelector((state: any) => state.site)

  // useEffect(() => {
  //   const getAuthUser = sessionStorage.getItem("authUser");
  //   if (getAuthUser) {
  //     const obj = JSON.parse(getAuthUser);
  //     setusername(obj.name);
  //   }
  // }, []);

  const onSignOut = () => {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('authUser')
    history.push('/login')
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          {currentUser?.LOGIN_METHOD === "ADMIN_LOGIN" && (
            <> 
              <img
                className="rounded-circle header-profile-user"
                src={currentUser?.avatar}
                alt=""
              />
              <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.userName}</span>
            </>
          )}

           {currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" && (
            <> 
              <img
                className="rounded-circle header-profile-user"
                src={currentUser?.logo}
                alt=""
              />
              <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.name}</span>
            </>
          )}

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          <DropdownItem tag="a" href="/#">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="" className="dropdown-item" onClick={() => onSignOut()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(ProfileMenu));
