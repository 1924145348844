import { useState, useEffect } from "react";
import { addAdminAsync, addAdminsAsync, deleteAdminByDocIdAsyc, getAdminByDocIdAsync, getAdminsByCompanyCodeAsync, getScoresByCompanyCodeAsynce, updateAdminByDocIdAsync } from "src/helpers/backend_helper";
import { IAdmin } from "src/interfaces/general";

const useAdmins = (companyCode?: string) => {

    const [admins, setAdmins] = useState<Array<IAdmin>>([]);
    const [scores, setScores] = useState<Array<IAdmin>>([])
    const [singleAdmin, setSingleAdmin] = useState<IAdmin>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorAlert, setErrorAlert] = useState(true);
    const [errorMessage, setErrorMessage] = useState('')

    const fetchAdmins = () => {
        setLoading(true);
        if (companyCode) {
            getAdminsByCompanyCodeAsync(companyCode)
            .then(res=>{
                if(res.success){
                    setAdmins(res.result||[]);
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    };

    const fetchScores = () => {
        setLoading(true);
        if (companyCode) {
            getScoresByCompanyCodeAsynce(companyCode)
            .then(res=>{
                if(res.success){
                    setScores(res.result||[]);
                }
            }).catch(console.log).finally(()=>setLoading(false))
        }
    }

    const fetchAdminById = (id: string) => {
        setLoading(true);
        if (id){
            getAdminByDocIdAsync(id)
            .then(res=>{
                if(res.result){
                    setSingleAdmin(res.result||{});
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    }

    const addAdmin = async (admin:IAdmin) => {
        try{
            setLoading(true);
            setErrorAlert(true);
            setErrorMessage("");
            const result = await addAdminAsync(admin);
            if (result.success){
                setAdmins([...admins, result.result]);
                setLoading(false);
                setErrorMessage('');
                setSuccess(true);
                return true;
            } else {
                setLoading(false);
                setSuccess(false);
                setErrorMessage(result.message);
                setErrorAlert(false);
            }
        }catch(error){
            console.log("admin add error", error)
        }
        setLoading(false);
        setSuccess(false);
        setErrorAlert(false);
        return false;
    }

    const addAdmins = async (data:Array<any>) => {
        try{
            setLoading(true);
            const result = await addAdminsAsync({data});
            if (result.success){
                setAdmins([...admins, ...result.result]);
                setLoading(false);
                setSuccess(true)
                return true;
            }
        }catch(error){
            console.log("admin add error", error)
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    const deleteAdmin = async (id: string) => {
        const index = admins.findIndex(admin=>admin.id == id)
        try{
            setLoading(true);
            const result = await deleteAdminByDocIdAsyc(id);
            if(result.success){
                admins.splice(index, 1);
                setAdmins([...admins]);
                setLoading(false);
                return true;
            }
        }catch(error){
            console.log("admin delete error", error);
        }
        setLoading(false);
        return false;
    }

    const updateAdmin = async (id: string, admin: IAdmin) => {
        try{
            setLoading(true);
            const result = await updateAdminByDocIdAsync(id, admin);
            if (result.success){
                admins.push(admin);
                setAdmins([...admins]);
                setLoading(false);
                setSuccess(true);
                return true;
            }
        }catch(error){
            console.log("admin update error", error);
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    const changeAdmin = (admin:IAdmin)=>{
        setSingleAdmin(admin);
    }

    useEffect (()=> {
        fetchAdmins()
        fetchScores()
    },[companyCode]);

    return {
        admins,
        scores,
        singleAdmin,
        loading,
        success,
        errorAlert,
        errorMessage,
        addAdmin,
        fetchAdminById,
        deleteAdmin,
        updateAdmin,
        changeAdmin,
        addAdmins
    };
}

export default useAdmins;