import { useEffect, useRef, useState } from "react";
import { Dropdown,DropdownToggle,DropdownMenu } from "reactstrap";
import { useSelector } from "react-redux";
import { IAdmin } from "src/interfaces/general";
import { useTranslation } from "react-i18next";
import useAdmins from "src/hooks/useAdmins";
import styled from "styled-components";

interface IProps {
    onSelectManager:(admin: any) => void;
}

const ManagerSelect = (props: IProps) => {
  
  const {t} = useTranslation();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ avatar, setAvatar ] = useState("");
  const [ title, setTitle ] = useState("");  
  const { currentUser } = useSelector((state: any) => state.login);
  const { admins, loading } = useAdmins(currentUser?.code||currentUser?.companyCode);
  const isChanged = useRef<boolean>(false);

  const onSelectAdmin = (admin: IAdmin) =>{
    setTitle(admin.userName);
    setAvatar(admin.avatar);
    props.onSelectManager(admin);
    setIsOpen(false);
    isChanged.current = true;
  };

  useEffect(()=>{
    if (!isChanged.current){
      setTitle(t("Choose Manager"))
    }
  },[t]);
      
  return (           
    <Dropdown  toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}>
      <DropdownToggle type="button" className="btn btn-light" tag="label" >
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-check-site d-flex align-items-center " >
            {avatar&&<StaffAvatar src={avatar} />}                
            <label className="form-check-label " htmlFor="formCheck2" style={{margin:0}} >
              {title}
            </label>                
          </div>
          <i className="mdi mdi-chevron-down justify-content-end" ></i>
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-md p-4 position-absolute" style={{width: '100%'}}>
      <div className="site-list-for-service">
        {loading && (
        <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 24}}></i>  
        )}            
        {admins.map((item: IAdmin, index: number) => {            
          return (
            <div key={index} >
              <label className="form-check-label" onClick={()=>onSelectAdmin(item)}>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <StaffAvatar src={item?.avatar} />
                  </div>
                  <h5>{item?.userName}</h5>
                </div>
              </label>
            </div>
          )
        })}
      </div>
      </DropdownMenu>
    </Dropdown>      
  );
};

export default ManagerSelect;

const StaffAvatar = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    border: solid 1px green;
    margin-right: 10px;
`