import { BookingToolTypes } from "./actionTypes";

export const INIT_STATE: any = {
  bookingToolSettings: [],
  signleBookingToolSetting: null,
  error: {},
  success: false,
  loading: false,
};

const bookingTool = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case BookingToolTypes.SAVE_BOOKING_TOOL_DATA:
      return {
        ...state,
        loading: true,
      };

    case BookingToolTypes.SAVE_BOOKING_TOOL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        signleBookingToolSetting: action.payload,
      };

    case BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE:
      return {
        ...state,
        loading: true,
      };

    case BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        signleBookingToolSetting: action.payload,
      };

    case BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE_FAIL:
      return {
        ...state,
        loading: false,
        signleBookingToolSetting: action.payload,
      };

    default:
      return state;
  }
};

export default bookingTool;
