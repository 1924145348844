import styled from "styled-components";
import { ICustomer } from "src/interfaces/general";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCustomers from "src/hooks/useCustomers";
import { Input } from "reactstrap";

interface IProps {
    onSelectCustomer: (customer: ICustomer) => void;
    onFilterChange?: (filter: string) => void;
    numberOfLines?: number;
}
const CustomerList = (props: IProps) => {
    const { currentUser } = useSelector((state: any) => state.login);
    const { customers, loading } = useCustomers(currentUser?.code || currentUser?.companyCode);
    const [filter, setFilter] = useState("");
    const [focused, setFocused] = useState(false);

    const onChangeFilter = (e: any) => {
        props.onFilterChange?.(e.target.value);
        setFilter(e.target.value);
    };

    const onSelect = (item: ICustomer) => {
        setFilter(item.phone);
        props.onFilterChange?.(item.phone);
        props.onSelectCustomer(item);
        setFocused(false);
    };

    useEffect(() => {
        document.addEventListener("click", (e) => {
            // @ts-ignore
            if (e.target && e.target.id !== "customer-input") {
                setFocused(false);
            }
        });
    }, []);

    return (
        <Container>
            <Header>
                <Input
                    id="customer-input"
                    className="customer-input"
                    placeholder="Search..."
                    onChange={onChangeFilter}
                    value={filter}
                    onFocus={() => setFocused(true)}
                // onBlur={() => setFocused(false)}
                />
            </Header>
            <List className="customer-list">
                {focused && customers
                    .filter(
                        customer =>
                            customer.phone?.toLowerCase().includes(filter) ||
                            customer.firstName?.toLowerCase().includes(filter) ||
                            customer.lastName?.toLowerCase().includes(filter) ||
                            customer.siteCode?.toLowerCase().includes(filter)
                    )
                    .map((customer, index) => index < (props.numberOfLines || 5) && (
                        <ItemContainer key={index} onClick={() => onSelect(customer)}>
                            <Avatar src={customer.avatar} />
                            <ItemBody>
                                <Phone>{customer.phone}</Phone>
                                <Name>
                                    {customer.firstName} {customer.lastName}
                                </Name>
                            </ItemBody>
                        </ItemContainer>
                    ))}
            </List>
        </Container>
    );
};

export default CustomerList;

const Container = styled.div`
    position: relative;
`;

const List = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  border-bottom: solid 1px rgb(220, 220, 230);
  cursor: pointer;
  
    &:hover {
        background-color: rgb(240, 240, 250);
    }
`;

const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  object-fit: cover;
  margin-left: 10px;
  background-color: rgb(200, 200, 200);
`;

const ItemBody = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  max-width: 150px;
`;

const Phone = styled.div`
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Name = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Header = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;    
  height: 56px;
`;
