import { useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//redux
import AddGroup from "./add-group";
import { useTranslation } from "react-i18next";
import { IGroup } from "src/interfaces/general";
import useGroups from "src/hooks/useGroups";
import { useSelector } from "react-redux";
import TablePage, { makeColumns } from "src/components/Common/TablePage";

const Groups = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const { groups, loading, deleteGroup, success, addGroup } = useGroups(currentUser?.code||currentUser?.companyCode);  
  const currentGroup = useRef<IGroup>();
  const hasPermission = true;

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];
  
  const onEdit = (group: any) => {
    history.push(
      `/groups/${group.id}`
    );
  };

  const onDelete = (group: IGroup) => {
    currentGroup.current = group;
    setShowConfirmAlert(true);
  };

  const confirmDelete = async() => {
    setShowConfirmAlert(false);    
    const result = await deleteGroup(currentGroup.current?.id||"");
    if (result){
      setShowSuccessAlert(true);
    }
  };

  const onAdd = () => {
    setDrawer(true);
  };
  
  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };
  
  return (
    <TablePage
      loading={loading}
      metaTitle={t("Groups")}
      data={groups}
      onAdd={onAdd}
      hasPermission={hasPermission}
      title={t("groups.title")}
      description={`(${groups.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={groups.length}
      defaultSorted={defaultSorted}
      footer = {
        <AddGroup
          open={drawer}
          onDrawerClose={onDrawerClose}
          addGroup={addGroup}
          loading={loading}
          success={success}
        />
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />    
  );
};

export default withRouter(Groups);

const header = [
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "managerName",
    text: "Manager Name",
    sort: true,
  },
  {
    dataField: "staff",
    text: "Amount of Staff",
    formatter: (_:any, group: any) => (
      group.staff.length
    )
  }, 
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
