import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Search } from "react-bootstrap-table2-toolkit";
import Icon from "@ailibs/feather-react-ts";

interface IProps {
  title: string;
  description: string;
  hasPermission: boolean;
  onAdd?: () => void;
  onImport?: () => void;
  searchProps: any;
  addtional?: React.ReactNode;
}

const TableHeader = (props: IProps) => {
  const { t } = useTranslation();
  const { SearchBar } = Search;
  return (
    <Row className="mb-2">
      <div className="row align-ite  ms-center">
        <div className="col-md-6">
          <div className="mb-3">
            <h5 className="card-title">
              {props.title}
              <span className="text-muted fw-normal ms-2">
                {props.description}
              </span>
            </h5>
          </div>
        </div>

        <div className="col-md-6 d-flex justify-content-end">
          {props.onImport ? (
            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3 me-3">
              {props.hasPermission && (
                <Link to="#" className="btn btn-light" onClick={props.onImport}>
                  <Icon name="upload" size={14} className="bx bx-plus me-1" />{" "}
                  {t("header.import")}
                </Link>
              )}
            </div>
          ) : null}
          <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
            {props.title === "GeoFence List" && (
              <Link to="/geo-fence/display" className="btn btn-light">
                Display geofence
              </Link>
            )}
            {props.hasPermission && (
              <>
                {props.title === "Products List" ? (
                  <></>
                ) : props.onAdd ? (
                  <div className="btn btn-light" onClick={props.onAdd}>
                    <i className="bx bx-plus me-1"></i> {t("header.addNew")}
                  </div>
                ) : null}
              </>
            )}
            {props.addtional}
          </div>
        </div>
      </div>
      <Col sm="4">
        <div className="search-box ms-2 mb-2 d-inline-block">
          <div className="position-relative">
            <SearchBar placeholder={t("search")} {...props.searchProps} />
            <i className="bx bx-search-alt search-icon-search" />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TableHeader;
