/**
 *  interface for Login Type
*/
export enum LoginTypes {
    LOGIN_COMPANY_ADMIN = 'LOGIN_COMPANY_ADMIN',
    LOGIN_STAFF_ADMIN = 'LOGIN_STAFF_ADMIN',
    LOGIN_MFA = 'LOGIN_MFA',
    CANCEL_MFA = 'CANCEL_MFA',
    RESEND_MFA = 'RESEND_MFA',
    LOGIN_MFA_REQUIRED = 'LOGIN_MFA_REQUIRED',
/*     LOGIN_MFA_SUCCESS = '@@login/LOGIN_MFA_SUCCESS',
    LOGIN_MFA_FAILED = 'LOGIN_MFA_FAIL', */
    LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS',
    LOGIN_FAIL = 'LOGIN_FAIL',
    SET_AUTH_USER = 'SET_AUTH_USER',
    LOGOUT_USER = '@@login/LOGOUT_USER',
    LOGOUT_USER_SUCCESS = '@@login/LOGOUT_USER_SUCCESS',
    API_ERROR = '@@login/API_ERROR',
}
