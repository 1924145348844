import { ProductTypes } from "./actionTypes";

export const getAllProductsBySiteCode = (siteCode: string) => ({
  type: ProductTypes.GET_PRODUCTS_BY_SITECODE,
  payload: siteCode,
});

export const getProductByDocIdSuccess = (product: any) => ({
  type: ProductTypes.GET_PRODUCT_BY_DOCID_SUCCESS,
  payload: product,
});

export const getProductByDocIdFail = (error: any) => ({
  type: ProductTypes.GET_PRODUCT_BY_DOCID_FAIL,
  payload: error,
});

export const getAllProducts = () => ({
  type: ProductTypes.GET_ALL_PRODUCTS,
});

export const getProductByDocId = (docid: string) => ({
  type: ProductTypes.GET_PRODUCT_BY_DOCID,
  payload: docid,
});

export const getProductsBySiteCodeSuccess = (products: any) => ({
  type: ProductTypes.GET_PRODUCTS_BY_SITECODE_SUCCESS,
  payload: products,
});

export const getProductsBySiteCodeFail = (error: any) => ({
  type: ProductTypes.GET_PRODUCTS_BY_SITECODE_FAIL,
  payload: error,
});

export const addProduct = (product: any) => ({
  type: ProductTypes.ADD_PRODUCT,
  payload: product,
});

export const addProductSuccess = (product: any) => ({
  type: ProductTypes.ADD_PRODUCT_SUCCESS,
  payload: product,
});

export const addProductFail = (error: any) => ({
  type: ProductTypes.ADD_PRODUCT_FAIL,
  payload: error,
});

export const deleteProductById = (docid: string) => ({
  type: ProductTypes.DELETE_PRODUCT_BYID,
  payload: docid,
});

export const deleteProductByDocIdSuccess = (docid: any) => ({
  type: ProductTypes.DELETE_PRODUCT_BYID_SUCCESS,
  payload: docid,
});

export const deleteProductByDocIdFail = (error: any) => ({
  type: ProductTypes.DELETE_PRODUCT_BYID_FAIL,
  payload: error,
});

export const updateProduct = (docid: string, product: any, history: any) => ({
  type: ProductTypes.UPDATE_PRODUCT_BY_DOCID,
  payload: { docid, product, history },
});

export const updateProductByDocIdSuccess = (product: any) => ({
  type: ProductTypes.UPDATE_PRODUCT_BY_DOCID_SUCCESS,
  payload: product,
});

export const updateProductByDocIdFail = (error: any) => ({
  type: ProductTypes.UPDATE_PRODUCT_BY_DOCID_FAIL,
  payload: error,
});

//was product
export const getAllWasProducts = (data: any) => ({
  type: ProductTypes.GET_ALL_WAS_PRODUCTS,
  payload: data,
});

export const setCurrentWasProductInfo = (DealerProductId : number, MonthlyDuration: number, siteCode: string, siteName: string, wasDealerId: string) => ({
  type: ProductTypes.SET_CURRENT_WAS_PRODUCT_INFO,
  payload: {DealerProductId, MonthlyDuration, siteCode, siteName, wasDealerId},
});