import { call, put, takeEvery } from "redux-saga/effects";
import { DirectoryTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import { getDirectoriesBySiteCodeSuccess, getDirectoryBySiteFail, getSitesByServiceIdSuccess, getSitesByServiceIdFail, updateDirectoryStatusByServiceSuccess } from "./actions";
import { getDirectoryBySiteCodeAsync, getSitesByServiceIdAsync, updateDirStatusByServiceAsync } from "../../helpers/backend_helper";


function* getDirectoryBySiteCode({ payload: siteCode}: any) {
  try {
    const response: IResponse = yield call(getDirectoryBySiteCodeAsync, siteCode);
    if(response.success) {
      yield put(getDirectoriesBySiteCodeSuccess(response.result[0]));
    } else {
      yield put(getDirectoryBySiteFail());
    }
  } catch (error) {
    console.log(error)
  }
}

function* getSitesByServiceId({ payload: serviceId}: any) {
  try {
    const response: IResponse = yield call(getSitesByServiceIdAsync, serviceId);
    if(response.success) {
      yield put(getSitesByServiceIdSuccess(response.result));
    } else {
      yield put(getSitesByServiceIdFail(response.message));
    }
  } catch (error) {
    yield put(getSitesByServiceIdFail(error));
  }
}


function* updateDirStatusByService({ payload: docid, data}: any) {
  try {
    const response: IResponse = yield call(updateDirStatusByServiceAsync, docid, data);
    yield put(updateDirectoryStatusByServiceSuccess(response.success));
  } catch (error) {
    yield put(getSitesByServiceIdFail(error));
  }
}



function* directorySaga() {
  yield takeEvery(DirectoryTypes.GET_DIRECTORY_BY_SITECODE, getDirectoryBySiteCode)
  yield takeEvery(DirectoryTypes.GET_SITES_BY_SERVICEID, getSitesByServiceId)
  yield takeEvery(DirectoryTypes.UPDATE_DIR_STATUS_BY_SERVICE, updateDirStatusByService)
}

export default directorySaga;


