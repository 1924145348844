import { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
import AddBooking from "./add-booking";
import useBookings from "src/hooks/useBookings";
import { useTranslation } from "react-i18next";
import { IBooking } from "src/interfaces/general";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import moment from "moment";

const Bookings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const { bookings, loading, fetchBookings, deleteBooking, addBooking, success } = useBookings(currentUser?.code || currentUser?.companyCode);
  const currentBooking = useRef<IBooking>();
  const hasPermission = currentUser?.permissions?.bookings;

  const defaultSorted: any = [
    {
      dataField: "code", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const onEdit = (booking: any) => {
    history.push(
      `/bookings/${booking.id}`
    );
  };

  const onDelete = (booking: IBooking) => {
    currentBooking.current = booking;
    setShowConfirmAlert(true);
  };

  const onAdd = () => {
    setDrawer(true);
  };

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    const result = await deleteBooking(currentBooking.current?.id || "");
    if (result) {
      setShowSuccessAlert(true);
    }
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  useEffect(() => {
    fetchBookings();
  }, [currentUser]);

  return (
    <TablePage
      loading={loading}
      metaTitle={t("bookings")}
      data={bookings}
      onAdd={onAdd}
      hasPermission={hasPermission}
      title={t("bookings.title")}
      description={`(${bookings.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={bookings.length}
      defaultSorted={defaultSorted}
      footer={
        <AddBooking
          open={drawer}
          onDrawerClose={onDrawerClose}
          addBooking={addBooking}
          loading={loading}
          success={success}
        />
      }
      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Bookings);

const header = [
  {
    dataField: "companyCode",
    text: "Company Code",
    sort: true,
  },
  {
    dataField: "createdAt",
    text: "Created Date",
    formatter: (_: any, booking: any) => (
      moment(booking.createdAt).format("DD-MM-YYYY")
    ),
    sort: true,
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "customerId",
    text: "Customer Id",
    sort: true,
  },
  {
    dataField: "regNo",
    text: "Reg No",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "firstName",
    text: "First Name",
    sort: true,
  },
  {
    dataField: "lastName",
    text: "Last Name",
    sort: true,
  },
  {
    dataField: "postCode",
    text: "Post Code",
    sort: true,
  },
  {
    dataField: "make",
    text: "Make",
    sort: true,
  },
  {
    dataField: "model",
    text: "Model",
    sort: true,
  },
  {
    dataField: "telephone",
    text: "Telephone",
    sort: true,
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
  },
  {
    dataField: "dueDate",
    text: "Due Date",
    formatter: (_: any, booking: any) => (
      moment(booking.dueDate).format("DD-MM-YYYY")
    ),
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
  },
  {
    dataField: "source",
    text: "Source",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];