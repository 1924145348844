import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SpinnerCircular } from "spinners-react";
import useBookings from "src/hooks/useBookings";
import { useSelector } from "react-redux";
import { IBooking } from "src/interfaces/general";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const AddBooking = (props: any) => {   
    const {t} = useTranslation();
    const [date, setDate] = useState(new Date());
    const dueDate = date.toLocaleDateString();    
    const { currentUser } = useSelector((state: any) => state.login);
    const { loading, addBooking, success } = useBookings(currentUser?.code||currentUser?.companyCode);

    useEffect(() => {
        if(success) {
        props.onDrawerBookingClose(false)
        }
    }, [success])

    const handleAddBooking = (booking: IBooking) => {
        booking.dueDate = dueDate
        addBooking(booking)    
    };

    return (
        <React.Fragment>
        <div className="add-company">
            <MetaTags>
            <title>Add Customer New Booking</title>
            </MetaTags>
        </div>
        <ReactDrawer
            open={props.open}
            position={"right"}
            onClose={props.onDrawerBookingClose}
        >
            <div className="add-company-form" style={{ padding: 20 }}>
            <AvForm
                onValidSubmit={(e: any, values: any) => {
                handleAddBooking(values);
                }}
            >
                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="companyCode"
                            label={t("customers.companyCode")}
                            placeholder="company code should 5 digits"
                            type="string"
                            errorMessage="Should be 5 digits"
                            validate={{
                            required: { value: true },
                            minLength: {
                                value: 5,
                                errorMessage: "Min 5 chars.",
                            },
                            maxLength: {
                                value: 5,
                                errorMessage: "Max 5 chars.",
                            },
                            }}
                            value={props.currentCustomer.companyCode}
                        />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="siteCode"
                            label={t("customers.steCode")}
                            placeholder="site code should 7 digits"
                            type="string"
                            errorMessage="Should be 7 digits"
                            validate={{
                            required: { value: true },
                            minLength: {
                                value: 7,
                                errorMessage: "Min 7 chars.",
                            },
                            maxLength: {
                                value: 7,
                                errorMessage: "Max 7 chars.",
                            },
                            }}
                            value={props.currentCustomer.siteCode}
                        />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <label> {t("Customer Contact Info")} </label>                  
                        </div>
                    </Col>              
                </Row>

                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="firstName"
                            label={t("customers.firstName")}
                            type="text"
                            errorMessage="Invalid Name"
                            validate={{
                            required: { value: true },
                            }}
                            value={props.currentCustomer.firstName}
                        />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="lastName"
                            label={t("customers.lastName")}
                            type="text"
                            errorMessage="Invalid Name"
                            validate={{
                            required: { value: true },
                            }}
                            value={props.currentCustomer.lastName}
                        />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="phone"
                            label={t("customers.phone")}
                            type="text"
                            errorMessage="Invalid Phone number"
                            validate={{
                            required: { value: true },
                            }}
                            value={props.currentCustomer.phone}
                        />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="email"
                            label={t("customers.email")}
                            type="email"
                            errorMessage="Invalid Email"
                            validate={{
                            required: { value: true },
                            }}
                            value={props.currentCustomer.email}
                        />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="6">
                        <div className="mb-3">
                            <AvField
                            name="title"
                            label={t("customers.Title")}
                            type="text"
                            errorMessage="Invalid title"
                            validate={{
                                required: { value: true },
                            }}
                            value={props.currentCustomer.title}
                            />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="customerId"
                            label={t("customers.customerId")}
                            type="text"
                            errorMessage="Invalid customerId"
                            validate={{
                            required: { value: true },
                            }}
                            value={props.currentCustomer.id}
                        />
                        </div>
                    </Col>              
                </Row>

                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <label> {t("customers.Vehicle info")}</label>                  
                        </div>
                    </Col>              
                </Row>

                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="regNo"
                            label={t("customers.regNo")}
                            type="text"
                            errorMessage="Invalid regNo"
                            validate={{
                            required: { value: true },
                            }}
                            value=""
                        />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="postCode"
                            label={t("customers.postCode")}
                            type="text"
                            errorMessage="Invalid postCode"
                            validate={{
                            required: { value: true },
                            }}
                            value=""
                        />
                        </div>
                    </Col>
                </Row>
                        
                <Row>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="make"
                            label={t("customers.make")}
                            type="text"
                            errorMessage="Invalid make"
                            validate={{
                            required: { value: true },
                            }}
                            value=""
                        />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="model"
                            label={t("customers.model")}
                            type="text"
                            errorMessage="Invalid model"
                            validate={{
                            required: { value: true },
                            }}
                            value=""
                        />
                        </div>
                    </Col>
                </Row>

                <Row>              
                    <Col md="6">
                        <div className="mb-3">
                        <AvField
                            name="telephone"
                            label={t("customers.telephone")}
                            type="text"
                            errorMessage="Invalid telephone"
                            validate={{
                            required: { value: true },
                            }}
                            value=""
                        />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">                        
                            <label> {t("customers.dueDate")} </label>
                            <DatePicker selected={date} onChange={(date:Date) => setDate(date)} className="form-control" />                        
                        </div>
                    </Col>
                    
                </Row>    
                {/* <Row>
                <Col md="6">
                    <div className="mb-3">
                    <AvField
                        name="status"
                        label="status"
                        type="text"
                        errorMessage="Invalid status"
                        validate={{
                        required: { value: true },
                        }}
                        value=""
                    />
                    </div>
                </Col>

                <Col md="6">
                    <div className="mb-3">
                    <AvField
                        name="source"
                        label="source"
                        type="text"
                        errorMessage="Invalid source"
                        validate={{
                        required: { value: true },
                        }}
                        value=""
                    />
                    </div>
                </Col>
                </Row> */}

                <Row>
                    <Col>
                        <div className="text-end">
                        <button 
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={loading}
                        >
                            {loading ? (
                            <SpinnerCircular
                                size={30}
                                thickness={150}
                                speed={150}
                                color="#36ad47"
                                secondaryColor="rgba(0, 0, 0, 0.44)"
                            />
                            ) : (
                            <>{t("customers.save")}</>
                            )}
                        </button>
                        </div>
                    </Col>
                </Row>
            </AvForm>
            </div>
        </ReactDrawer>
        </React.Fragment>
    );
};

export default withRouter(AddBooking);
