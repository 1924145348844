import { BookingToolTypes } from "./actionTypes";

export const getBookingToolDataBySiteCode = (siteCode: string) => ({
  type: BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE,
  payload: siteCode
});

export const getBookingToolDataBySiteCodeSuccess = (bookingToolData: any) => ({
  type: BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE_SUCCESS,
  payload: bookingToolData
});

export const getBookingToolDataBySiteCodeFail = () => ({
  type: BookingToolTypes.GET_BOOKING_TOOL_DATA_BY_SITECODE_FAIL,
  payload: null
});



export const saveBookingToolSetting = (bookingToolData: any) => ({
  type: BookingToolTypes.SAVE_BOOKING_TOOL_DATA,
  payload: bookingToolData
});

export const saveBookingToolSettingSuccess = (bookingToolData: any) => ({
  type: BookingToolTypes.SAVE_BOOKING_TOOL_DATA_SUCCESS,
  payload: bookingToolData
});












