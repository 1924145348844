import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import useSitesByService from "src/hooks/useSitesByService";
import { IAddButtonProps } from "../types";
import styled from 'styled-components';


const AddButton = ({
    service,
    onSelect
}:IAddButtonProps)=>{

    const [open, setOpen] = useState(false);
    const {sites, loading} = useSitesByService(service.key);

    return(
        <Container>
            <Dropdown toggle={() => setOpen(!open)} isOpen={open}>
            <DropdownToggle>
                <span className='fa fa-plus'/>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-md p-4">
                {loading && <i className="bx bx-loader bx-spin align-middle me-2"/>}
                {sites.map((site, index)=>(
                    <SiteItem key={index} onClick={()=>onSelect(site)}>
                        <SiteLogo src={site.logo}/>
                        <div>
                            <SiteName>{site.name}</SiteName>
                            <SiteName>{site.code}</SiteName>
                        </div>
                    </SiteItem>
                ))}
            </DropdownMenu>
            </Dropdown>
        </Container>
    )
}

export default AddButton;

const Container = styled.div`
    display: inline-flex;
    margin: 10px;
`

const SiteItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: grey;
    border-radius: 5px;
    margin: 2px;
    padding: 10px;
`

const SiteLogo = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    object-fit: cover;
`

const SiteName = styled.div`
    font-size: 12px;
    color: white;
    margin-left: 15px;
`