import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { BookingTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import { 
  getAllBookingsByCompanyCodeSuccess,
  getAllBookingsByCompanyCodeFail,
  getAllBookingsSuccess,
  getAllBookingsFail,
  getBookingsBySiteCodeSuccess,
  getBookingsBySiteCodeFail,
  addBookingSuccess,
  addBookingFail,
  getBookingByDocIdSuccess,
  getBookingByDocIdFail,
  deleteBookingByIdSuccess
} from "./actions";

import {
  getBookingsByCompanyCodeAsync,
  getAllBookingsAsync,
  getBookingsBySiteCodeAsync,
  addBookingAsync,
  getBookingByDocIdAsync,
  deleteBookingByIdAsync
} from "../../helpers/backend_helper";

function* getBookingsByCompanyCode({ payload: companyCode}: any) {
  try {
    const response: IResponse = yield call(getBookingsByCompanyCodeAsync, companyCode);
    if(response.success) {
      yield put(getAllBookingsByCompanyCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getAllBookingsByCompanyCodeFail(error));
  }
}

function* getAllBookings() {
  try {
    const response: IResponse = yield call(getAllBookingsAsync);
    if(response.success) {
      yield put(getAllBookingsSuccess(response.result));
    }
  } catch (error) {
    yield put(getAllBookingsFail(error));
  }
}

function* getBookingsBySiteCode({ payload: siteCode}: any) {
  try {
    const response: IResponse = yield call(getBookingsBySiteCodeAsync, siteCode);
    if(response.success) {
      yield put(getBookingsBySiteCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getBookingsBySiteCodeFail(error));
  }
}

function* addBooking({ payload: booking }: any) {
  try {
    const response: IResponse = yield call(addBookingAsync, booking);
    if(response.success) {
      yield put(addBookingSuccess(response.result));
    }
  } catch (error) {
    yield put(addBookingFail(error));
  }
}

function* getBookingByDocId({ payload: docid}: any) {
  try {
    const response: IResponse = yield call(getBookingByDocIdAsync, docid);
    if(response.success) {
      yield put(getBookingByDocIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getBookingByDocIdFail(error));
  }
}

function* deleteBookingById({ payload: docid }: any) {
  try {
    const response: IResponse = yield call(deleteBookingByIdAsync, docid);
    yield put(deleteBookingByIdSuccess(response.result));
  } catch (error) {
    console.log(error)
  }
}

// function* deleteCompany({ payload: code }: any) {
//   try {
//     const response: IResponse = yield call(deleteCompanyAsync, code);
//     yield put(deleteCompanySuccess(response.result));
//   } catch (error) {
//     yield put(deleteCompanyFail(error));
//   }
// }

// function* updateSiteByDocId({ payload: {docid, site} }: any) {
//   try {
//     const response: IResponse = yield call(updateSiteByDocIdAsync, docid, site);
//     console.log('response =>', response)
//     if(response.success) {
//       yield put(updateSiteByDocIdSuccess(response.result));
//     }
//   } catch (error) {
//     yield put(updateSiteByDocIdFail(error));
//   }
// }

function* bookingSaga() {
  yield takeEvery(BookingTypes.GET_BOOKINGS_BY_COMPANY_CODE, getBookingsByCompanyCode)
  yield takeEvery(BookingTypes.GET_ALL_BOOKINGS, getAllBookings)
  yield takeEvery(BookingTypes.GET_BOOKINGS_BY_SITECODE, getBookingsBySiteCode)
  yield takeEvery(BookingTypes.ADD_BOOKING, addBooking)
  yield takeEvery(BookingTypes.GET_BOOKING_BY_DOCID, getBookingByDocId)
  yield takeEvery(BookingTypes.DELETE_BOOKING_BYID, deleteBookingById)

  // // yield takeEvery(CoCompanyTypesmpanyTypes.DELETE_COMPANY, deleteCompany),
  // yield takeEvery(BookingTypes.UPDATE_SITE_BY_DOCID, updateSiteByDocId);
}

export default bookingSaga;


