import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addCompany } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyByCode, updateCompany } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";

const EditCompany = (props: any) => {
  const dispatch = useDispatch();
  const { docid } = useParams<any>();
  const history = useHistory();

  const { singleCompany } = useSelector((state: any) => state.company);

  useEffect(() => {
    dispatch(getCompanyByCode(docid));
  }, []);

  const handleEditCompany = (values: any) => {
    dispatch(updateCompany(docid, values, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{paddingRight: 10}}>
                          <Link to="/companies" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Company Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: CompanyCode: xxxxx
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditCompany(values);
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="code"
                    label="Company Code"
                    placeholder="company code should 5 digits"
                    type="number"
                    errorMessage="Should be 5 digits"
                    validate={{
                      required: { value: true },
                      minLength: {
                        value: 5,
                        errorMessage: "Min 5 chars.",
                      },
                      maxLength: {
                        value: 5,
                        errorMessage: "Max 5 chars.",
                      },
                    }}
                    value={singleCompany?.code || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.name || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Phone"
                    type="text"
                    errorMessage="Invalid Phone number"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.phone || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.email || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    errorMessage="Invalid Password"
                    validate={{
                      required: { value: true },
                    }}
                    value={"" || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="address"
                    label="Address"
                    type="text"
                    errorMessage="Invalid Address"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.address || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="postcode"
                    label="Postcode"
                    type="text"
                    errorMessage="Invalid postcode"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.postcode || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="websiteUrl"
                    label="Website Url"
                    type="text"
                    errorMessage="Invalid websiteUrl"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.websiteUrl || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="bookingUrl"
                    label="Booking Url"
                    type="text"
                    errorMessage="Invalid bookingUrl"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.bookingUrl || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="tutorialVideo"
                    label="Tutorial Video"
                    type="text"
                    errorMessage="Invalid tutorialVideo"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.tutorialVideo || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Row>
                <Col md="6">
                  <h5>Location</h5>
                </Col>
              </Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="location.lat"
                    label="Lat"
                    type="number"
                    errorMessage="Invalid lat"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.location?.lat || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="location.lon"
                    label="Lon"
                    type="text"
                    errorMessage="Invalid lon"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.location?.lon || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="managerName"
                    label="Manager Name"
                    type="text"
                    errorMessage="Invalid ManagerName"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleCompany?.managerName || ""}
                  />
                </div>
              </Col>
              <Col md="6"></Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Update
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditCompany);
