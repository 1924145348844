import { SiteTypes } from "./actionTypes";

// export const getSitesByCompanyCode = (companyCode: string) => ({
//   type: SiteTypes.GET_SITES,
//   payload: companyCode
// });

export const getAllSitesByCompanyCode = (companyCode: string) => ({
  type: SiteTypes.GET_SITES_BY_COMPANY_CODE,
  payload: companyCode
});

export const fetchSitesByCompanyCodeSuccess = (sites: any) => ({
  type: SiteTypes.GET_SITES_BY_COMPANY_CODE_SUCCESS,
  payload: sites,
});

export const fetchSitesByCompanyCodeFail = (error: any) => ({
  type: SiteTypes.GET_SITES_BY_COMPANY_CODE_FAIL,
  payload: error,
});

export const addSite = (site: any) => ({
  type: SiteTypes.ADD_SITE,
  payload: site,
});

export const addSiteSuccess = (site: any) => ({
  type: SiteTypes.ADD_SITE_SUCCESS,
  payload: site,
});

export const addSiteFail = (error: any) => ({
  type: SiteTypes.ADD_SITE_FAIL,
  payload: error,
});

export const deleteSiteBySiteCode = (siteCode: string) => ({
  type: SiteTypes.DELETE_SITE_BYSITECODE,
  payload: siteCode,
});

export const deleteSiteBySiteCodeSuccess = (siteCode: any) => ({
  type: SiteTypes.DELETE_SITE_BYSITECODE_SUCCESS,
  payload: siteCode,
});


export const getSiteByDocId = (docid: string) => ({
  type: SiteTypes.GET_SITE_BY_DOCID,
  payload: docid
})

export const getSiteByDocIdSuccess = (site: any) => ({
  type: SiteTypes.GET_SITE_BY_DOCID_SUCCESS,
  payload: site,
});

export const getSiteByDocIdFail = (error: any) => ({
  type: SiteTypes.GET_SITE_BY_DOCID_FAIL,
  payload: error,
});

export const updateSiteByDocId = (docid: string, site: any, history: any) => ({
  type: SiteTypes.UPDATE_SITE_BY_DOCID,
  payload: {docid, site, history}
})

export const updateSiteByDocIdSuccess = (site: any) => ({
  type: SiteTypes.UPDATE_SITE_BY_DOCID_SUCCESS,
  payload: site,
});

export const updateSiteByDocIdFail = (error: any) => ({
  type: SiteTypes.UPDATE_SITE_BY_DOCID_FAIL,
  payload: error,
});

export const getSiteBySiteCode = (siteCode: string) => ({
  type: SiteTypes.GET_SITE_BY_SITECODE,
  payload: siteCode
})

export const getSiteBySiteCodeSuccess = (site: any) => ({
  type: SiteTypes.GET_SITE_BY_SITECODE_SUCCESS,
  payload: site,
});

export const getSiteBySiteCodeFail = (error: any) => ({
  type: SiteTypes.GET_SITE_BY_SITECODE_FAIL,
  payload: error,
});





