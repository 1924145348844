import React from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col } from "reactstrap";

const Unauthorized = () => {
    return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Unauthorised</title>
                </MetaTags>
        <Container fluid>
          <Row>
            <Col md="12">
            <p>Sorry, you are not authorised to access this resource</p>
            </Col>
          </Row>
</Container>
</div>
        </React.Fragment>
    );
};
 
export default Unauthorized;