import React from 'react';
import { useSelector } from 'react-redux';
import useNotificationTemplates from 'src/hooks/useNotiffcationTemplates';
import styled from 'styled-components';


interface IProps {
    onChange: (value: any) => void;
}

const SelectTemplate = (props: IProps) => {

    const { templates, fetchTemplatesByCompanyCode } = useNotificationTemplates();
    const [selected, setSelected] = React.useState<any>(null);
    const user = useSelector((state: any) => state.login.currentUser);

    const onselect = (template: any) => {
        setSelected(template);
        props.onChange(template);
    }

    React.useEffect(() => {
        fetchTemplatesByCompanyCode();
    }, [user]);

    return (
        <Container>
            {templates.map((template: any) => (
                <div
                    className='item-container'
                    key={template.id}
                    onClick={() => onselect(template)}
                    style={{
                        backgroundColor: selected?.id === template.id ? '#ccc' : '#fff'
                    }}
                >
                    <div className='subject'>{template.subject}</div>
                    <div className='body'>{template.body}</div>
                </div>
            ))}
        </Container>
    )

}

export default SelectTemplate;

const Container = styled.div`
    padding: 20px;
    max-height: 500px;
    overflow: scroll;

    .item-container {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        .subject {
            font-weight: bold;
        }

        .body {
            font-size: 12px;
        }
    }
`