import { useEffect, useState } from "react";
import { deleteBookingByIdAsync, getBookingsByCompanyCodeAsync,addBookingAsync, getGraphDataOfBooking } from "src/helpers/backend_helper";
import { IBooking, TGraphData } from "src/interfaces/general";

import moment from 'moment'

const useBookings = (companyCode?: string) => {

    const [bookings, setBookings] = useState<Array<IBooking>>([]);
    const [graphData, setGraphData] = useState<TGraphData>([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const fetchBookings = () => {
        setLoading(true);
        if ( companyCode ){
            getBookingsByCompanyCodeAsync(companyCode)
            .then(res=>{                
                if (res.success){
                    setBookings(res.result||[]);
                }
            }).catch(console.log).finally(()=>setLoading(false));
        }
    }

    const fetchGraphData = (siteCode:string, start:number, days: number)=>{
        setLoading(true);
        getGraphDataOfBooking(
            siteCode,
            moment(start).format("YYYY-MM-DD"),
            days
        ).then(res=>{
            if (res.success){
                setGraphData(res.result);
            }
        })
        setLoading(false);
    }

    // const addFakeBookings = ()=>{
    //     const seedBooking =   {
    //         companyCode: "77777",
    //         siteCode: "1111111",
    //         customerId: "customerId",
    //         regNo: "reg",
    //         email: "test",
    //         firstName: "first",
    //         lastName: "last",
    //         postcode: "",
    //         make: "make",
    //         model: "model",
    //         telephone: "",
    //         title: "Mr",
    //         dueDate: moment().format("YYYY-MM-DD"),
    //         status: "aaa",
    //         actions: [],
    //         source: ""
    //       }
        
    //     const size = 100;
    //     const bookings: IBooking[] =[];
    //     for (let i = 0; i < size; i ++){
    //         addBooking({
    //             ...seedBooking,
    //             createdAt: moment().subtract(Math.trunc(Math.random()*100), 'days').valueOf(),
    //             id: "id"+Math.trunc(Math.random()*1000),
    //             phone: "07"+Math.trunc(Math.random()*1000000),
    //         })
    //     }
        
    // }

    const deleteBooking = async (id: string)=>{
        const index = bookings.findIndex(booking=>booking.id === id);
        try{
            setLoading(true);
            const result = await deleteBookingByIdAsync(id);
            if (result.success){
                bookings.splice(index, 1);
                setBookings([...bookings]);
                setLoading(false);
                return true;
            }
        }catch(error){
            console.log("booking delete error", error);
        }
        setLoading(false);
        return false;
        
    }

    const addBooking = async( booking: IBooking)=>{

        try{
            setLoading(true);
            const result = await addBookingAsync(booking);
            if (result.success){
                bookings.push(booking);
                setBookings([...bookings]);
                setLoading(false);
                setSuccess(true);
                return true;
            }
        }catch(error){
            console.log("booking add error", error);
        }
        setLoading(false);
        return false;
    }

    return {
        bookings,
        loading,
        deleteBooking,
        addBooking,
        fetchGraphData,
        fetchBookings,
        graphData,
        success,
    };
}

export default useBookings;