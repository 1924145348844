import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import {
  Button,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  Toast,
  Alert,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  createProductNotificationsAsync,
  invitedCustomerProudctNotificationAsync,
  getVehicleByRegNoAsync,
  createContractAsync,
  addVehicleAsync,
  addSaleOfProductAsync,
} from "src/helpers/backend_helper";
import NavHeader from "src/components/Common/NavHeader";
import { ICustomer } from "src/interfaces/general";
import styled from "styled-components";
import CustomerList from "./CustomerList";
import { useSelector } from "react-redux";
import ProductList from "../Notifications/ProductList";
import { IProduct } from "src/hooks/useProducts";
import ProductDetailCard from "../Notifications/ProductDetailCard";
import moment from "moment";
import { CheckBox } from "../Directory2/components/general";
import useSites from "src/hooks/useSites";
import useSaleOfProducts from "src/hooks/useSaleOfProducts";

const AddNotification = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentUser } = useSelector((state: any) => state.login);
  const { sites } = useSites(currentUser?.code || currentUser?.companyCode);
  const { singleSaleOfProduct, addSaleOfProduct } = useSaleOfProducts();

  const [showTutorial, setShowTutorial] = useState(false);
  const [product, setProduct] = useState<IProduct>();
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [regNo, setRegNo] = useState("");
  const [mileAge, setMileAge] = useState<number>();
  const [drive, setDrive] = useState<boolean>(false);
  const [customer, setCustomer] = useState<ICustomer>();

  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [smartInfo, setSmartInfo] = useState<any>();
  const [error, setError] = useState<string>("");

  const onSelectCustomer = (customer: ICustomer) => {
    setCustomer(customer);
    setTitle(customer.title || "Mr");
    setFirstName(customer.firstName);
    setLastName(customer.lastName);
    setPhone(customer.phone);
    setAddress(customer.address);
    setPostcode(customer.postcode);
    setEmail(customer.email);
  };

  const onFilterChange = (filter: string) => {
    setPhone(filter);
    setTitle("");
    setFirstName("");
    setLastName("");
    setAddress("");
    setPostcode("");
    setEmail("");
  };

  const disabled = !(phone.length > 8 && product && regNo && mileAge);

  const onTutorial = () => {
    setShowTutorial(true);
  };

  const onSend = () => {
    if (disabled) {
      alert("Please fill all fields");
      return;
    }
    setShowConfirmAlert(true);
  };

  const fetchSmartInfo = async () => {
    const result = await getVehicleByRegNoAsync(regNo);
    if (result?.Success) {
      setSmartInfo(result);
    } else {
      setSmartInfo(null);
    }
  };

  const confirmSend = async () => {
    setShowConfirmAlert(false);
    setError("");
    if (product) {
      const contractNumber = await createContract();
      if (!contractNumber) {
        return;
      }

      const site = sites.find(item => item.code === product.hendyInfo.siteCode);
      const cover = product.wasInfo.ProductName.toLowerCase().includes(
        "diamond"
      )
        ? "Diamond"
        : product.wasInfo.ProductName.toLowerCase().includes("platinum")
        ? "Platinum"
        : "";

      const saleOfProduct = {
        agreement: regNo,
        state: "Sent",
        site: site?.name,
        productName: product.wasInfo.ProductName,
        cover: cover,
        price: product.wasInfo.Monthly,
        department: "",
        job_role: currentUser?.role?.name,
        cc: "Pending",
        policy: product.hendyInfo.terms,
        admin: currentUser,
        product: product,
        customer: customer,
        contractNumber: contractNumber
      };

      const saleOfProductRes = await addSaleOfProductAsync(saleOfProduct);

      const companyCode = currentUser.companyCode || currentUser.code;
      const template = {
        companyCode,
        subject: product.wasInfo.ProductName,
        body: product.hendyInfo.description,
        buttons: [
          {
            icon: "arrow-forward",
            name: "Continue",
            type: "product",
          },
        ],
        data: {
          ...product,
          regNo: regNo,
          mileAge: mileAge,
          contractNumber,
        },
        coverImage: product.hendyInfo.image,
        type: "product",
        saleOfProcutId: saleOfProductRes?.result?.id,
      };

      let customerId = customer?.id;

      if (customer) {
        await createProductNotificationsAsync({
          template,
          customers: [customer],
        });
      } else {
        const res = await invitedCustomerProudctNotificationAsync({
          customerData: {
            staffId: currentUser.id,
            companyCode,
            siteCode: product.hendyInfo.siteCode,
            name: `${firstName} ${lastName}`,
            phone,
          },
          template,
          productId: product.id,
        });
        if (res.success) {
          customerId = res.customerResult.id;
        }
      }
      addVehicleAsync({
        regNo: regNo,
        mileAge: mileAge,
        customerId: customerId,
        phone,
        smartInfo: smartInfo?.VehicleDetails,
      });

      setShowSuccessAlert(true);

      history.push("/products/dashboard");
    } else {
      alert("Please select product.");
    }
  };

  const handleChangeRegNo = (e: any) => {
    setRegNo(e.target.value);
  };
  const handleChangeMileAge = (e: any) => {
    setMileAge(e.target.value);
  };

  const createContract = async () => {
    if (smartInfo) {
      let contractTemplateData = {
        DealerId: product?.wasDealerId,
        Customer: {
          Title: title,
          ForeName: firstName,
          Surname: lastName,
          CompanyName: "",
          HomePhone: "",
          Mobile: phone,
          Email: email,
          Line1: address,
          Line2: "",
          Line3: "",
          City: "",
          County: "",
          Postcode: postcode,
          CountryID: 1,
          DoNotContact: false,
          GdprEmail: false,
          GdprPhone: false,
          HowDidYouFirstHearOfUs: "",
          FirstName: firstName,
        },
        Vehicle: {
          VehicleType: smartInfo?.ExtendedVehicleDetails?.Other?.VehicleType,
          VRM: regNo,
          Make: smartInfo?.VehicleDetails?.Make,
          Model: smartInfo?.VehicleDetails?.Model,
          EngineCapacity: smartInfo?.VehicleDetails?.CylinderCapacity,
          FuelType: smartInfo?.VehicleDetails?.Fuel,
          VIN: smartInfo?.ExtendedVehicleDetails?.Dvla.Vin,
          Transmission:
            smartInfo?.ExtendedVehicleDetails?.Other?.TransmissionType,
          LastServiceDate: null, // temporary value
          PurchasePrice: 12345, // temporary value
          MarketValue: 12000, // temporary value
          PurchaseDate: "2019-11-27", // temporary value
          RegistrationDate: smartInfo.VehicleDetails.DateOfRegistration, // temporary value
          MileagePointOfSale: 70000, // temporary value
          ServicedPriorDelivery: false, // temporary value
          Deliverydate: "", // temporary value
          ServiceBookStamped: false, // temporary value
          PDICompleted: false, // temporary value
        },
        Product: {
          DealerProductId: product?.wasInfo.DealerProductId,
          ProductMonthlyDuration: product?.wasInfo.MonthlyDuration,
          AgeFrom: product?.wasInfo.AgeFrom,
          AgeTo: product?.wasInfo.AgeTo,
          MileageFrom: product?.wasInfo.MileageFrom,
          MileageTo: product?.wasInfo.MileageTo,
          ProductClaimLimit: product?.wasInfo.ClaimLimit,
          Excess: 0,
          StartDate: moment().format("YYYY-MM-DD"),
          Loadings: [],
        },
        PaymentInformation: {
          PaymentAmount: 99,
          NumberOfPayments: product?.wasInfo.MonthlyDuration,
          PaymentConfirmation: regNo,
        },
      };
      try {
        const result = await createContractAsync({
          data: contractTemplateData,
        });
        return result.result.ContractNumber;
      } catch (e) {
        console.log(e);
        setError(
          "Error occured while creating contract. Please input correct information."
        );
      }
    }
    return null;
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Notification</title>
      </MetaTags>
      <div
        className="page-content"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Container fluid>
          <NavHeader
            title="Add Notification"
            description="You can send a notification to a specified user."
          />
          <Row>
            <Col md="6">
              <CardTitle>Please select the customer</CardTitle>
              <CustomerList
                onSelectCustomer={onSelectCustomer}
                onFilterChange={onFilterChange}
              />

              <RowItem>
                <Label>Title</Label>
                <Input
                  type="text"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </RowItem>
              <RowItem>
                <Label>First Name</Label>
                <Input
                  type="text"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </RowItem>
              <RowItem>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </RowItem>
              <RowItem>
                <Label>Phone</Label>
                <Input
                  type="text"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </RowItem>
              <RowItem>
                <Label>Address</Label>
                <Input
                  type="text"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </RowItem>
              <RowItem>
                <Label>Postcode</Label>
                <Input
                  type="text"
                  value={postcode}
                  onChange={e => setPostcode(e.target.value)}
                />
              </RowItem>
              <RowItem>
                <Label>Email</Label>
                <Input
                  type="text"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </RowItem>
              {error != "" ? <Alert color="danger">{error}</Alert> : null}
            </Col>
            <Col md="6">
              <Row className="pt-2">
                <Col md={6}>
                  <CardTitle>Reg No</CardTitle>
                  <div>
                    <Input
                      type="text"
                      onChange={handleChangeRegNo}
                      value={regNo}
                      onBlur={fetchSmartInfo}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <CardTitle>Mileage</CardTitle>
                  <div>
                    <Input
                      type="number"
                      onChange={handleChangeMileAge}
                      value={mileAge}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="pt-2">
                <CheckRow>
                  <CheckBox onChange={() => setDrive(!drive)} />
                  <CardTitle>All JLR and any brand 4x4 vehicles over 2.0cc</CardTitle>
                </CheckRow>
              </Row>
              {smartInfo ? (
                <>
                  <Description>
                    Make: {smartInfo.VehicleDetails?.Make}
                  </Description>
                  <Description>
                    Model: {smartInfo.VehicleDetails?.Model}
                  </Description>
                  <Description>
                    Engine Capacity: {smartInfo.VehicleDetails.CylinderCapacity}
                  </Description>
                  <Description>
                    Fuel Type: {smartInfo.VehicleDetails?.Fuel}
                  </Description>
                  <Description>
                    Transmission:{" "}
                    {smartInfo.ExtendedVehicleDetails.Other?.TransmissionType}
                  </Description>
                  <Description>
                    Registration Date:{" "}
                    {moment(smartInfo.VehicleDetails?.DateOfRegistration).format(
                      "DD/MM/YYYY"
                    )}
                  </Description>
                  <Description>
                    Colour: {smartInfo.VehicleDetails?.Colour}
                  </Description>
                </>
              ) : null}
              <div>
                <ProductList
                  regNo={regNo}
                  mileAge={mileAge}
                  drive={drive}
                  value={product}
                  onSelect={setProduct}
                />
              </div>
              <div className="pt-5">
                {product ? <ProductDetailCard {...product} /> : null}
              </div>
            </Col>

            <ButtonContainer>
              <Button
                className="template-create"
                color="success"
                type="submit"
                onClick={onSend}
              >
                Send
              </Button>
            </ButtonContainer>
          </Row>
        </Container>
        {showConfirmAlert ? (
          <SweetAlert
            title={t("alert.confirm")}
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={confirmSend}
            onCancel={() => setShowConfirmAlert(false)}
          ></SweetAlert>
        ) : null}

        {showSuccessAlert ? (
          <SweetAlert
            success
            title={t("alert.send")}
            onConfirm={() => setShowSuccessAlert(false)}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default AddNotification;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;

  .template-create {
    margin-right: 10px;
  }
`;

const CheckRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  // padding: 10px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 100px;
`;

const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;
