import { Button } from "reactstrap";
import styled from "styled-components";
import { ThemeColors } from "src/constants/colors";
import Icon from "@ailibs/feather-react-ts";
import { IOnboard } from "src/interfaces/general";
import { useState } from "react";
import useOnboardings from "src/hooks/useOnboardings";

interface IProps {
    onEdit: (card: any) => void;
    onRemove: (card:any)=>void;
    onChange: (card:any)=>void;
    onboarding: IOnboard;
}

const OnboardingCard = (props:IProps) => {     

    const [visible, setVisible] =useState(props.onboarding.visible);
    
    const onChange = () => {
        setVisible(!visible)
        props.onChange(props.onboarding);
    };

    return (    
        <CardContainer>
            <EditButton>
                <i
                    className="mdi mdi-pencil"
                    id="edittooltip"
                    onClick={()=>props.onEdit(props.onboarding)}
                ></i>
            </EditButton>
            <RemoveIcon onClick={()=>props.onRemove(props.onboarding)}>
                <Icon name="x-circle" size={30} color={ThemeColors.main}/>
            </RemoveIcon>              
            <DescBox>
                <Title>{props.onboarding.title}</Title>
                <Desc>{props.onboarding.description}</Desc>
            </DescBox>              
            <CardImg src={props.onboarding.image}/>
            <Button
                onClick={onChange}
                style={{width:30, height:30, borderRadius: 15, backgroundColor: visible?ThemeColors.green:ThemeColors.white, position: "absolute", bottom: 20}}
            />                           
        </CardContainer>
    )
};

export default OnboardingCard;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 400px;
    border-radius: 20px;
    border-color: ${ThemeColors.main};
    border: solid;
    border-width: 1px;
    position: relative;
    background: ${ThemeColors.white};
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    margin-left: 10px;
    margin-top: 10px;
`

const EditButton = styled.div`
    width: 24px;
    height: 24px;
    color: white;
    background-color: ${ThemeColors.main};
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
`

const RemoveIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`

const DescBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    margin-top:20px;
`

const Title = styled.div`
    color: ${ThemeColors.main};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`

const Desc = styled.div`
    color: ${ThemeColors.main};
    font-size: 12px;
    font-weight: 400;
    white-space: break-spaces;
    text-align: center;
`

const CardImg = styled.img`
    width: 100%;
    height: 160px;
    border-radius: 20px;
    object-fit: cover;
    margin-top: 20px;
`
