import firebase from "firebase/app";
import "firebase/storage";
import 'firebase/analytics'
// import { getAuth } from "firebase/auth";

import config from "../config";

firebase.initializeApp(config.firebase);
firebase.analytics();
// getAuth(firebase.initializeApp(config.firebase));



const storage = firebase.storage();

export { storage, firebase as default };
