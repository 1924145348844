import { GeoFenceTypes } from "./actionTypes";

export const INIT_STATE: any = {
  geoFenceList: [],
  singleGeoFence: {},
  error: {},
  success: false,
  loading: true,
  confirm_del: false,
};

const geofence = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case GeoFenceTypes.GET_GEOFENCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        geoFenceList: action.payload,
      };

    case GeoFenceTypes.GET_GEOFENCE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GeoFenceTypes.ADD_GEO_FENCE:
      return {
        ...state,
        loading: true,
      };
    case GeoFenceTypes.ADD_GEO_FENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        geoFenceList: [...state.geoFenceList, action.payload],
      };

    case GeoFenceTypes.ADD_GEO_FENCE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GeoFenceTypes.GET_GEOFENCE_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case GeoFenceTypes.GET_GEOFENCE_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleGeoFence: action.payload,
      };

    case GeoFenceTypes.GET_GEOFENCE_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    
    case GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID:
    return {
      ...state,
      loading: true,
    };

    case GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GeoFenceTypes.UPDATE_GEOFENCE_BY_DOCID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleGeoFence: action.payload,
      };
    case GeoFenceTypes.DELETE_GEOFENCE_BYID:
      return {
        ...state,
        loading: true,
        success: false
      };

    case GeoFenceTypes.DELETE_GEOFENCE_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        geoFenceList: state.geoFenceList.filter((item: any) => item.id !== action.payload),
      };

    case GeoFenceTypes.DELETE_GEOFENCE_BYID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      }    
    default:
      return state;
  }
};

export default geofence;
