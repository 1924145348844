import { useEffect, useState } from "react";
import {
  getAllSaleOfProductsAsync,
  addSaleOfProductAsync,
} from "src/helpers/backend_helper";
import { ISaleOfProduct } from "src/interfaces/general";

const useSaleOfProducts = () => {
  const [saleOfProducts, setSaleOfProducts] = useState<Array<ISaleOfProduct>>(
    []
  );
  const [singleSaleOfProduct, setSingleSaleOfProduct] =
    useState<ISaleOfProduct>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const fetchSaleOfProducts = () => {
    setLoading(true);
    getAllSaleOfProductsAsync()
      .then(res => {
        if (res.success) {
          setSaleOfProducts(res.result || []);
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const addSaleOfProduct = async (saleOfProduct: ISaleOfProduct) => {
    try {
      setLoading(true);
      const res = await addSaleOfProductAsync(saleOfProduct);
      if (res.success) {
        setSingleSaleOfProduct(res.result || {});
        fetchSaleOfProducts();
        setLoading(false);
        setSuccess(true);
        return true;
      }
    } catch (error) {
      console.log("saleOfProduct add error", error);
    }
    setLoading(false);
    return false;
  };

  useEffect(() => {
    fetchSaleOfProducts();
  }, []);

  return {
    saleOfProducts,
    addSaleOfProduct,
    singleSaleOfProduct,
    loading,
    success,
  };
};

export default useSaleOfProducts;
