import { ScoreTypes } from "./actionTypes";

//get all dmin by COMPANYCODE
export const getScoresByCompanyCode = (companyCode: string) => ({
  type: ScoreTypes.GET_SCORES_BY_COMPANYCODE,
  payload: companyCode
});

export const getScoresByCompanyCodeSuccess = (scores: any) => ({
  type: ScoreTypes.GET_SCORES_BY_COMPANYCODE_SUCCESS,
  payload: scores
});

export const getScoresByCompanyCodeFail = (error: any) => ({
  type: ScoreTypes.GET_SCORES_BY_COMPANYCODE_FAIL,
  payload: error
});




