import { VehicleTypes } from "./actionTypes";
import vehicleSaga from "./saga";

export const getAllVehicles = () => ({
  type: VehicleTypes.GET_ALL_VEHICLES,
});

export const getAllVehiclesSuccess = (vehicles: any) => ({
  type: VehicleTypes.GET_ALL_VEHICLES_SUCCESS,
  payload: vehicles,
});

export const getAllVehiclesFail = (error: any) => ({
  type: VehicleTypes.GET_ALL_VEHICLES_FAIL,
  payload: error,
});

export const addVehicle = (vehicle: any) => ({
  type: VehicleTypes.ADD_VEHICLE,
  payload: vehicle,
});

export const addVehicleSuccess = (vehicle: any) => ({
  type: VehicleTypes.ADD_VEHICLE_SUCCESS,
  payload: vehicle,
});

export const addVehicleFail = (error: any) => ({
  type: VehicleTypes.ADD_VEHICLE_FAIL,
  payload: error,
});

export const deleteVehicleById = (docid: any)=> ({
  type: VehicleTypes.DELETE_VEHICLE_BYID,
  payload: docid,
});

export const deleteVehicleByIdSuccess = (docid: any) => ({
  type: VehicleTypes.DELETE_VHEICLE_BYID_SUCCESS,
  payload: docid,
})

// export const deleteCompnay = (code: string) => ({
//   type: VehicleTypes.DELETE_COMPANY,
//   payload: code,
// });

// export const confirmSuccess = () => ({
//   type:VehicleTypes.CONFIRM_DELETE
// })

// export const deleteCompanySuccess = (code: any) => ({
//   type: VehicleTypes.DELETE_COMPANY_SUCCESS,
//   payload: code,
// });

// export const deleteCompanyFail = (error: any) => ({
//   type: VehicleTypes.DELETE_COMPANY_FAIL,
//   payload: error,
// });

// export const updateCompany = (id: string, company: any) => ({
//   type: VehicleTypes.UPDATE_COMPANY,
//   payload: {id, company}
// });


// export const updateCompanySuccess = (company: any) => ({
//   type: VehicleTypes.UPDATE_COMPANY_SUCCESS,
//   payload: company,
// });

// export const updateCompanyFail = (error: any) => ({
//   type: VehicleTypes.UPDATE_COMPANY_FAIL,
//   payload: error,
// });


export const getVehicleByDocId = (docid: string) => ({
  type: VehicleTypes.GET_VEHICLE_BY_DOCID,
  payload: docid
})

export const getVehicleByDocIdSuccess = (vehicle: any) => ({
  type: VehicleTypes.GET_VEHICLE_BY_DOCID_SUCCESS,
  payload: vehicle,
});

export const getVehicleByDocIdFail = (error: any) => ({
  type: VehicleTypes.GET_VEHICLE_BY_DOCID_FAIL,
  payload: error,
});

// export const updateSiteByDocId = (docid: string, site: any) => ({
//   type: VehicleTypes.UPDATE_SITE_BY_DOCID,
//   payload: {docid, site}
// })

// export const updateSiteByDocIdSuccess = (site: any) => ({
//   type: VehicleTypes.UPDATE_SITE_BY_DOCID_SUCCESS,
//   payload: site,
// });

// export const updateSiteByDocIdFail = (error: any) => ({
//   type: VehicleTypes.UPDATE_SITE_BY_DOCID_FAIL,
//   payload: error,
// });




