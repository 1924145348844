import React from "react";
import MetaTags from "react-meta-tags";
import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import styled from "styled-components";
import { ThemeColors } from "src/constants/colors";
import ImagePicker from 'src/components/Common/ImagePicker';
import Icon from "@ailibs/feather-react-ts";
import useOnboardings from "src/hooks/useOnboardings";
import { SpinnerCircular } from "spinners-react";


const AddOnboarding = () => {
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [coverImg, setCoverImg] = useState("");
    const { onAdd, loading } = useOnboardings();    

    const onChangeCoverImg = (coverImg: string) => {
        setCoverImg(coverImg);
    };

    const onCreate = (values: any) => {
       values.image = coverImg;
       values.visible = visible;
       onAdd(values);
       history.push("/settings/onboarding")
    };

    const onChange = () => {
        setVisible(!visible)
    };
    
    return (
        <React.Fragment>
            <MetaTags>
                <title>Onboarding</title>
            </MetaTags>
            <div className="page-content" style={{ height: '100vh', overflow:'scroll'}}>
                <Container fluid >
                    <Row>
                        <Col llg={6} className="mt-3">
                            <CardContainer>                           
                                <DescBox>
                                    <Title>{title}</Title>
                                    <Desc>{description}</Desc>
                                </DescBox>              
                                <CardImg src={coverImg}/>
                                <Button
                                    style={{width:30, height:30, borderRadius: 15, backgroundColor: visible?ThemeColors.green:ThemeColors.white, position: "absolute", bottom: 20}}
                                />                           
                            </CardContainer>
                        </Col>
                        <Col llg={6} className="mt-3">
                            <AvForm
                                onValidSubmit={(e: any, values: any) => {
                                    onCreate(values);
                                }}
                            >
                                <ImagePicker
                                    placeholder='Pick a cover image'
                                    onChange={onChangeCoverImg}
                                />
                                <AvField
                                    name="title"
                                    label="Title"
                                    type="text"
                                    className="template-subject"
                                    onChange={(e: any) => setTitle(e.target.value)}
                                />
                                <AvField
                                    name="description"
                                    label="Description"
                                    type="textarea"
                                    className="template-body"
                                    onChange={(e: any) => setDescription(e.target.value)}
                                />
                                <Visible>
                                    <label>Visible</label>
                                    {visible?
                                    <Icon  onClick={onChange} name="toggle-right" size={50} color={ThemeColors.green}/>:
                                    <Icon  onClick={onChange} name="toggle-left" size={50} color={ThemeColors.red}/>}
                                </Visible>
                                <CreateButton>
                                    <button 
                                        type="submit"
                                        className="btn btn-success save-user"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                        <SpinnerCircular
                                            size={30}
                                            thickness={150}
                                            speed={150}
                                            color="#36ad47"
                                            secondaryColor="rgba(0, 0, 0, 0.44)"
                                        />
                                        ) : (
                                        <>Create</>
                                        )}
                                    </button>                                    
                                </CreateButton>
                            </AvForm>
                        </Col>
                    </Row>                                        
                </Container>
            </div>
        </React.Fragment>
    )
};

export default withRouter(AddOnboarding);

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 455px;
    border-radius: 20px;
    border-color: ${ThemeColors.main};
    border: solid;
    border-width: 1px;
    position: relative;
    background: ${ThemeColors.white};
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    margin-left: 20px;
`

const DescBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
`

const Title = styled.div`
    color: ${ThemeColors.main};
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
`

const Desc = styled.div`
    color: ${ThemeColors.main};
    font-size: 12px;
    font-weight: 400;
    white-space: break-spaces;
    text-align: center;
`

const CardImg = styled.img`
    width: 100%;
    height: 200px;
    border-radius: 20px;
    object-fit: cover;
    margin-top: 20px;
`

const Visible = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const CreateButton = styled.div`
    margin-top:10px;
    display: flex;
    flex-direction: row;
    justify-content: end;
`