import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getAdminByDocId, updateAdminByDocId } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import { SpinnerCircular } from "spinners-react";
import AvatarEdit from "src/components/Common/AvatarEdit";
import { IRole } from "src/interfaces/general";
import AddRoleSelector from "./AddRole";
import useAdmins from "src/hooks/useAdmins";
import SiteSelect from "src/components/Common/SiteSelect";

const EditAdmin = (props: any) => {
  const dispatch = useDispatch();
  const { docid: adminId } = useParams<any>();
  const { currentUser } = useSelector((state: any) => state.login);
  const [avatar, setAvatar] = useState("");
  const [role, setRole] = useState("");

  const { singleAdmin, loading, updateAdmin, fetchAdminById, changeAdmin } =
    useAdmins(currentUser?.code || currentUser?.companyCode);
  const { sites, authSite } = useSelector((state: any) => state.site);
  const [siteName, setSiteName] = useState("");
  const [siteList, setSitesList] = useState<any>([]);


  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      siteCodeLists(Array(authSite));
    } else {
      siteCodeLists(sites);
    }
  }, [sites, authSite, singleAdmin]);

  const siteCodeLists = (lists: any) => {
    let arr: { value: any; label: any }[] = [];
    if (lists.length > 0) {
      lists.map((item: any) => {
        if (singleAdmin?.siteCode) {
          if (singleAdmin.siteCode == item.code) {
            setSiteName(item.name);
          }
        }
        arr.push({ value: item.code, label: item.name });
      });
    }
    setSitesList(arr);
  };

  const onChange = (avatar: string) => {
    if (singleAdmin) {
      changeAdmin({
        ...singleAdmin,
        avatar: avatar,
      });
    }
  };

  const handleChange = (selectedOption: any) => {
    if (singleAdmin) {
      setSiteName(selectedOption.label);
      changeAdmin({
        ...singleAdmin,
        siteCode: selectedOption.value,
      });
    }
  };

  const onChangeRole = (role: IRole) => {
    if (singleAdmin) {
      changeAdmin({
        ...singleAdmin,
        role: {
          name: role.name,
        }
      });
    }
  };

  const onUpdateAdmin = () => {
    if (singleAdmin) {
      updateAdmin(singleAdmin.id, singleAdmin);
    }
  };

  useEffect(() => {
    fetchAdminById(adminId);
  }, [adminId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to={"/admins"} className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Admin Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: AdminID: {singleAdmin?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AvForm>
            <AvatarEdit
              singleSelect={singleAdmin?.avatar || ""}
              onChange={onChange}
              title={"Admin"}
            />
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label for="siteCode">Site</Label>
                  <SiteSelect
                    siteCode={singleAdmin?.siteCode || ""}
                    siteName={siteName}
                    productList={siteList}
                    handleChange={selectedOption =>
                      handleChange(selectedOption)
                    }
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <label> Role </label>
                  <AddRoleSelector
                    method="edit"
                    onChangeRole={onChangeRole}
                    singleAdminRole={singleAdmin?.role?.name}
                  />
                </div>
              </Col>

            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="firstName"
                    label="First Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.firstName || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="lastName"
                    label="Last Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.lastName || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Phone"
                    type="text"
                    errorMessage="Invalid Phone number"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.phone || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.email || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="userName"
                    label="User Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.userName || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="pincode"
                    label="PinCode"
                    type="text"
                    errorMessage="Invalid Pincode"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.pincode || ""}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user update-btn"
                    disabled={loading}
                    onClick={onUpdateAdmin}
                  >
                    {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Update</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditAdmin);
