import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { CompanyTypes } from "./actionTypes";
import { ICompany, IResponse } from "../../interfaces/general";
import {
  getCompaniesSuccess,
  getCompaniesFail,
  addCompanySuccess,
  addCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  getCompanyByCodeSuccess,
  getCompanyByCodeFail
} from "./actions";
import {
  fetchCompaniesAsync,
  addNewCompanyAsync,
  deleteCompanyAsync,
  updateCompanyAsync,
  getCompanyByCodeAsync
} from "../../helpers/backend_helper";

function* fetchCompanies() {
  try {
    const response: IResponse = yield call(fetchCompaniesAsync);
    if(response.success) {
      yield put(getCompaniesSuccess(response.result));
    }
  } catch (error) {
    yield put(getCompaniesFail(error));
  }
}

function* addNewCompany({ payload: company }: any) {
  try {
    const response: IResponse = yield call(addNewCompanyAsync, company);
    if(response.success) {
      yield put(addCompanySuccess(response.result));
    }
  } catch (error) {
    yield put(addCompanyFail(error));
  }
}

function* deleteCompany({ payload: code }: any) {
  try {
    const response: IResponse = yield call(deleteCompanyAsync, code);
    yield put(deleteCompanySuccess(response.result));
  } catch (error) {
    yield put(deleteCompanyFail(error));
  }
}

function* updateCompany({ payload: {id, company, history} }: any) {
  try {
    const response: IResponse = yield call(updateCompanyAsync, id, company);
    if(response.success) {
      // history.goBack()
      yield put(updateCompanySuccess(response.result));
    }
  } catch (error) {
    yield put(updateCompanyFail(error));
  }
}

function* getCompanyByCode({ payload: companyCode }: any) {
  try {
    const response: IResponse = yield call(getCompanyByCodeAsync, companyCode);
    if(response.success) {
      yield put(getCompanyByCodeSuccess(response.result));
    }
  } catch (error) {
    yield put(getCompanyByCodeFail(error));
  }
}

function* companySaga() {
  yield takeEvery(CompanyTypes.GET_COMPANIES, fetchCompanies),
  yield takeEvery(CompanyTypes.GET_COMPANY_BY_CODE, getCompanyByCode),
  yield takeEvery(CompanyTypes.ADD_COMPANY, addNewCompany),
  yield takeEvery(CompanyTypes.DELETE_COMPANY, deleteCompany),
  yield takeEvery(CompanyTypes.UPDATE_COMPANY, updateCompany);
}

export default companySaga;

