import React, { useEffect, useRef, useState } from "react";
import { withRouter, Link, useParams} from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import useGroups from "src/hooks/useGroups";
import { IAdmin } from "src/interfaces/general";
import EditStaffList from "./EditStaffList";
import { useSelector } from "react-redux";
import useAdmins from "src/hooks/useAdmins";
import EditManagerSelect from "./editManagerSelect";

const EditGroup = () => {
  const {t} = useTranslation();
  const { docid: groupId } = useParams<any>();
  const { currentUser } = useSelector((state: any) => state.login);
  const { admins } = useAdmins(currentUser?.code||currentUser?.companyCode);
  const [mapping, setMapping] = useState<{[id:string]:IAdmin}>({});
  const { 
    singleGroup, 
    loading, 
    updateGroup, 
    fetchGroupById,
    changeGroup
  } = useGroups(currentUser?.code||currentUser?.companyCode);

  const onChangeManager = (data: IAdmin)=>{
    if ( singleGroup){
      changeGroup({
        ...singleGroup,
        manager: data.id
      })
    }
  };

  const onChangeMembers = (data:IAdmin[])=>{
    if ( singleGroup){
      changeGroup({
        ...singleGroup,
        staff: data.map(item=>item.id)
      })
    }
  };

  const onUpdateGroup = ()=>{
    if (singleGroup){
      updateGroup(singleGroup.id||"", singleGroup);
    }
  };

  useEffect(()=>{
    const tmp:any = {};
    admins.map(admin=>tmp[admin.id] = admin);
    setMapping(tmp);
  },[admins]);
  
  useEffect(()=> {
    fetchGroupById(groupId);
  },[]);

  const manager = mapping[singleGroup?.manager||""];
  const staffs = singleGroup?.staff.map(id=>mapping[id]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Group</title>
      </MetaTags>
      <div className="page-content" style={{height:'100vh'}}>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/groups" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Group Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: GroupId: {singleGroup?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>         
          <AvForm>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleGroup?.name || ""}
                    style={{height:48}}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <label> Choose Manager </label>
                  <EditManagerSelect
                    singleGroupAdmin={manager}
                    onSelectManager={onChangeManager}
                  />
                </div>
              </Col> 
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <label> Staff List </label>            
                  <EditStaffList
                    members={staffs||[]}
                    onSetMembers = {onChangeMembers}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    disabled={loading}
                    onClick={onUpdateGroup}
                  >
                    {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>{t("customers.Update")}</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>      
  );
};

export default withRouter(EditGroup);
