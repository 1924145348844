import { CommsTypes } from "./actionTypes";

export const getCommsBySiteCode = (siteCode: string) => ({
  type: CommsTypes.GET_COMMS_BY_SITECODE,
  payload: siteCode
});

export const getCommsBySiteCodeSuccess = (comm: any) => ({
  type: CommsTypes.GET_COMMS_BY_SITECODE_SUCCESS,
  payload: comm
});

export const getCommsBySiteCodeFail = () => ({
  type: CommsTypes.GET_COMMS_BY_SITECODE_FAIL,
  payload: null
});


export const saveComms = (commsData: any) => ({
  type: CommsTypes.SAVE_COMMS,
  payload: commsData
});

export const saveCommBySitecodeSuccess = (comms: any) => ({
  type: CommsTypes.SAVE_COMMS_SUCCESS,
  payload: comms
});












