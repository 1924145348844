import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {addCompany} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";


const AddCompany = (props: any) => {

  const dispatch = useDispatch();
  const { add_company_status }  = useSelector((state: any) => state.company)

  useEffect(() => {
    if(add_company_status) {
      props.onDrawerClose(false);
    }
  }, [add_company_status])

  const handleAddCompany = (values: any) => {
    let newCompany = values;
      newCompany.logo =
        "https://www.bcquake.ca/wp-content/uploads/2018/02/Sample-Logo.jpg";
      dispatch(addCompany(newCompany));
  };

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Company</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-company-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddCompany(values);
            }}
          >
            <div className="add-company-form-cotainer">
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="code"
                      label="Company Code"
                      placeholder="company code should 5 digits"
                      type="number"
                      errorMessage="Should be 5 digits"
                      validate={{
                        required: { value: true },
                        minLength: {
                          value: 5,
                          errorMessage: "Min 5 chars.",
                        },
                        maxLength: {
                          value: 5,
                          errorMessage: "Max 5 chars.",
                        },
                      }}
                      value={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Name"
                      type="text"
                      errorMessage="Invalid Name"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="phone"
                      label="Phone"
                      type="text"
                      errorMessage="Invalid Phone number"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      type="email"
                      errorMessage="Invalid Email"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      errorMessage="Invalid Password"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="address"
                      label="Address"
                      type="text"
                      errorMessage="Invalid Address"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="postcode"
                      label="Postcode"
                      type="text"
                      errorMessage="Invalid postcode"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="websiteUrl"
                      label="Website Url"
                      type="text"
                      errorMessage="Invalid websiteUrl"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="bookingUrl"
                      label="Booking Url"
                      type="text"
                      errorMessage="Invalid bookingUrl"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="tutorialVideo"
                      label="Tutorial Video"
                      type="text"
                      errorMessage="Invalid tutorialVideo"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Row>
                  <Col md="6">
                    <h5>Location</h5>
                  </Col>
                </Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="location.lat"
                      label="Lat"
                      type="number"
                      errorMessage="Invalid lat"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="location.lon"
                      label="Lon"
                      type="text"
                      errorMessage="Invalid lon"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="managerName"
                      label="Manager Name"
                      type="text"
                      errorMessage="Invalid ManagerName"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    />
                  </div>
                </Col>
                <Col md="6"></Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddCompany);
