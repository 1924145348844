import { LoginTypes } from "./actionTypes";

export const setAuthUser = (user: any) => {
  return {
    type: LoginTypes.SET_AUTH_USER,
    payload: { user },
  };
}



export const ADMIN_LOGIN = (user: any, history: any) => {
  return {
    type: LoginTypes.LOGIN_STAFF_ADMIN,
    payload: { user, history },
  };
};

export const loginCompanyAdmin = (user: any, history: any) => {
  return {
    type: LoginTypes.LOGIN_COMPANY_ADMIN,
    payload: { user, history },
  };
};

export const loginMFA = (mfa: any, history: any) => {
  return {
    type: LoginTypes.LOGIN_MFA,
    payload: { mfa, history },
  };
};

export const cancelMFA = () => {
  return {
    type: LoginTypes.CANCEL_MFA,
  };
};

export const resendMFA = (mfa: any) => {
  return {
    type: LoginTypes.RESEND_MFA,
    payload: mfa
  };
};

export const mfaRequired = (mfa: any) => {
  return {
    type: LoginTypes.LOGIN_MFA_REQUIRED,
    payload: mfa,
  };
};

export const loginSuccess = (user: any) => {
  return {
    type: LoginTypes.LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFail = (response: any) => {
  return {
    type: LoginTypes.LOGIN_FAIL,
    payload: response,
  };
}

export const logoutUser = (history: any) => {
  return {
    type: LoginTypes.LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = (response: any) => {
  return {
    type: LoginTypes.LOGOUT_USER_SUCCESS,
    payload: response,
  };
};

export const apiError = (error: any) => {
  return {
    type: LoginTypes.API_ERROR,
    payload: error,
  };
};

