import React, { useEffect, useState } from "react";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getVehicleByDocId } from "../../store/actions";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import MetaTags from "react-meta-tags"

const EditVehicle = (props: any) => {
  const dispatch = useDispatch();
  const { docid } = useParams<any>();
  const history = useHistory();

  const { signleVehicle } = useSelector((state: any) => state.vehicle);

  useEffect(() => {
    dispatch(getVehicleByDocId(docid));
  }, []);

  const handleEditSite = (values: any) => {
    // dispatch(updateSiteByDocId(docid, values));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Vehicle</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/vehicles" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Vehicle Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: VehicleId: {signleVehicle?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>    
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditSite(values);
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="regNo"
                    label="Reg No"
                    type="text"
                    errorMessage="Invalid Reg No"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.regNo || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="image"
                    label="Image"
                    type="text"
                    errorMessage="Invalid Image"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.image || ""}
                  />
                </div >
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="customerId"
                    label="Customer Id"
                    type="text"
                    errorMessage="Invalid Customer Id"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.customerId || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="companyCode"
                    label="Company Code"
                    type="text"
                    disabled={true}
                    errorMessage="Invalid Company Code"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.companyCode || ""}
                  />
                </div>
              </Col>
              
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="tax"
                    label="Tax"
                    type="text"
                    errorMessage="Invalid Tax"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.tax || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="mot"
                    label="Mot"
                    type="text"
                    errorMessage="Invalid Mot"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.mot || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="warranty"
                    label="Warranty"
                    type="text"
                    errorMessage="Invalid Warranty"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.warranty || ""}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="reminders"
                    label="Reminders"
                    type="text"
                    errorMessage="Invalid Reminders"
                    validate={{
                      required: { value: true },
                    }}
                    value={signleVehicle?.reminders || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Update
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment> 
  );
};

export default withRouter(EditVehicle);