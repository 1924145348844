import styled from 'styled-components';
import { IAdmin } from "src/interfaces/general";
import AddStaffButton from './AddStaffButton';
import { useEffect, useState } from 'react';

interface IProps {
    members:Array<IAdmin>;
    onSetMembers:(members:Array<IAdmin>) => void;
}
const EditStaffList = ({
    members,
    onSetMembers
}:IProps) =>{

    const [excludes, setExcludes] = useState({});

    const onAddStaff = (data: IAdmin)=>{
        onSetMembers([
            ...members,
            data
        ])
    };

    const onRemoveStaff = (data:IAdmin)=>{
        const index = members.findIndex(member=>member.id === data.id);
        if (index>=0){
            members.splice(index, 1);
            onSetMembers(members);
        }
    };

    useEffect(()=>{
        const tmp:{[id:string]:boolean} = {};
        members
            .filter(member=>member!=null)
            .map(member=>tmp[member.id] = true);
        setExcludes(tmp);
    },[members]);

    return(
        <>            
            <StaffListContainer>
                {members.map((admin, index)=>(
                    <StaffItem key={index}>
                        <StaffAvatar src={admin?.avatar}/>
                        <Body>
                            <SiteName>{admin?.userName}</SiteName>
                        </Body>
                        <RemoveIcon onClick={()=>onRemoveStaff(admin)}>
                            <span className='fa fa-trash'/>
                        </RemoveIcon>
                    </StaffItem>
                ))}
            </StaffListContainer>
            <AddStaffButton 
                onSelect={onAddStaff}
                excludes={excludes}
            />
        </>
    )
}

export default EditStaffList;


const StaffListContainer = styled.div`
    align-items: center;
`

const StaffItem = styled.div`
    height: 50px;
    border-radius: 8px;
    background-color: white;
    border: solid 1px black;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: black;
    margin: 10px;
    position: relative;
    width: 200px;
`

const RemoveIcon = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: red;
    font-size: 12px;
    cursor: pointer;
`

const StaffAvatar = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-left: 10px;
    border: solid 1px green;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
`

const SiteName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 600;
`
