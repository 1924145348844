import { useEffect, useState } from "react";
import { addRoleAsync, deleteRoleByIdAsync, getAllRolesAsync, getRoleByIdAsync, updateRoleByIdAsync } from "src/helpers/backend_helper";
import { IRole } from "src/interfaces/general";

const useRoles = () => {

    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [loading, setLoading] = useState(false);
    const [singleRole, setSingleRole] = useState<IRole>();
    const [success, setSuccess] = useState(false);

    const fetchRoles = () => {
        setLoading(true);
        getAllRolesAsync()
            .then(res => {
                if (res.success) {
                    setRoles(res.result || []);
                }
            }).catch(console.log).finally(() => setLoading(false));
    }

    const fetchRoleById = (id: string) => {
        setLoading(true);
        if (id) {
            getRoleByIdAsync(id)
                .then(res => {

                    if (res.success) {
                        console.log("ROLE", res.result);
                        setSingleRole(res.result || {});
                        console.log("SINGLE ROLE", singleRole);
                    }
                }).catch(console.log).finally(() => setLoading(false));
        }
    }

    const deleteRole = async (id: string) => {
        const index = roles.findIndex(role => role.id === id);
        try {
            setLoading(true);
            const result = await deleteRoleByIdAsync(id);
            if (result.success) {
                roles.splice(index, 1);
                setRoles([...roles]);
                setLoading(false);
                return true;
            }
        } catch (error) {
            console.log("role delete error", error);
        }
        setLoading(false);
        return false;

    }

    const addRole = async (role: IRole) => {

        try {
            setLoading(true);
            const result = await addRoleAsync(role);
            if (result.success) {
                fetchRoles()
                setLoading(false);
                setSuccess(true);
                return true;
            }
        } catch (error) {
            console.log("role add error", error);
        }
        setLoading(false);
        return false;
    }

    const updateRole = async (id: string, role: IRole) => {

        try {
            setLoading(true);
            setSuccess(true);
            console.log("Updating...", role);
            const result = await updateRoleByIdAsync(id, role);
            if (result.success) {
                roles.push(role);
                setRoles([...roles]);
                setLoading(false);
                setSuccess(false);
                return true;
            }
        } catch (error) {
            console.log("role edit error", error);
        }
        setLoading(false);
        setSuccess(false);
        return false;
    }

    useEffect(() => {
        fetchRoles();
    }, [])

    return {
        roles,
        loading,
        fetchRoleById,
        singleRole,
        addRole,
        deleteRole,
        success,
        updateRole
    };
}

export default useRoles;